import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

//getSettings
export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async (id , thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BASE_API}/setting/${id}`);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//createSettings
export const createSettings = createAsyncThunk(
  "settings/createSettings",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    let { id } = data;

    try {
      const res = await axios.post(`${BASE_API}/setting/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allSettings: [],
  isError: false,
  isSuccess: false,
  isLoadingSetting: false,
  message: "",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},

  extraReducers: {
    //getSettings
    [getSettings.pending]: (state, action) => {
      //console.log(action);
      state.isLoadingSetting = true;
      state.isError = false;
    },
    [getSettings.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoadingSetting = false;
      state.isSuccess = true;
      state.isError = false;
      state.allSettings = action.payload;
    },
    [getSettings.rejected]: (state, action) => {
      //console.log(action);
      state.isLoadingSetting = false;
      state.isError = true;
      state.message = action.payload;
    },


    //createSettings
      [createSettings.pending]: (state, action) => {
        //console.log(action);
        state.isLoadingSetting = true;
        state.isError = false;
      },
      [createSettings.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoadingSetting = false;
        state.isSuccess = true;
        state.isError = false;
        state.allSettings = action.payload;
      },
      [createSettings.rejected]: (state, action) => {
        //console.log(action);
        state.isLoadingSetting = false;
        state.isError = true;
        state.message = action.payload;
      },
  },
});

export default settingsSlice.reducer;
