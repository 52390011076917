import { Alert, Button, ButtonBase, Container } from "@mui/material";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./myTeam.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { deleteOldTask, getOldTasks } from "../../store/oldTasks/oldTaskSliceMarketing";
import SummaryMarketing from "../Reports/dailySummry/summaryMarketing";
import { createSummary } from "../../store/dailySummary/dailySummaryMarketing";


const TeamMemberTasksMarketing = ({ setCurrentPage }) => {
  const { allOldTasks, isLoading, todayallTasks } = useSelector(
    (state) => state.oldTasksMarketing
  );
  const { user } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setCurrentPage(location.pathname);
    if (user) {
      dispatch(
        getOldTasks({
          id: location.state.month,
          employeeId: location.state.employee,
        })
      );
    }
  }, [navigate]);

  const converDate = (date) => {
    return date.split("T")[0].split('-')[0] + '-' + date.split("T")[0].split('-')[1]
  };

  return (
    <>
      <Box sx={{ border: "1px solid #E9E9EF" }}>
        <Box className={styles.reportContainer}>
          <>
            {allOldTasks.length === 0 && !isLoading && (
              <Box className={styles.alertContainerStyle} height="400px">
                <Alert
                  variant="filled"
                  severity="info"
                  className={styles.alertStyle}
                >
                  You don't have any Tasks in {location.state.title}
                </Alert>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonBase
                onClick={() => navigate("/reports/myTeam/months/days/tasks/createTask",
                  {
                    state: {
                      monthId: location.state.month,
                      dateSchema: location.state.schema,
                      employeeId: location.state.employee,
                    },
                  }
                )}
                type="submit"
                className={styles.addButton}
              >
                <i className={`fa-solid fa-plus ${styles.addIconStyle}`}></i>
                New
              </ButtonBase>
              <p style={{ fontFamily: "tahoma" }}>
                Tasks Of {location.state.title}
              </p>
              <ButtonBase
                onClick={() => navigate(-1)}
                type="submit"
                className={styles.backButton}
              >
                <i
                  className={`fa-solid fa-angle-left ${styles.addIconStyle}`}
                ></i>
                back
              </ButtonBase>
            </Box>

            {isLoading ? (
              <div className={styles.progressContainer}>
                <CircularProgress className={styles.progress} />
              </div>
            ) : (
              allOldTasks.length !== 0 && (
                <TableContainer
                  sx={{
                    maxHeight: 430,
                  }}
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                    <TableHead className={styles.tableHeadStyle}>
                      <TableRow>
                        <TableCell className={styles.headerCell}>
                          YY/MM
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          task
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          Status
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          comments
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          % complete
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allOldTasks &&
                        allOldTasks.map((row, index) => {
                          return (
                            <TableRow
                            className={
                              row.status === "In Progress"
                                ? styles.inProgressStyle
                                : row.status === "Completed"
                                ? styles.completedStyle
                                : ""
                            }
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                className={styles.dataCell}
                                component="th"
                                scope="row"
                              >
                                {converDate(row.date)}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.task}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.status}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.comments}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.completePersentage}
                              </TableCell>
                              <TableCell
                                className={styles.buttonsContainer}
                                align="right"
                              >
                                <Button
                                  className={styles.editButton}
                                  variant="text"
                                  onClick={() => {
                                    navigate(
                                      `/reports/allTasks/allMonths/days/oldTasks/editTask`,
                                      {
                                        state: { info: row },
                                      }
                                    );
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() =>
                                    dispatch(
                                      deleteOldTask({
                                        id: row._id,
                                        employeeId: location.state.employee,
                                        monthId: location.state.month,
                                        yearId: location.state.year,
                                      })
                                    ).then(() => {
                                      console.log(row.date);
                                      let month = new Date(row.date).toString().split(" ")[1];
                                      let year = new Date(row.date).toString().split(" ")[3];
                                      let schema = `${month} ${year}`;

                                      let values = { employeeId: location.state.employee, date: schema }
                                      dispatch(createSummary(values))
                                    })
                                  }
                                  className={styles.tableDeleteButton}
                                  variant="text"
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            )}
          </>
        </Box>
      </Box>
      <Box>
        <SummaryMarketing todayallTasks={allOldTasks} />
      </Box>
    </>
  );
};
export default TeamMemberTasksMarketing;
