import { useLocation } from "react-router-dom";
import TeamMemberTasks from "../myTeam_planning/teamMemberTasks";
import TeamMemberTasksMarketing from "../myTeam_marketing/teamMemberTasks";

const Admin_Team_Tasks_Table = ({ setCurrentPage }) => {
  const location = useLocation();
  let { depart } = location.state;
  return depart === "Planning" || depart === "BIM" ? (
    <TeamMemberTasks  setCurrentPage={setCurrentPage} />
  ) : (
    <TeamMemberTasksMarketing setCurrentPage={setCurrentPage} />
  );
};
export default Admin_Team_Tasks_Table;
