import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import employeesSlice from "./employees/employeesSlice";
import departmentSlice from "./department/departmentSlice";
import taskSlice from "./dailyTasks/taskSlice";
import dailySummarySlice from "./dailySummary/dailySummarySlice";
import yearsSlice from "./yearsSlice/yearsSlice";
import monthsSlice from "./months/monthsSlice";
import daysSlice from "./days/daysSlice";
import oldTasksSlice from "./oldTasks/oldTasksSlice";
import customSummarySlice from "./customSummary/customSummarySlice";
import monthlySummarySlice from "./monthlySummary/monthlySummarySlice";
import projectsSlice from "./projects/projectsSlice";
import notificationSlice from "./notifications/notificationsSlice";
import taskSliceMarketing from "./dailyTasks/taskSliceMarketing";
import yearsSliceMarketing from "./yearsSlice/yearsSliceMarketing";
import monthsMarketingSlice from "./months/monthsSliceMarketing";
import oldTaksSliceMarketing from "./oldTasks/oldTaskSliceMarketing";
import customSummarySliceMarketing from "./customSummary/customSummarySliceMarketing";
import monthlyReportSlice from "./monthlyReport/monthlyReportSlice";
import settingsSlice from "./tablesSettings/tablesSettingSlice";
import glopalChatSlice from "./dashboard/glopalSlice";
import requestSlice from "./requests/requestsSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    tasks: taskSlice,
    tasksMarketing: taskSliceMarketing,
    dailysummary: dailySummarySlice,
    employees: employeesSlice,
    departments: departmentSlice,
    years: yearsSlice,
    yearsMarketing: yearsSliceMarketing,
    months: monthsSlice,
    monthlyReport: monthlyReportSlice,
    monthsMarketing: monthsMarketingSlice,
    days: daysSlice,
    oldTasks: oldTasksSlice,
    oldTasksMarketing: oldTaksSliceMarketing,
    customSummary: customSummarySlice,
    customSummaryMarketing: customSummarySliceMarketing,
    monthlySummary: monthlySummarySlice,
    projects: projectsSlice,
    settings: settingsSlice,
    notifications: notificationSlice,
    glopalChat: glopalChatSlice,
    requests: requestSlice,
  },
  // devTools: false,
});
