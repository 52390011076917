import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { bim } from "../../roles";
import { useSelector } from "react-redux";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent * 100 !== 0 && `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const SummaryExample = ({ allTasks }) => {
  const convertMinutesToHours = (totalHours, totalMinutes) => {
    var num = totalMinutes;
    var hours = num / 60;
    var rhours = Math.floor(hours) + totalHours;
    var minutes = (hours - Math.floor(hours)) * 60;
    var rminutes = Math.round(minutes);
    return rhours + rminutes / 60;
  };

  const iterator = (categorypar) => {
    let totalHours = 0;
    let totalMinutes = 0;
    allTasks.forEach((val) => {
      totalHours += +val[categorypar]?.split(":")[0];
      totalMinutes += +val[categorypar]?.split(":")[1];
    });
    if (totalMinutes === 60) {
      totalHours += 1;
      return totalHours;
    } else if (totalMinutes < 60) {
      return totalHours + totalMinutes / 60;
    } else {
      return convertMinutesToHours(totalHours, totalMinutes);
    }
  };

  const { user } = useSelector((state) => state.employees);
  const bimRole = bim(user);

  const COLORS = bimRole
    ? ["#FFBF53", "#dd2c00", "#76ff03", "#2962ff", "#9575cd", "#f06292"]
    : [
        "#52AAF0",
        "#2AB57D",
        "#FFBF53",
        "#dd2c00",
        "#76ff03",
        "#2962ff",
        "#9575cd",
        "#f06292",
      ];
  // console.log(iterator("Technical"));

  let data = bimRole
    ? [
        { name: "Project Admin", value: iterator("Project_Admin") },
        { name: "Technical", value: iterator("Technical") },
        { name: "Office Admin", value: iterator("Office_Admin") },
        { name: "Training", value: iterator("Training") },
        { name: "Break", value: iterator("Break") },
        { name: "No Work Assigned", value: iterator("No_Work_Assigned") },
      ]
    : [
        { name: "Project Admin", value: iterator("Project_Admin") },
        { name: "Technical", value: iterator("Technical") },
        { name: "Assisting", value: iterator("Assisting") },
        { name: "Office Admin", value: iterator("Office_Admin") },
        { name: "Internal Meetings", value: iterator("Internal_Meetings") },
        { name: "Training", value: iterator("Training") },
        { name: "Break", value: iterator("Break") },
        { name: "No Work Assigned", value: iterator("No_Work_Assigned") },
      ];
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          isAnimationActive={true}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={130}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default SummaryExample;
