import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//getMonthSummary
export const getMonthSummary = createAsyncThunk(
  "monthlySummary/getMonthSummary",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;


    try {
      const res = await axios.get(`${BASE_API}/monthlySummary/${id}`)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCompletedMonthSummary = createAsyncThunk(
    "monthlySummary/getCompletedMonthSummary",
    async (id, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
  
  
      try {
        const res = await axios.get(`${BASE_API}/completedSummary/${id}`)
        //console.log(res);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

const initialState = {
  allMonthlySummary: [],
  completed:0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const monthlySummarySlice = createSlice({
  name: "months",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //createDepartment
    [getMonthSummary.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getMonthSummary.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allMonthlySummary = action.payload;
    },
    [getMonthSummary.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.allMonthlySummary = null;
    },

// getCompletedMonthSummary
    [getCompletedMonthSummary.pending]: (state, action) => {
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [getCompletedMonthSummary.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.completed = action.payload;
      },
      [getCompletedMonthSummary.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.completed = 0;
      },
  },
});

export const { reset } = monthlySummarySlice.actions;
export default monthlySummarySlice.reducer;
