import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from "./modal.module.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { sumOfHours, sumOfHoursDeduct } from '../../pages/projects/calculateTime'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #E9E9EF',
  boxShadow: 24,
  p: 4,
};



export default function ModalSpentTime({ row }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const filterTasks = (tasks, userId, role) => {
    return tasks.filter((item) => {
      return (item.employeeId === userId && item.employeeRole === role)
    })
  }

  const teamLeaderRows = row.oldSelectedTeamLeader.map((item, index) => {
    if (item._id !== row.selectedTeamLeader._id) {
      return (
        <TableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell className={styles.dataCell} align="right">{item.username}</TableCell>
          <TableCell className={styles.dataCell} align="right">
            Team Leader
          </TableCell>
          <TableCell className={styles.dataCell}
            align="right">{sumOfHoursDeduct(filterTasks(row.tasksOfAssistant, item._id, "selectedTeamLeader"))}</TableCell>
            <TableCell className={styles.dataCell}
            align="right">{sumOfHours(filterTasks(row.tasksOfAssistant, item._id, "selectedTeamLeader"))}</TableCell>
          <TableCell
            className={styles.buttonsContainer}
            align="right"
          >
            <Button
              className={styles.editButton}
              variant="text"
            >
              {`details >>`}
            </Button>
          </TableCell>
        </TableRow>
      )
    }
  })

  const assignedRows = row.oldAssignedEmployee.map((item, index) => {
    if (item._id !== row.assignedEmployee._id) {
      return (
        <TableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell className={styles.dataCell} align="right">
            {item.username}
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            assigned employee
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            {sumOfHoursDeduct(filterTasks(row.tasks, item._id, "assignedEmployee"))}
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            {sumOfHours(filterTasks(row.tasks, item._id, "assignedEmployee"))}
          </TableCell>
          <TableCell
            className={styles.buttonsContainer}
            align="right"
          >
            <Button
              className={styles.editButton}
              variant="text"
            >
              {`details >>`}
            </Button>
          </TableCell>
        </TableRow>
      )
    }
  })

  const assistantRows = row.oldAssistantPlanner.map((item, index) => {
    if (item._id !== row.assistantPlanner._id) {
      return (
        <TableRow
          key={index}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell className={styles.dataCell} align="right">
            {item.username}
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            assistant Planner
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            {sumOfHoursDeduct(filterTasks(row.tasksOfAssistant, item._id, "assistantPlanner"))}
          </TableCell>
          <TableCell className={styles.dataCell} align="right">
            {sumOfHours(filterTasks(row.tasksOfAssistant, item._id, "assistantPlanner"))}
          </TableCell>
          <TableCell
            className={styles.buttonsContainer}
            align="right"
          >
            <Button
              className={styles.editButton}
              variant="text"
            >
              {`details >>`}
            </Button>
          </TableCell>
        </TableRow>
      )
    }
  })

  return (
    <div >
      <EventAvailableIcon sx={{ marginLeft: "4%", cursor: "pointer" }} onClick={handleOpen}>edit</EventAvailableIcon>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>ProjectId [ {row.projectId} ]</p>
          </div>

          <Box sx={{ border: "1px solid #E9E9EF", padding: "2%" }}>
            <Box>
              <TableContainer
                sx={{
                  maxHeight: 300,
                }}
                className={styles.tableContainer}
              >
                <Table aria-label="simple table" stickyHeader>
                  <TableHead className={styles.tableHeadStyle}>
                    <TableRow>
                      <TableCell className={styles.headerCell} align="right">
                        name
                      </TableCell>
                      <TableCell className={styles.headerCell} align="right">
                        position
                      </TableCell>
                      <TableCell className={styles.headerCell} align="right">
                        BaseLine
                      </TableCell>
                      <TableCell className={styles.headerCell} align="right">
                        spent Time
                      </TableCell>
                      <TableCell className={styles.headerCell} align="right">
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* row */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.titlediv} align="left">
                        TeamLeader
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right"></TableCell>
                      <TableCell className={styles.titlediv} align="right"></TableCell>
                    </TableRow>
                    {/* ------------------------------ Team leader ------------------------------- */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.dataCell} align="right">
                        {row.selectedTeamLeader?.username}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        Team Leader
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHoursDeduct(filterTasks(row.tasksOfAssistant, row.selectedTeamLeader?._id, "selectedTeamLeader"))}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHours(filterTasks(row.tasksOfAssistant, row.selectedTeamLeader?._id, "selectedTeamLeader"))}
                      </TableCell>
                      <TableCell
                        className={styles.buttonsContainer}
                        align="right"
                      >
                        <Button
                          // onClick={() =>{
                          //   setCurrentPage('/departments/employees');
                          //   navigate(`/departments/employees`, {
                          //     state: { info: row._id },
                          //   })
                          // }}
                          className={styles.editButton}
                          variant="text"
                        >
                          {`details >>`}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {teamLeaderRows}
                    {/* row */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.titlediv} align="left">
                        Assistant
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                    </TableRow>

                    {/* ------------------------------ assistant planner ------------------------------- */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.dataCell} align="right">
                        {row.assistantPlanner?.username}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        assistant planner
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHoursDeduct(filterTasks(row.tasksOfAssistant, row.assistantPlanner?._id, "assistantPlanner"))}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHours(filterTasks(row.tasksOfAssistant, row.assistantPlanner?._id, "assistantPlanner"))}
                      </TableCell>
                      <TableCell
                        className={styles.buttonsContainer}
                        align="right"
                      >
                        <Button
                          // onClick={() =>{
                          //   setCurrentPage('/departments/employees');
                          //   navigate(`/departments/employees`, {
                          //     state: { info: row._id },
                          //   })
                          // }}
                          className={styles.editButton}
                          variant="text"
                        >
                          {`details >>`}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {assistantRows}
                    {/* row */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.titlediv} align="left">
                        Assigned
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell className={styles.titlediv} align="right">
                      </TableCell>
                      <TableCell
                        className={styles.titlediv}
                        align="right"
                      >
                      </TableCell>
                      <TableCell
                        className={styles.titlediv}
                        align="right"
                      >
                      </TableCell>
                    </TableRow>

                    {/* ------------------------------ assigned employee ------------------------------- */}
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className={styles.dataCell} align="right">
                        {row.assignedEmployee?.username}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        assigned employee
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHoursDeduct(filterTasks(row.tasks, row.assignedEmployee?._id, "assignedEmployee"))}
                      </TableCell>
                      <TableCell className={styles.dataCell} align="right">
                        {sumOfHours(filterTasks(row.tasks, row.assignedEmployee?._id, "assignedEmployee"))}
                      </TableCell>
                      <TableCell
                        className={styles.buttonsContainer}
                        align="right"
                      >
                        <Button
                          className={styles.editButton}
                          variant="text"
                        >
                          {`details >>`}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {assignedRows}

                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
