import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//getOldTasks
export const getOldTasks = createAsyncThunk(
  "oldTasks/getOldTasks",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { id, yearId, monthId, employeeId } = data;
    //console.log(id, yearId, monthId, employeeId);
    try {
      const res = await axios.get(`${BASE_API}/oldTasks/${id}`, {
        params: { yearId: yearId, monthId: monthId, employeeId: employeeId },
      });
      //console.log(res);
      return res.data[0].tasks;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addOldTask = createAsyncThunk(
  "oldTasks/addOldTask",
  async (allData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, employeeId } = allData;
    //console.log(allData);
    try {
      const res = await axios.post(`${BASE_API}/oldTasks/${id}`, {
        data: allData,
      });
      //console.log(res);
      return res.data[0].tasks;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteOldTask = createAsyncThunk(
  "oldTasks/deleteOldTask",
  async (allData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, employeeId } = allData;
    //console.log(allData);
    try {
      const res = await axios.delete(`${BASE_API}/oldTasks/${id}`, {
        data: allData,
      });

      return res.data[0].tasks;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allOldTasks: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const oldTaksSlice = createSlice({
  name: "days",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //getOldTasks
    [getOldTasks.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getOldTasks.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allOldTasks = action.payload;
    },
    [getOldTasks.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.allOldTasks = null;
    },

    //addOldTask
    [addOldTask.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [addOldTask.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allOldTasks = action.payload;
    },
    [addOldTask.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
     
    },

    //getOldTasks
    [deleteOldTask.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [deleteOldTask.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allOldTasks = action.payload;
    },
    [deleteOldTask.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.allOldTasks = null;
    },
  },
});

export const { reset, setStateCurrentPage } = oldTaksSlice.actions;
export default oldTaksSlice.reducer;
