import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from '../../config'



export const getEmployeesByDepartmentName = createAsyncThunk(
  "auth/getEmployeesByDepartmentName",
  async (name, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/employees`, { params: { departmentName: name } });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getTeamLeaders = createAsyncThunk(
  "auth/getTeamLeaders",
  async (name, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/teamleaders`, { params: { departmentName: "leader" } });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getEmployees = createAsyncThunk(
  "auth/getEmployees",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/employees/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getBimEmployees = createAsyncThunk(
  "auth/getBimEmployees",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/bimemployees`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Register
export const register = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.post(`${BASE_API}/employees`, userData);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Login
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${BASE_API}/login`, userData, {
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
      });
      if (res.data) {
        localStorage.setItem("user", JSON.stringify(res.data.employee));
      }
      return res.data.employee;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      localStorage.removeItem("user");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//updateEmloyee
export const updateEmloyee = createAsyncThunk(
  "auth/updateEmloyee",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.put(`${BASE_API}/employees`, userData);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//blockEmloyee
export const blockEmloyee = createAsyncThunk(
  "auth/blockEmloyee",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.post(`${BASE_API}/blockUser`, userData);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//getblockEmloyee
export const getblockEmloyee = createAsyncThunk(
  "auth/getblockEmloyee",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BASE_API}/blockUser`);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//unBlockEmloyee
export const unBlockEmloyee = createAsyncThunk(
  "auth/unBlockEmloyee",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.put(`${BASE_API}/blockUser`, userData);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


let user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
  employees: [],
  bdEmployees: [],
  blockedUsers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
  },

  extraReducers: {
    //getblockEmloyee
    [getblockEmloyee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getblockEmloyee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.blockedUsers = action.payload;
    },
    [getblockEmloyee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //blockEmployee
    [blockEmloyee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [blockEmloyee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      // state.employees = action.payload;
    },
    [blockEmloyee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //unBlockEmloyee
    [unBlockEmloyee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [unBlockEmloyee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.employees = action.payload;
    },
    [unBlockEmloyee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //register
    [register.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [register.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.employees = action.payload;
    },
    [register.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //logout
    [logout.fulfilled]: (state, action) => {
      //console.log(action);
      state.user = null;

    },

    //login
    [login.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [login.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.user = action.payload;
    },
    [login.rejected]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.user = null;
    },

    //update
    [updateEmloyee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateEmloyee.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      // state.user = action.payload;
    },
    [updateEmloyee.rejected]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      // state.user = null;
    },

    // get employees by department id 
    [getEmployees.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getEmployees.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.employees = action.payload;
    },
    [getEmployees.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

       // get employees by department id 
       [getBimEmployees.pending]: (state, action) => {
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [getBimEmployees.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.employees = action.payload;
      },
      [getBimEmployees.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      },

    // get employees by department name 

    [getEmployeesByDepartmentName.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getEmployeesByDepartmentName.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.bdEmployees = action.payload;
    },
    [getEmployeesByDepartmentName.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },


    [getTeamLeaders.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getTeamLeaders.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.bdEmployees = action.payload;
    },
    [getTeamLeaders.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
