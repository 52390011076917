import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import styles from "./CreateTasks.module.css";
import { ButtonBase, CircularProgress, Grid } from "@mui/material";
import { editMonthScore, reset } from "../../store/months/monthsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "react-notifications-component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "1px solid #E9E9EF",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ row }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { isError, isSuccess, isLoading } = useSelector(
    (state) => state.months
  );
  const validationSchema = Yup.object({});

  const formik = useFormik({
    initialValues: {
      // score: row.score,
      id: row._id,
      employeeId: row.employeeId,

      quality: row.quality,
      qualityNote: row.qualityNote,

      timeManagement: row.timeManagement,
      timeManagementNote: row.timeManagementNote,

      communication: row.communication,
      communicationNote: row.communicationNote,

      teamPlayer: row.teamPlayer,
      teamPlayerNote: row.teamPlayerNote,

      integrityandConduct: row.integrityandConduct,
      integrityandConductNote: row.integrityandConductNote,

      productivity: row.productivity,
      productivityNote: row.productivityNote,

      weeklyTraking: row.weeklyTraking,
      weeklyTrakingNote: row.weeklyTrakingNote,

      teamProductivity: row.teamProductivity,
      teamProductivityNote: row.teamProductivityNote,

      projectAllocatedTime: row.projectAllocatedTime,
      projectAllocatedTimeNote: row.projectAllocatedTimeNote,

      organization: row.organization,
      organizationNote: row.organizationNote,

      generalNote: row.generalNote,
    },

    onSubmit: (values) => {
      //console.log(values);
      dispatch(editMonthScore({ values }));

      dispatch(editMonthScore({ values }))
        .unwrap()
        .then((data) => {
          Store.addNotification({
            title: "Wonderful!",
            message: `employee score is updated successfully`,
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
            },
          });
          if (isSuccess && !isLoading) {
            dispatch(reset());
          }
        });
    },
    validationSchema,
  });

  return (
    <div style={{ display: "inline" }}>
      <Button className={styles.editScoreButton} onClick={handleOpen}>
        edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Evaluation Form</p>
            <p>
              [ {row.name} - {row.year.name} ]
            </p>
          </div>

          <Box sx={{ border: "1px solid #E9E9EF", padding: "2%" }}>
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <div style={{ height: "70vh", overflowY: "auto" }}>
                  <div className={styles.titlediv}></div>
                  {/* ----------------------- quality ------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="quality"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Quality{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter quality value"
                        type="number"
                        max={15}
                        name="quality"
                        {...formik.getFieldProps("quality")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="qualityNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter quality note"
                        type="text"
                        name="qualityNote"
                        {...formik.getFieldProps("qualityNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- timeManagement --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="timeManagement"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        time Management{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter time management value"
                        type="number"
                        max={15}
                        name="timeManagement"
                        {...formik.getFieldProps("timeManagement")}
                      />
                    </Grid>
                    {/* -----------------------timeManagementNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="timeManagementNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter timeManagement note"
                        type="text"
                        name="timeManagementNote"
                        {...formik.getFieldProps("timeManagementNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- communication --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="communication"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        communication{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter communication value"
                        type="number"
                        max={15}
                        name="communication"
                        {...formik.getFieldProps("communication")}
                      />
                    </Grid>
                    {/* -----------------------communicationNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="communicationNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter communication note"
                        type="text"
                        name="communicationNote"
                        {...formik.getFieldProps("communicationNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- teamPlayer --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="teamPlayer"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        teamPlayer{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter teamPlayer value"
                        type="number"
                        max={10}
                        name="teamPlayer"
                        {...formik.getFieldProps("teamPlayer")}
                      />
                    </Grid>
                    {/* -----------------------teamPlayerNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="teamPlayerNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter teamPlayer note"
                        type="text"
                        name="teamPlayerNote"
                        {...formik.getFieldProps("teamPlayerNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- integrityandConduct --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="integrityandConduct"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        integrity & Conduct{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter integrity & Conduct value"
                        type="number"
                        max={5}
                        name="integrityandConduct"
                        {...formik.getFieldProps("integrityandConduct")}
                      />
                    </Grid>
                    {/* -----------------------integrityandConductNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="integrityandConductNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter integrity & Conduct note"
                        type="text"
                        name="integrityandConductNote"
                        {...formik.getFieldProps("integrityandConductNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- productivity --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="productivity"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        productivity{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter productivity value"
                        type="number"
                        max={5}
                        name="productivity"
                        {...formik.getFieldProps("productivity")}
                      />
                    </Grid>
                    {/* -----------------------productivityNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="productivityNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter productivity note"
                        type="text"
                        name="productivityNote"
                        {...formik.getFieldProps("productivityNote")}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.titlediv}></div>
                  {/* ----------------------- weeklyTraking --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="weeklyTraking"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        weekly Traking{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter weekly Traking value"
                        type="number"
                        max={10}
                        name="weeklyTraking"
                        {...formik.getFieldProps("weeklyTraking")}
                      />
                    </Grid>
                    {/* -----------------------weeklyTrakingNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="weeklyTrakingNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter weeklyTraking note"
                        type="text"
                        name="weeklyTrakingNote"
                        {...formik.getFieldProps("weeklyTrakingNote")}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.titlediv}></div>
                  {/* ----------------------- teamProductivity --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="teamProductivity"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        team Productivity{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter team Productivity value"
                        type="number"
                        max={10}
                        name="teamProductivity"
                        {...formik.getFieldProps("teamProductivity")}
                      />
                    </Grid>
                    {/* -----------------------teamProductivityNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="teamProductivityNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter teamProductivity note"
                        type="text"
                        name="teamProductivityNote"
                        {...formik.getFieldProps("teamProductivityNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- projectAllocatedTime --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="projectAllocatedTime"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        project allocated time{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter project allocated time value"
                        type="number"
                        max={10}
                        name="projectAllocatedTime"
                        {...formik.getFieldProps("projectAllocatedTime")}
                      />
                    </Grid>
                    {/* -----------------------projectAllocatedTimeNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="projectAllocatedTimeNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter project allocated time note"
                        type="text"
                        name="projectAllocatedTimeNote"
                        {...formik.getFieldProps("projectAllocatedTimeNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- organization --------------------------- */}
                  <Grid
                    container
                    spacing={2}
                    className={styles.selectorContainer}
                  >
                    <Grid item xs={12} sm={12} md={4}>
                      <label
                        htmlFor="organization"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        organization{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter organization value"
                        type="number"
                        max={5}
                        name="organization"
                        {...formik.getFieldProps("organization")}
                      />
                    </Grid>
                    {/* -----------------------organizationNote--------------------------- */}
                    <Grid item xs={12} sm={12} md={8}>
                      <label
                        htmlFor="organizationNote"
                        className={`form-label  ${styles.labelStyle}`}
                      >
                        Note{" "}
                      </label>
                      <input
                        className={`form-control ${styles.inputStyle}`}
                        placeholder="Enter organization note"
                        type="text"
                        name="organizationNote"
                        {...formik.getFieldProps("organizationNote")}
                      />
                    </Grid>
                  </Grid>

                  {/* ----------------------- generalNote --------------------------- */}
                  <div className={styles.selectorContainer}>
                    <label
                      htmlFor="generalNote"
                      className={`form-label  ${styles.labelStyle}`}
                    >
                      monthly Notes{" "}
                    </label>
                    <textarea
                      className={`form-control ${styles.inputStyle}`}
                      placeholder="Enter general notes of the month"
                      type="text"
                      rows="4"
                      name="generalNote"
                      {...formik.getFieldProps("generalNote")}
                    />
                  </div>
                </div>
                {/* ------------Save button -------------- */}
                <ButtonBase type="submit" className={styles.saveScoreButton}>
                  <i
                    className={`fa-solid fa-floppy-disk  ${styles.saveScoreButtonIcon}`}
                  ></i>
                  {isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </ButtonBase>
              </form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
