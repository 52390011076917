import React from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CreateTasks.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import {
  updateOtherRequest,
} from "../../store/requests/requestsSlice";
import { createNotification } from "../../store/notifications/notificationsSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { getEmployeesByDepartmentName } from "../../store/employees/employeesSlice";
import { useState } from "react";

const EditOther = ({ socket , row , handleClose}) => {
  const { user, bdEmployees } = useSelector((state) => state.employees);
  const [selectors, setSelectors] = useState(row.receivers);
  const [isSelectorEmpty, setEmpty] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // console.log(row);

  const onTagChange = (event, values) => {
    // console.log(values);
    setEmpty(false);
    setSelectors(values);
  };

  React.useEffect(() => {
    dispatch(getEmployeesByDepartmentName("all"));
  }, [navigate]);

  const handleNotification = (values) => {
    let receiversArray = [];

    values.receivers.forEach((element) => {
      receiversArray.push({
        receiverId: element?._id,
        message: `update (Other) request with date ${row?.created_date}`,
      });
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "request",
      })
    );
    
    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });
   
    handleClose()

  };

  const validationSchema = Yup.object({
    // username: Yup.string().required("please Enter username"),
    // receivers: Yup.array().min(1).required("please select your receivers"),
    notes: Yup.string().required("please enter your request content"),
  });

  const formik = useFormik({
    initialValues: {
      senderId: row?.senderId,
      senderImage: row?.senderImage,
      senderName: row?.senderName,
      senderEmail: row?.senderEmail,
      department: row?.department,
      receivers: selectors,
      status: row.status,
      notes: row.notes,
    },

    onSubmit: (values) => {
      values.receivers = selectors;
      values.id = row._id
      if (values.receivers.length === 0) {
        setEmpty(true);
      } else {
        // console.log(values.receivers);

        dispatch(updateOtherRequest(values))
          .unwrap()
          .then(() => {
            Store.addNotification({
              title: "Wonderful!",
              message: "Request is updated successfully",
              type: "default",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 4000,
                onScreen: true,
              },
            });

            handleNotification(values);
            
            // dispatch(getRequestEmployee({id : user?._id}))
          });
      }
    },
    validationSchema,
  });

  return (
    <form
      className={styles.editOtherRequestForm}
      onSubmit={formik.handleSubmit}
    >
      <Box marginY={4}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="User Name"
          variant="outlined"
          type="text"
          placeholder="User Name"
          name="senderName"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("senderName")}
        />
      </Box>

      <Box marginY={4}>
        {/* ------------endDate input-------------- */}
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="Department"
          variant="outlined"
          type="text"
          placeholder="department"
          name="department"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("department")}
        />
      </Box>

      {/* ------------ comments -------------- */}
      <Box marginY={4}>
        <Autocomplete
          multiple
          defaultValue={selectors}
          isOptionEqualToValue={(option , value) => option.username === value.username}
          disableCloseOnSelect
          id="tags-outlined"
          name="receivers"
          options={bdEmployees}
          getOptionLabel={(option) => option.username}
          filterSelectedOptions
          onChange={onTagChange}
          renderInput={(params) => (
            <TextField
              {...params}
              name="receivers"
              label="receivers"
              placeholder="receivers"
            />
          )}
        />
        {isSelectorEmpty ? (
          <div className={styles.errorStyle}>select at least one item</div>
        ) : null}
      </Box>

      <Box>
        <textarea
          style={{ width: "100%" }}
          size="small"
          id="outlined-basic-email"
          placeholder="Notes"
          variant="outlined"
          type="text"
          name="notes"
          rows={4}
          {...formik.getFieldProps("notes")}
        />
        {formik.touched.notes && formik.errors.notes ? (
          <div className={styles.errorStyle}>{formik.errors.notes}</div>
        ) : null}
      </Box>

      {/* ------------Save button -------------- */}
      <Box textAlign="center">
        <Button
          sx={{
            marginY: "4%",
            width: "80%",
            backgroundColor: "#12633F",
          }}
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </Box>

      {/* ------------back button -------------- */}
    </form>
  );
};
export default EditOther;
