import { Navigate, useLocation, useRoutes } from "react-router-dom";
// layouts

import LoginPage from "./pages/login/loginPage";
import Page404 from "./pages/underMaintenance/Page404";
import Loadable from "./loadable/lodable";
import { createContext, lazy, useState } from "react";
import SimpleLayout from "./layouts/simple/SimpleLayout";
import Home from "./pages/home/homePage";
import Requests from "./pages/requests/requests";
import AlignItemsList from "./pages/requests/otherRequestupdates";
import {
  Planning_Employee,
  Planning_Leader,
  admin,
  adminOnly,
  adminPlanning,
  bd,
  bim,
  hr,
  marketing,
  marketing_Leader,
  planning,
} from "./roles";
import TestNewGridTable from "./pages/projects/new/newTest";
import CreateProjectMarketing from "./components/addForms/addProjectMarketing";
import CreateProjectBD from "./components/addForms/addProjectBD";
import CreateProjectAdmin from "./components/addForms/addProjectBDAdmin";
import EditProjectBd from "./components/editForm/editProjectBd";
import EditProjectMarketing from "./components/editForm/editProjectMarketing";
import EditProjectBDAdmin from "./components/editForm/editProjectBDAdmin";
import EditProjectLeader from "./components/editForm/editProjectLeader";
import EditProjectPlanningAdmin from "./components/editForm/editProjectPlanningAdmin";
import EditProjectAssigned from "./components/editForm/editProjectAssigned";
import TestNotAssignedGridTable from "./pages/projects/notAssigned/notAsignedTest";
import TestInProgressGridTable from "./pages/projects/inProgress/inProgressTest";
import TestCompletedGridTable from "./pages/projects/completed/completedTest";
import ToBd from "./pages/projects/toBd/toBd";
import AllProjects from "./pages/projects/allProjects/allProjects";
import NotificationsList from "./pages/notifications/notificationsPage";
import Profile from "./pages/profile/profile";
import Admin_Team_Months_Table from "./pages/myTeam_admin/months";
import Admin_Team_Tasks_Table from "./pages/myTeam_admin/tasks";
import HR_Team_Tasks_Edit_Form from "./components/editForm/editOldTaskHr";
import SummaryCenteredTabsAdmin from "./pages/myTeam_admin/summary";

// ---------------------------------------------------------------------- //

export default function Router() {
  // imports
  const Company = Loadable(lazy(() => import("./pages/company/company")));
  const ViewDepartments = Loadable(
    lazy(() => import("./pages/departments/departments"))
  );
  const Employees = Loadable(lazy(() => import("./pages/employees/employee")));
  const OtherProfile = Loadable(
    lazy(() => import("./pages/profile/otherProfile"))
  );
  const CreateEmployee = Loadable(
    lazy(() => import("./components/addForms/addEmployee"))
  );
  const EditDepartment = Loadable(
    lazy(() => import("./components/editForm/editDepartment"))
  );
  const CreateDepartment = Loadable(
    lazy(() => import("./components/addForms/addDepartment"))
  );
  const EditEmployee = Loadable(
    lazy(() => import("./components/editForm/editEmployee"))
  );
  const BlockForm = Loadable(
    lazy(() => import("./components/blockForms/blockForm"))
  );
  const BlockedUsers = Loadable(
    lazy(() => import("./pages/blockedUsers/blockedUsers"))
  );

  /* ------------------------------------------------------------------- Reports ------------------------------------------------------------------------- */

  /* ------------ Planning -------------- */
  const DailyTasks = Loadable(
    lazy(() => import("./pages/Reports/dailyTasks/viewTasks"))
  );
  const AllTasks = Loadable(lazy(() => import("./pages/allTasks/allTasks")));
  const AllMonths = Loadable(lazy(() => import("./pages/allTasks/allMonths")));
  const AllDays = Loadable(lazy(() => import("./pages/allTasks/allDays")));
  const OldTasks = Loadable(lazy(() => import("./pages/allTasks/oldTasks")));
  const EditTasks = Loadable(
    lazy(() => import("./components/editForm/editTasksPlanning"))
  );
  const CreateOldTasks = Loadable(
    lazy(() => import("./components/addForms/createOldTask"))
  );
  const CreateSummary = Loadable(
    lazy(() => import("./components/addForms/addSummary"))
  );
  const CreateSummaryEmployee = Loadable(
    lazy(() => import("./components/addForms/addSummaryEmployee"))
  );
  const CreateTasksPlanning = Loadable(
    lazy(() => import("./components/addForms/createTasksPlanning"))
  );
  const EditOldTasks = Loadable(
    lazy(() => import("./components/editForm/editOldTaskPlanning"))
  );
  const ExpandTable = Loadable(
    lazy(() => import("./pages/myTeam_planning/collapsTable"))
  );
  const TeamMemberAllMonths = Loadable(
    lazy(() => import("./pages/myTeam_planning/teamMemberMonths"))
  );
  const EmptyDays = Loadable(
    lazy(() => import("./pages/myTeam_planning/allEmptyDays"))
  );
  const FinalMonthlyreport = Loadable(
    lazy(() => import("./pages/myTeam_planning/finalMonthlyReport"))
  );
  const TeamMemberAllDays = Loadable(
    lazy(() => import("./pages/myTeam_planning/teamMemberDays"))
  );
  const TeamMemberTasks = Loadable(
    lazy(() => import("./pages/myTeam_planning/teamMemberTasks"))
  );
  const TeamMemberProfile = Loadable(
    lazy(() => import("./pages/myTeam_planning/teamMemberProfile"))
  );

  /* ------------ BIM -------------- */
  const ExpandTableBim = Loadable(
    lazy(() => import("./pages/myTeam_planning/collapsTableBim"))
  );
  const EditTasksBim = Loadable(
    lazy(() => import("./components/editForm/editTasksBim"))
  );
  const CreateOldTasksBim = Loadable(
    lazy(() => import("./components/addForms/createOldTaskBim"))
  );
  const CreateTasksBim = Loadable(
    lazy(() => import("./components/addForms/createTaskBim"))
  );
  /* ------------ Marketting -------------- */
  const DailyTasksMarketing = Loadable(
    lazy(() => import("./pages/Reports/dailyTasks/viewTasksMarketing"))
  );
  const AllTasksMarketing = Loadable(
    lazy(() => import("./pages/allTasksMarketing/allTasks"))
  );
  const CreateYear = Loadable(
    lazy(() => import("./components/addForms/createNewYear"))
  );
  const AllMonthsMarketing = Loadable(
    lazy(() => import("./pages/allTasksMarketing/allMonths"))
  );
  const OldTasksMarketing = Loadable(
    lazy(() => import("./pages/allTasksMarketing/oldTasksMarketing"))
  );

  const EditTasksMarketing = Loadable(
    lazy(() => import("./components/editForm/editTasksMarketing"))
  );

  const CreateOldTasksMarketing = Loadable(
    lazy(() => import("./components/addForms/createOldTaskMarketing"))
  );
  const CreateSummaryMarketing = Loadable(
    lazy(() => import("./components/addForms/addSummaryMarketing"))
  );

  const CreateTasksMarketingAndHr = Loadable(
    lazy(() => import("./components/addForms/createTaskM,H"))
  );
  const CenteredTabsAdmin = Loadable(
    lazy(() => import("./pages/myTeam_marketing/myTeamTabsAdmin"))
  );
  const ExpandTableMarketing = Loadable(
    lazy(() => import("./pages/myTeam_marketing/collapsTable"))
  );
  const TeamMemberAllMonthsMarketing = Loadable(
    lazy(() => import("./pages/myTeam_marketing/teamMemberMonths"))
  );
  const TeamMemberTasksMarketing = Loadable(
    lazy(() => import("./pages/myTeam_marketing/teamMemberTasks"))
  );
  const CreateOldTasksMarketingAdmin = Loadable(
    lazy(() => import("./components/addForms/createOldTaskMarketingAdmin"))
  );
  const CreateOldTasksMarketingLeader = Loadable(
    lazy(() => import("./components/addForms/createOldTaskMarketingLeader"))
  );
  /* ------------------------------------------------------------------- Dashboard ------------------------------------------------------------------------- */

  const Chat = Loadable(lazy(() => import("./pages/dashboard/chat/chat")));

  /* ------------------------------------------------------------------- Projects ------------------------------------------------------------------------- */

  // hocks
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [value, setValue] = useState(0);

  // Create a custom context
  const SocketContext = createContext();
  const user = JSON.parse(localStorage.getItem("user"));

  // const
  const isLoggedIn = localStorage.getItem("user") ? true : false; // Replace with your actual login check logic
  const planningRole = planning(user);
  const planningEmployeeRole = Planning_Employee(user);
  const planningLeaderRole = Planning_Leader(user);
  const marketingLeaderRole = marketing_Leader(user);
  const hrRole = hr(user);
  const marketingRole = marketing(user);
  const bdRole = bd(user);
  const adminPlanningRole = adminPlanning(user);
  const adminBDRole = adminOnly(user);
  const bimRole = bim(user);
  const adminRole = admin(user);

  const routes = useRoutes([
    {
      path: "/",
      element: isLoggedIn ? (
        <Home
          SocketContext={SocketContext}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: "home",
          element: <Company setCurrentPage={setCurrentPage} />,
        },
        {
          path: "departments",
          children: [
            {
              path: "",
              element: <ViewDepartments setCurrentPage={setCurrentPage} />,
            },
            {
              path: "employees",
              children: [
                {
                  path: "",
                  element: <Employees setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "employeeProfile",
                  element: <OtherProfile setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "addEmployee",
                  element: <CreateEmployee setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "editEmloyee",
                  element: <EditEmployee setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "blockEmployee",
                  element: <BlockForm setCurrentPage={setCurrentPage} />,
                },
              ],
            },
            {
              path: "addDepartment",
              element: <CreateDepartment setCurrentPage={setCurrentPage} />,
            },
            {
              path: "editDepartment",
              element: <EditDepartment setCurrentPage={setCurrentPage} />,
            },
          ],
        },
        {
          path: "blocked",
          element: <BlockedUsers setCurrentPage={setCurrentPage} />,
        },
        {
          path: "requests",
          children: [
            {
              path: "",
              element: (
                <Requests
                  socketContext={SocketContext}
                  value={value}
                  setValue={setValue}
                  setCurrentPage={setCurrentPage}
                />
              ),
            },
            {
              path: "updates",
              element: (
                <AlignItemsList
                  setValue={setValue}
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ),
            },
          ],
        },
        {
          path: "dashboard/chat",
          element: <Chat socketContext={SocketContext} />,
        },
        {
          path: "projects",
          children: [
            {
              path: "New",
              children: [
                {
                  path: ":id",
                  element: <TestNewGridTable setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "createProject",
                  element: marketingLeaderRole ? (
                    <CreateProjectMarketing
                      socketContext={SocketContext}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : bdRole ? (
                    <CreateProjectBD
                      socketContext={SocketContext}
                      setCurrentPage={setCurrentPage}
                    />
                  ) : (
                    <CreateProjectAdmin
                      socketContext={SocketContext}
                      setCurrentPage={setCurrentPage}
                    />
                  ),
                },
              ],
            },
            {
              path: "NotAssigned",
              children: [
                {
                  path: ":id",
                  element: (
                    <TestNotAssignedGridTable setCurrentPage={setCurrentPage} />
                  ),
                },
              ],
            },
            {
              path: "InProgress",
              children: [
                {
                  path: ":id",
                  element: (
                    <TestInProgressGridTable setCurrentPage={setCurrentPage} />
                  ),
                },
              ],
            },
            {
              path: "Completed",
              children: [
                {
                  path: ":id",
                  element: (
                    <TestCompletedGridTable setCurrentPage={setCurrentPage} />
                  ),
                },
              ],
            },
            {
              path: "ToBd",
              children: [
                {
                  path: ":id",
                  element: <ToBd setCurrentPage={setCurrentPage} />,
                },
              ],
            },
            {
              path: "allProjects",
              children: [
                {
                  path: ":id",
                  element: <AllProjects setCurrentPage={setCurrentPage} />,
                },
              ],
            },
            {
              path: "editProject",
              element: bdRole ? (
                <EditProjectBd
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : marketingRole ? (
                <EditProjectMarketing
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : adminBDRole ? (
                <EditProjectBDAdmin
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : planningLeaderRole ? (
                <EditProjectLeader
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : adminPlanningRole ? (
                <EditProjectPlanningAdmin
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : planningEmployeeRole || bimRole ? (
                <EditProjectAssigned
                  socketContext={SocketContext}
                  setCurrentPage={setCurrentPage}
                />
              ) : (
                ""
              ),
            },
          ],
        },
        {
          path: "reports",
          children: [
            {
              path: "dailyTasks",
              children: [
                {
                  path: "",
                  element:
                    planningRole || adminPlanningRole || bimRole ? (
                      <DailyTasks setCurrentPage={setCurrentPage} />
                    ) : (
                      <DailyTasksMarketing setCurrentPage={setCurrentPage} />
                    ),
                },
                {
                  path: "addTask",
                  element:
                    planningRole || adminPlanningRole ? (
                      <CreateTasksPlanning setCurrentPage={setCurrentPage} />
                    ) : bimRole ? (
                      <CreateTasksBim setCurrentPage={setCurrentPage} />
                    ) : (
                      <CreateTasksMarketingAndHr
                        setCurrentPage={setCurrentPage}
                      />
                    ),
                },
                {
                  path: "edittaskhr",
                  element: (
                    <HR_Team_Tasks_Edit_Form setCurrentPage={setCurrentPage} />
                  ),
                },
                {
                  path: "editTask",
                  element:
                    planningRole || adminPlanningRole ? (
                      <EditTasks setCurrentPage={setCurrentPage} />
                    ) : bimRole ? (
                      <EditTasksBim setCurrentPage={setCurrentPage} />
                    ) : (
                      <EditTasksMarketing setCurrentPage={setCurrentPage} />
                    ),
                },
              ],
            },
            {
              path: "summary",
              element: adminRole ? (
                <SummaryCenteredTabsAdmin setCurrentPage={setCurrentPage} />
              ) : planningLeaderRole ? (
                <CreateSummary setCurrentPage={setCurrentPage} />
              ) : planningRole || bimRole ? (
                <CreateSummaryEmployee setCurrentPage={setCurrentPage} />
              ) : (
                <CreateSummaryMarketing setCurrentPage={setCurrentPage} />
              ),
            },
            {
              path: "bimteam",
              element: <ExpandTableBim setCurrentPage={setCurrentPage} />,
            },
            {
              path: "myTeam",
              children: [
                {
                  path: "",
                  element: planningLeaderRole ? (
                    <ExpandTable setCurrentPage={setCurrentPage} />
                  ) : adminRole || hrRole ? (
                    <CenteredTabsAdmin setCurrentPage={setCurrentPage} />
                  ) : (
                    <ExpandTableMarketing setCurrentPage={setCurrentPage} />
                  ),
                },
                {
                  path: "months",
                  children: [
                    {
                      path: "",
                      element: adminRole ? (
                        <Admin_Team_Months_Table
                          setCurrentPage={setCurrentPage}
                        />
                      ) : planningRole || hrRole ? (
                        <TeamMemberAllMonths setCurrentPage={setCurrentPage} />
                      ) : (
                        <TeamMemberAllMonthsMarketing
                          setCurrentPage={setCurrentPage}
                        />
                      ),
                    },
                    {
                      path: "monthlyReport",
                      element: <EmptyDays setCurrentPage={setCurrentPage} />,
                    },
                    {
                      path: "FinalmonthlyReport",
                      element: (
                        <FinalMonthlyreport setCurrentPage={setCurrentPage} />
                      ),
                    },
                    {
                      path: "days",
                      children: [
                        {
                          path: "",
                          element: (
                            <TeamMemberAllDays
                              setCurrentPage={setCurrentPage}
                            />
                          ),
                        },
                        {
                          path: "tasks",
                          children: [
                            {
                              path: "",
                              element: adminRole ? (
                                <Admin_Team_Tasks_Table
                                  setCurrentPage={setCurrentPage}
                                />
                              ) : planningRole || hrRole ? (
                                <TeamMemberTasks
                                  setCurrentPage={setCurrentPage}
                                />
                              ) : (
                                <TeamMemberTasksMarketing
                                  setCurrentPage={setCurrentPage}
                                />
                              ),
                            },
                            {
                              path: "createTask",
                              element: adminBDRole ? (
                                <CreateOldTasksMarketingAdmin
                                  socketContext={SocketContext}
                                  setCurrentPage={setCurrentPage}
                                />
                              ) : (
                                <CreateOldTasksMarketingLeader
                                  socketContext={SocketContext}
                                  setCurrentPage={setCurrentPage}
                                />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "profile",
                  element: (
                    <TeamMemberProfile setCurrentPage={setCurrentPage} />
                  ),
                },
              ],
            },
            {
              path: "allTasks",
              children: [
                {
                  path: "",
                  element:
                    planningRole || adminPlanningRole || bimRole ? (
                      <AllTasks setCurrentPage={setCurrentPage} />
                    ) : (
                      <AllTasksMarketing setCurrentPage={setCurrentPage} />
                    ),
                },
                {
                  path: "createYear",
                  element: <CreateYear setCurrentPage={setCurrentPage} />,
                },
                {
                  path: "allMonths",
                  children: [
                    {
                      path: "",
                      element:
                        planningRole || adminPlanningRole || bimRole ? (
                          <AllMonths setCurrentPage={setCurrentPage} />
                        ) : (
                          <AllMonthsMarketing setCurrentPage={setCurrentPage} />
                        ),
                    },
                    {
                      path: "days",
                      children: [
                        {
                          path: "",
                          element: <AllDays setCurrentPage={setCurrentPage} />,
                        },
                        {
                          path: "oldTasks",
                          children: [
                            {
                              path: "",
                              element:
                                planningRole || adminPlanningRole || bimRole ? (
                                  <OldTasks setCurrentPage={setCurrentPage} />
                                ) : (
                                  <OldTasksMarketing
                                    setCurrentPage={setCurrentPage}
                                  />
                                ),
                            },
                            {
                              path: "editTask",
                              element:
                                planningRole || adminPlanningRole ? (
                                  <EditTasks setCurrentPage={setCurrentPage} />
                                ) : bimRole ? (
                                  <EditTasksBim
                                    setCurrentPage={setCurrentPage}
                                  />
                                ) : (
                                  <EditTasksMarketing
                                    setCurrentPage={setCurrentPage}
                                  />
                                ),
                            },
                            {
                              path: "addTask",
                              element:
                                planningRole || adminPlanningRole ? (
                                  <CreateOldTasks
                                    setCurrentPage={setCurrentPage}
                                  />
                                ) : bimRole ? (
                                  <CreateOldTasksBim
                                    setCurrentPage={setCurrentPage}
                                  />
                                ) : (
                                  <CreateOldTasksMarketing
                                    setCurrentPage={setCurrentPage}
                                  />
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "notifications",
          element: <NotificationsList setCurrentPage={setCurrentPage} />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "login",
      element: isLoggedIn ? <Navigate to="/company" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/company" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
