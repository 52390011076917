import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EditOther from "./editOtherRequestForm";

export default function FormDialog({ row, socket }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginRight: "10px", cursor: "pointer" }}>
      <BorderColorOutlinedIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <EditOther row={row} socket={socket} handleClose={handleClose} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
