import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import pealsLogo from "../../assets/final.png";
import styles from "./login.module.css";
import { ButtonBase, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login, reset } from "../../store/employees/employeesSlice";
import Loadable from "../../loadable/lodable";

export default function LoginPage() {
  const MyImage = Loadable(React.lazy(() => import("./loginImage")));
  const [loginStatus, setLoginStatus] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, user, isSuccess, isError, message } = useSelector(
    (state) => state.employees
  );

  const validationSchema = Yup.object({
    email: Yup.string().required("Please Enter your Username"),
    password: Yup.string().required("Please Enter your Password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: (values) => {
      //console.log(values);
      const userData = {
        username: values.email,
        password: values.password,
      };

      dispatch(login(userData));
      if (isError) {
        //console.log("error mesage");
      }
      if (isSuccess || user) {
        navigate("/home");
        dispatch(reset());
      }
    },
    validationSchema,
  });
  const myUser = localStorage.getItem("user");
  useEffect(() => {
    if (myUser) {
      // dispatch(reset())
      navigate("/home");
    }
  }, [user, isSuccess, isError, message, navigate, dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <Grid container>
        <Grid item xs={8}>
          <Box
            sx={{
              backgroundColor: "#fff",
              top: 0,
              bottom: 0,
              position: "absolute",
              width: "66.66666666666667%",
            }}
          >
            <MyImage />
            {/* background image */}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="c"
            flexDirection="column"
            sx={{
              backgroundColor: "#fff",
              top: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              width: "35.33333333333333%",
            }}
          >
            <h2 className={styles.subLogoStyle}>Pearls Net</h2>
            <h2
              style={{
                marginTop: "15%",
                marginBottom: "0%",
                fontFamily: "tahoma",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={pealsLogo} alt="" className={styles.logoStyle} />
            </h2>

            <p
              style={{
                marginTop: "13%",
                padding:"0",
                marginBottom: "0%",
                fontFamily: "tahoma",
                fontSize: "1.4rem",
              }}
            >
              Welcome Back !
            </p>
            <p
              style={{
                marginTop: "0%",
                fontFamily: "tahoma",
                fontSize: "1rem",
                color: "#7d7f97",
              }}
            >
              Sign in to continue to Pearls Net
            </p>
            {!loginStatus && (
              <>
                <form
                  style={{ width: "80%", marginTop: "10%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <div style={{ width: "100%", marginTop: "3%" }}>
                    <label
                      htmlFor="email"
                      className={`form-label  ${styles.labelStyle}`}
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="  Enter Your Username"
                      className={styles.email}
                      style={{
                        width: "100%",
                        height: "40px",
                        fontSize: "0.9rem",
                        marginBottom: "0%",
                      }}
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "0.9rem",
                        marginTop: "0.5%",
                      }}
                      className="error"
                    >
                      {formik.errors.email}
                    </div>
                  ) : null}

                  <div style={{ width: "100%", marginTop: "3%" }}>
                    <label
                      htmlFor="password"
                      className={`form-label  ${styles.labelStyle}`}
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      placeholder="  Enter Your Password"
                      className={styles.email}
                      style={{
                        width: "100%",
                        height: "40px",
                        fontSize: "0.9rem",
                        marginBottom: "0%",
                      }}
                      {...formik.getFieldProps("password")}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "0.9rem",
                        marginTop: "0.5%",
                      }}
                      className="error"
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}

                  <div>
                    <ButtonBase
                      type="submit"
                      sx={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "5px",
                        backgroundColor: "#3d5afe",
                        color: "white",
                        fontSize: "1rem",
                        marginTop: "7%",
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Sign In"
                      )}
                    </ButtonBase>
                  </div>
                  {isError && (
                    <div>
                      <span
                        style={{
                          color: "red",
                          marginLeft: "0",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        username or password is inCorrect
                      </span>
                    </div>
                  )}
                </form>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
