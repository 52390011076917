import { Alert, Button, ButtonBase, Container } from "@mui/material";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./myTeam.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { getMonths } from "../../store/months/monthsSliceMarketing";
import BasicModalMarketing from "../../components/editForm/editScoreMarketing";


const TeamMemberAllMonthsMarketing = ({ setCurrentPage }) => {
  const { user } = useSelector((state) => state.employees);
  const location = useLocation();
  
  const {employee , yearId} = user && location.state 
  const { allMonths, isLoading } = useSelector((state) => state.monthsMarketing);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentDate = new Date().getMonth()+1
  

  React.useEffect(() => {
    if (user) {
      dispatch(getMonths({ id: employee._id , year: yearId }));
    }
    setCurrentPage(location.pathname);
  }, [navigate]);

  const totalPersentatge = (arr) => {
    return iterator(arr, "totalTasks") !== 0
      ? parseFloat(
          (iterator(arr, "Completed") / iterator(arr, "totalTasks")) * 100
        ).toFixed(2)
      : 0;
  };
  
  const iterator = (arr, status) => {
    if (status === "totalTasks") {
      return arr.length;
    } else
      return arr.filter((val) => {
        return val.status === status;
      }).length;
  };


  return (
    <Box sx={{ border: "1px solid #E9E9EF" }}>
      <Box className={styles.reportContainer}>
        <>
          {allMonths.length === 0 && !isLoading && (
            <Box className={styles.alertContainerStyle} height="400px">
              <Alert
                variant="filled"
                severity="info"
                className={styles.alertStyle}
              >
                You don't have any months in {location.state.yearName}
              </Alert>
            </Box>
          )}
          <ButtonBase
            onClick={() => navigate(-1)}
            type="submit"
            className={styles.backButton}
          >
            <i className={`fa-solid fa-angle-left ${styles.addIconStyle}`}></i>
            back
          </ButtonBase>
          <p
            style={{
              marginTop: "2%",
              fontFamily: "tahoma",
              color: "#495057",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            Months Of {location.state.yearName}
          </p>
          <Box></Box>

          {isLoading ? (
            <div className={styles.progressContainer}>
              <CircularProgress className={styles.progress} />
            </div>
          ) : (
            allMonths.length !== 0 && (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 400,
                }}
                className={styles.tableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                  <TableHead className={styles.tableHeadStyle}>
                    <TableRow>
                      <TableCell className={styles.headerCell} align="right">
                        date
                      </TableCell>
                      <TableCell className={styles.headerCell} align="right">
                        month
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        completed{" "}
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        inProgress
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        not Started
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        postponed
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        Total tasks
                      </TableCell>
                      <TableCell className={styles.headerCell} align="left">
                        Total % complete
                      </TableCell>
                      {/* <TableCell className={styles.headerCell} align="left">
                        monthly Score (%)
                      </TableCell> */}
                      <TableCell className={styles.headerCell} align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allMonths &&
                      allMonths.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell className={styles.dataCell} align="right">
                            {row.name} - {row.year.name}
                          </TableCell>
                          <TableCell className={styles.dataCell} align="right">
                            {row.alphaName}
                          </TableCell>

                          {/* complete */}
                          <TableCell className={styles.dataCell} align="right">
                            {iterator(row.tasks, "Completed")}
                          </TableCell>

                          {/* inProgress */}
                          <TableCell className={styles.dataCell} align="right">
                            {iterator(row.tasks, "In Progress")}
                          </TableCell>

                          {/* not Started */}
                          <TableCell className={styles.dataCell} align="right">
                            {iterator(row.tasks, "Not Started")}
                          </TableCell>

                          {/* postponed */}
                          <TableCell className={styles.dataCell} align="right">
                            {iterator(row.tasks, "postponed")}
                          </TableCell>

                          {/* total tasks */}
                          <TableCell className={styles.dataCell} align="right">
                            {iterator(row.tasks, "totalTasks")}
                          </TableCell>

                          {/* complete percentage */}
                          <TableCell className={styles.dataCell} align="right">
                            <span
                              className={
                                totalPersentatge(row.tasks) >= 66.6
                                  ? styles.bigPersentageDataCell
                                  : totalPersentatge(row.tasks) < 66.6 &&
                                    totalPersentatge(row.tasks) >= 33.3
                                  ? styles.middlePersentageDataCell
                                  : styles.lessPersentageDataCell
                              }
                            >
                              {totalPersentatge(row.tasks)}%
                            </span>
                          </TableCell>

                          {/* <TableCell className={styles.dataCell} align="right">
                            {row.monthlyscore}
                            {Math.abs(currentDate - row.name) < 2 && 
                            <BasicModalMarketing row={row}/>
                            }
                          </TableCell> */}


                          <TableCell
                            className={styles.buttonsContainer}
                            align="right"
                          >

                            <Button
                              onClick={() => {
                                setCurrentPage(
                                  "/reports/myTeam/months/days/tasks"
                                );
                                navigate(`/reports/myTeam/months/days/tasks`, {
                                  state: {
                                    title: row.alphaName,
                                    month: row._id,
                                    schema: `${row.alphaName} ${location.state.yearNameClear}`,
                                    employee: employee?._id,
                                    yearId: yearId,
                                  },
                                });
                              }}
                              className={styles.editButton}
                              variant="text"
                            >
                              {`view tasks >>`}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </>
      </Box>
    </Box>
  );
};
export default TeamMemberAllMonthsMarketing;
