import * as React from "react";
import Box from "@mui/material/Box";
import styles from "./requests.module.css";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate } from "react-router-dom";
import {
  getRequest,
  getRequestHr,
  updateRequestHr,
} from "../../store/requests/requestsSlice";
import { useDispatch, useSelector } from "react-redux";
import AlertDialogSlide from "./requestDialog";
import DeleteOtherRequestdialog from "./deleteOtherRequestDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function getstartDate(params) {
  return params.row.startDate.split("T")[0];
}

function getEndDate(params) {
  return params.row.endDate.split("T")[0];
}

export default function DataGridDemoHr({ status, requestType }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.employees);
  const { allRequests, isLoading } = useSelector((state) => state.requests);

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  React.useEffect(() => {
    if (user) {
      dispatch(
        getRequestHr({
          id: user?._id,
          status: status,
          requestType: requestType,
        })
      );
    }
  }, [navigate]);

  const handelApprove = (rowData) => {
    dispatch(
      updateRequestHr({
        id: rowData.row?._id,
        receiver: rowData.row?.receiverId,
        approvedFromLeader: true,
        status: "Take It",
        requestType: requestType,
      })
    );
  };

  const handelReject = (rowData) => {
    dispatch(
      updateRequestHr({
        id: rowData.row?._id,
        receiver: rowData.row?.receiverId,
        approvedFromLeader: true,
        status: "Not Taken",
        requestType: requestType,
      })
    );
  };

  const columns = [
    {
      field: "senderName",
      headerName: "Sender Name",
      width: 150,
    },
    {
      field: "receiverEmail",
      headerName: "Sender Email",
      width: 250,
    },

    {
      field: "department",
      headerName: "Department",
      width: 150,
    },
    {
      field: "requestTime",
      headerName: "Type",
      width: 100,
    },
    {
      field: "startDate",
      headerName: "From",
      width: 150,
      valueGetter: getstartDate,
    },

    {
      field: "endDate",
      headerName: "To",
      width: 150,
      valueGetter: getEndDate,
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <span
            className={
              params.row.status === "InProgress"
                ? styles.inProgressStatus
                : params.row.status === "Take It"
                ? styles.approvedStatus
                : params.row.status === "Not Taken"
                ? styles.notTaken
                : params.row.status === "Rejected"
                ? styles.notAssignedStatus
                : styles.newStatus
            }
          >
            {params.row.status === "InProgress" && (
              <LoopIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Take It" && (
              <CheckIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Rejected" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status === "Not Taken" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status}
          </span>
        );
      },
      headerClassName: styles.dataCell,
    }, // Status

    {
      field: "action",
      headerName: "action",
      width: 150,
      renderCell: (params) => {
        return (
          <AlertDialogSlide
            bodyMessage={"Select action for vacation request from "}
            headerMessage={"Vacation Request action"}
            approveButtonContent={"Take It"}
            rejectedButtonContent={"Not Taken"}
            handelApprove={handelApprove}
            handelReject={handelReject}
            rowData={params}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {params.row.status !== "New" &&
              params.row.status !== "InProgress" && (
                <DeleteOtherRequestdialog
                  setSnackbar={setSnackbar}
                  rowId={params.row._id}
                  userId={user?._id}
                  dispatch={dispatch}
                  state={"vacation_hr"}
                  status={status}
                  requestType={requestType}
                />
              )}
          </Box>
        );
      },
    }, // action
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={allRequests}
        columns={columns}
        getRowId={(row) => row._id}
        loading={isLoading}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />

      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={3000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
