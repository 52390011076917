import React, { useState } from "react";
import { Autocomplete, Box, ButtonBase, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./CreateTasks.module.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomSummary } from "../../store/customSummary/customSummarySliceMarketing";
import FinalSummaryMarketing from "../../pages/Reports/finalSummary/finalSummaryMarketing";
import { getEmployeesByDepartmentName } from "../../store/employees/employeesSlice";

const CreateSummaryMarketingAdmin = ({ depart }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isError, user, bdEmployees } = useSelector(
    (state) => state.employees
  );
  const { allCustomSummary, isLoading } = useSelector(
    (state) => state.customSummaryMarketing
  );

  const [selectors, setSelectors] = useState([]);
  const [isSelectorEmpty, setEmpty] = useState(false);

  const onTagChange = (event, values) => {
    console.log(values);
    setSelectors(values);
    setEmpty(false);
  };

  React.useEffect(() => {
    dispatch(getEmployeesByDepartmentName(depart || "Planning"));
  }, [navigate]);

  const validationSchema = Yup.object({
    startDate: Yup.string().required("Please enter start date "),
    endDate: Yup.string().required("Please enter end date "),
  });

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      searchers: selectors,
    },

    onSubmit: (values) => {
      values.searchers = selectors;
      if (values.searchers.length === 0) {
        setEmpty(true);
      } else {
        values.employeeId = user._id;
        values.role = true;

        dispatch(getCustomSummary(values));
      }
    },
    validationSchema,
  });

  return (
    <>
      <Box className={styles.formContainer}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            {/* ------------startDate  input-------------- */}

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className="mb-2 col-sm-12 col-md-12">
                  <label
                    htmlFor="startDate "
                    className={`form-label  ${styles.labelStyle}`}
                  >
                    start date{" "}
                  </label>
                  <input
                    className={`form-control ${styles.inputStyle}`}
                    placeholder="Enter department Name"
                    type="month"
                    name="startDate"
                    {...formik.getFieldProps("startDate")}
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div className={styles.errorStyle}>
                      {formik.errors.startDate}
                    </div>
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                {/* ------------endDate input-------------- */}
                <div className="mb-2 col-sm-12 col-md-12">
                  <label
                    htmlFor="endDate"
                    className={`form-label  ${styles.labelStyle}`}
                  >
                    end date{" "}
                  </label>
                  <input
                    className={`form-control ${styles.inputStyle}`}
                    placeholder="Enter department Name"
                    type="month"
                    name="endDate"
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className={styles.errorStyle}>
                      {formik.errors.endDate}
                    </div>
                  ) : null}
                </div>
              </Grid>
            </Grid>

            <Box>
              <Autocomplete
                fullWidth
                multiple
                disableCloseOnSelect
                id="tags-outlined"
                name="searchers"
                options={bdEmployees}
                getOptionLabel={(option) => option.username}
                filterSelectedOptions
                onChange={onTagChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="searchers"
                    label="who you want to get ?"
                    placeholder="who you want to get ?"
                  />
                )}
              />
              {isSelectorEmpty ? (
                <div className={styles.errorStyle}>
                  select at least one item
                </div>
              ) : null}
            </Box>

            {/* ------------Save button -------------- */}
            <ButtonBase type="submit" className={styles.saveButton}>
              <i
                style={{ marginRight: "10%" }}
                className="fa-solid fa-magnifying-glass"
              ></i>
              get data
            </ButtonBase>
          </form>
        </Box>
      </Box>
      <Box>
        <FinalSummaryMarketing allSummaries={allCustomSummary} />
      </Box>
    </>
  );
};

export default CreateSummaryMarketingAdmin;
