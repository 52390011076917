import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import styles from "./summary.module.css";
import { useSelector } from "react-redux";
import { bim } from "../../../roles";

const CustomSummaryTable = ({ allTasks }) => {
  const convertMinutesToHours = (totalHours, totalMinutes) => {
    var num = totalMinutes;
    var hours = num / 60;
    var rhours = Math.floor(hours) + totalHours;
    var minutes = (hours - Math.floor(hours)) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes <= 9) rminutes = "0" + rminutes;
    if (rhours <= 9) rhours = "0" + rhours;
    return rhours + ":" + rminutes;
  };

  const sumOfHours = () => {
    let totalHours = 0;
    let totalMinutes = 0;
    allTasks.forEach((val) => {
      totalHours += +val.grandTotal.split(":")[0];
      totalMinutes += +val.grandTotal.split(":")[1];
    });
    if (totalMinutes === 60) {
      totalHours += 1;
      return totalHours + ":00";
    } else if (totalMinutes < 60) {
      if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
      if (totalHours <= 9) totalHours = "0" + totalHours;
      return totalHours + ":" + totalMinutes;
    } else {
      return convertMinutesToHours(totalHours, totalMinutes);
    }
  };

  const iterator = (categorypar) => {
    let totalHours = 0;
    let totalMinutes = 0;
    allTasks.forEach((val) => {
      totalHours += +val[categorypar].split(":")[0];
      totalMinutes += +val[categorypar].split(":")[1];
    });
    if (totalMinutes === 60) {
      totalHours += 1;
      return totalHours + ":00";
    } else if (totalMinutes < 60) {
      if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
      if (totalHours <= 9) totalHours = "0" + totalHours;
      return totalHours + ":" + totalMinutes;
    } else {
      return convertMinutesToHours(totalHours, totalMinutes);
    }
  };

  const getTotalTasks = () => {
    return allTasks.reduce((prev, cur) => {
      return prev + +cur.totalTasks;
    }, 0);
  };

  const { user } = useSelector((state) => state.employees);
  const bimRole = bim(user);

  const categories = bimRole
    ? [
        "Project_Admin",
        "Technical",
        "Office_Admin",
        "Training",
        "Break",
        "No_Work_Assigned",
      ]
    : [
        "Project_Admin",
        "Technical",
        "Assisting",
        "Office_Admin",
        "Internal_Meetings",
        "Training",
        "Break",
        "No_Work_Assigned",
      ];
  const colors = bimRole
    ? ["#FFBF53", "#dd2c00", "#76ff03", "#2962ff", "#9575cd", "#f06292"]
    : [
        "#52AAF0",
        "#2AB57D",
        "#FFBF53",
        "#dd2c00",
        "#76ff03",
        "#2962ff",
        "#9575cd",
        "#f06292",
      ];

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table className={styles.tableStyle} aria-label="simple table">
        <TableHead className={styles.tableHeadStyle}>
          <TableRow>
            <TableCell>Summary</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              Total Tasks
            </TableCell>
            <TableCell align="right">{getTotalTasks()}</TableCell>
          </TableRow>
          {categories.map((item, index) => {
            return (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 1 },
                }}
              >
                <TableCell component="th" scope="row">
                  {item}
                </TableCell>
                <TableCell align="right">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        backgroundColor: `${colors[index]}`,
                        height: "1rem",
                        width: "1rem",
                      }}
                    ></div>
                    <div>{iterator(item)}</div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              Grand Total
            </TableCell>
            <TableCell align="right">{sumOfHours()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CustomSummaryTable;
