import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import styles from "./notification.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editNotification,
  getNotification,
} from "../../store/notifications/notificationsSlice";
import { Avatar, Divider, List, ListItem } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useNavigate } from "react-router-dom";
import NotificationSound from "./notification-sound.mp3";
import systemAvatar from "../../assets/logo3.png"

const StyledMenu = styled((props) => (
  <Menu
    className={styles.menueStyle}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0.1),
    minWidth: 360,
    [theme.breakpoints.down("sm")]: {
      minWidth: "91%",
      marginTop: "3%",
    },
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function AccountMenu({ socket }) {
  const { testAllNotifications } = useSelector((state) => state.notifications);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useSelector((state) => state.employees);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const audioPlayer = React.useRef(null);

  React.useEffect(() => {
    if (user) {
      socket?.on("getNotification", (data) => {
        console.log(data);
        dispatch(getNotification({ id: user._id, status: "unread" }));
        audioPlayer.current.play();
      });

      socket?.on("getNewGlopalMessage", (data) => {
        console.log(data);
      });
    }
  }, [socket]);

  React.useEffect(() => {
    if (user) {
      dispatch(getNotification({ id: user._id, status: "unread" }));
    }
  }, []);

  const handleClick = (event) => {
    dispatch(editNotification(user?._id));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(getNotification({ id: user?._id, status: "unread" }));
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <audio ref={audioPlayer} src={NotificationSound} />
        <Tooltip title="notifications">
          <IconButton
            className={styles.notificationButton}
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <i className={`fa-regular fa-bell ${styles.notificationStyle}`}></i>
            {testAllNotifications?.length > 0 && (
              <div className={styles.notification_counter}>
                {testAllNotifications.length}
              </div>
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <StyledMenu
        className={styles.menue}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {testAllNotifications.length <= 0 ? (
          <div>
            <div className={styles.noNotificationsYet}>
              {" "}
              you don't have new notifications yet
            </div>
            <MenuItem
              onClick={() => {
                navigate("/notifications");
                handleClose();
              }}
              sx={{
                margin: "2%",
                marginTop: "4%",
                boxShadow: "0 0 8px #8286f0",
                display: "flex",
                justifyContent: "center",
                borderRadius: "6px",
              }}
            >
              <ArrowCircleRightIcon className={styles.arrowStyle} />
              <span className={styles.viewAllSpan}> view all</span>
            </MenuItem>
          </div>
        ) : (
          <div>
            <div className={styles.notificationsHeader}>
              <span className={styles.notificationSpan}>Notifications</span>
            </div>
            <Divider className={styles.notificationDivider} />

            <List sx={{ maxHeight: "250px", overflowY: "scroll" }}>
              {testAllNotifications.slice(0, 5).map((row, index) => {
                return (
                  <ListItem key={index} onClick={handleClose}>
                    <Avatar sx={{ marginRight: "1%" }} src={row.sender ? row.sender?.image : systemAvatar} />
                    <div className={styles.listItemContainer}>
                      <span className={styles.senderSpan}>
                        {row.sender? row.sender?.username : "Pearls Net"}
                      </span>
                      <span className={styles.messageSpan}>
                        {
                          row.receiver.filter((item) => {
                            return item.receiverId === user?._id;
                          })[0]?.message
                        }
                      </span>
                      <span className={styles.messageSpan}>
                        {row.created_date}
                        {" at "}
                        {row?.created_time}
                      </span>
                    </div>
                  </ListItem>
                );
              })}
            </List>

            <Divider className={styles.notificationDivider} />
            <MenuItem
              onClick={() => {
                navigate("/notifications");
                handleClose();
              }}
              sx={{
                margin: "2%",
                marginTop: "4%",
                boxShadow: "0 0 8px #8286f0",
                display: "flex",
                justifyContent: "center",
                borderRadius: "6px",
              }}
            >
              <ArrowCircleRightIcon className={styles.arrowStyle} />
              <span className={styles.viewAllSpan}> view all</span>
            </MenuItem>
          </div>
        )}
      </StyledMenu>
    </>
  );
}
