const convertMinutesToHours = (totalHours, totalMinutes) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours) + totalHours;
  var minutes = (hours - Math.floor(hours)) * 60;
  var rminutes = Math.round(minutes);
  if (rminutes <= 9) rminutes = "0" + rminutes;
  if (rhours <= 9) rhours = "0" + rhours;
  return rhours + ":" + rminutes;
};

export const sumOfHours = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours += +val.timeSpent?.split(":")[0];
    totalMinutes += +val.timeSpent?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;
    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const sumOfHoursBaseLine = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours += +val.timeSpent?.split(":")[0];
    totalMinutes += +val.timeSpent?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;
    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const sumOfHoursDeduct = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks
    .filter((item) => {
      return item.deduct;
    })
    .forEach((val) => {
      totalHours += +val.timeSpent?.split(":")[0];
      totalMinutes += +val.timeSpent?.split(":")[1];
    });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;
    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const timeConvert = (totalHours, totalMinutes) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours) + totalHours;
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ":" + rminutes;
};

export const changeTimeSpend = (est, spent) => {
  const estHours = +est.split(":")[0];
  const spentHours = +spent.split(":")[0];
  let totalHours = estHours - spentHours;
  // if (totalHours < 0) totalHours = totalHours.toFixed(2);

  const estMinutes = +est.split(":")[1];
  const spentMinutes = +spent.split(":")[1];
  let totalMinutes = estMinutes - spentMinutes;

  if (totalHours > 0 && totalMinutes < 0) {
    totalHours = totalHours - 1;
    totalMinutes = 60 + totalMinutes;
    // if (totalHours < 0) totalHours = totalHours.toFixed(2);
  }

  let totalTime;
  if (totalMinutes < 60) {
    if (totalMinutes <= 9 && totalMinutes > 0) {
      totalMinutes = "0" + totalMinutes;
    }
    totalTime =
      (totalHours % 1 != 0 ? totalHours.toFixed(1) : totalHours) +
      ":" +
      totalMinutes;
    return totalTime;
  } else if (totalMinutes === 60) {
    totalHours += 1;

    totalTime =
      (totalHours % 1 != 0 ? totalHours.toFixed(1) : totalHours) + ":" + "00";
    return totalTime;
  } else {
    totalTime = timeConvert(
      totalHours % 1 != 0 ? totalHours.toFixed(1) : totalHours,
      totalMinutes
    );
    return totalTime;
  }
};
