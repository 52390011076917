import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import styles from './avatar.module.css'
export default function ImageAvatar({src}) {

  return (
      <Avatar
        alt="Remy Sharp"
        src={src}
        className={styles.avatar}
      />
   
  );
}