import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteOtherRequest,
  deleteVacationRequest,
  deleteVacationRequestHr,
  deleteVacationRequestLeader,
} from "../../store/requests/requestsSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteOtherRequestdialog({
  rowId,
  userId,
  dispatch,
  setSnackbar,
  state,
  status,
  requestType,
  viewStatus,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelDeleteRequest = () => {
    switch (state) {
      case "other":
        // code block
        dispatch(
          deleteOtherRequest({
            id: rowId,
            userId: userId,
            viewStatus:viewStatus
          })
        );
        break;
      case "vacation":
        dispatch(
          deleteVacationRequest({
            id: rowId,
            userId: userId,
            requestType: requestType,
          })
        );
        // code block
        break;
      case "vacation_hr":
        dispatch(
          deleteVacationRequestHr({
            id: rowId,
            userId: userId,
            status: status,
            requestType: requestType,
          })
        );
        // code block
        break;
      case "vacation_leader":
        console.log(status);
        dispatch(
          deleteVacationRequestLeader({
            id: rowId,
            userId: userId,
            status: status,
            requestType: requestType,
          })
        );
        // code block
        break;
    }

    setSnackbar({
      children: "Request successfully removed",
      severity: "success",
    });
  };

  return (
    <div>
      <IconButton
        sx={{ p: 0 }}
        aria-label="delete"
        size="large"
        onClick={handleClickOpen}
      >
        <DeleteIcon fontSize="inherit" color="error" />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You Sure ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Once agree you won't be able to get this request data again !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              handleClose();
              handelDeleteRequest();
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
