import { Alert, Button, ButtonBase, Container } from "@mui/material";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../allTasks/allTasks.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { getMonths } from "../../store/months/monthsSlice";
import BasicModal from "../../components/editForm/editScore";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {
  getTotalTasks,
  iterator,
  sumOfHours,
  sumOfHoursWithFunActivity,
} from "./logic";

const TeamMemberAllMonths = ({ setCurrentPage }) => {
  const { user } = useSelector((state) => state.employees);
  const location = useLocation();

  const { employee, yearId, yearName , depart} = user && location.state;
  const { allMonths, isLoading } = useSelector((state) => state.months);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user) {
      dispatch(getMonths({ id: employee._id, year: yearId }));
    }
    setCurrentPage(location.pathname);
  }, [navigate]);

  const categories =
    employee?.department?.name === "BIM"
      ? [
          "Project_Admin",
          "Technical",
          "Office_Admin",
          "Training",
          "Break",
          "No_Work_Assigned",
        ]
      : [
          "Project_Admin",
          "Technical",
          "Assisting",
          "Office_Admin",
          "Internal_Meetings",
          "Training",
          "Break",
          "No_Work_Assigned",
        ];

  return (
    <Box sx={{ border: "1px solid #E9E9EF", zoom: "90%" }}>
      <Box className={styles.reportContainer}>
        <>
          {allMonths.length === 0 && !isLoading && (
            <Box className={styles.alertContainerStyle} height="400px">
              <Alert
                variant="filled"
                severity="info"
                className={styles.alertStyle}
              >
                You don't have any months in {yearName}
              </Alert>
            </Box>
          )}
          <ButtonBase
            onClick={() => navigate(-1)}
            type="submit"
            className={styles.backButton}
          >
            <i className={`fa-solid fa-angle-left ${styles.addIconStyle}`}></i>
            back
          </ButtonBase>
          <p
            style={{
              marginTop: "2%",
              fontFamily: "tahoma",
              color: "#495057",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            Months Of {yearName} For {employee?.username}
          </p>
          <Box></Box>

          {isLoading ? (
            <div className={styles.progressContainer}>
              <CircularProgress className={styles.progress} />
            </div>
          ) : (
            allMonths.length !== 0 && (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "auto",
                }}
                className={styles.tableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead className={styles.tableHeadStyle}>
                    <TableRow>
                      <TableCell
                        variant="head"
                        size="small"
                        className={styles.headerCell}
                        align="left"
                      >
                        date
                      </TableCell>
                      {categories.map((item, index) => {
                        return (
                          <TableCell
                            size="small"
                            key={index}
                            className={styles.headerCell}
                            align="left"
                          >
                            {item}
                          </TableCell>
                        );
                      })}
                      <TableCell
                        size="small"
                        className={styles.headerCell}
                        align="left"
                      >
                        Fun Activities
                      </TableCell>
                      <TableCell
                        size="small"
                        className={styles.headerCell}
                        align="left"
                      >
                        Total tasks
                      </TableCell>
                      <TableCell
                        size="small"
                        className={styles.headerCell}
                        align="left"
                      >
                        Grand Total
                      </TableCell>

                      <TableCell
                        size="small"
                        className={styles.headerCell}
                        align="left"
                      >
                        monthly Score (%)
                      </TableCell>
                      <TableCell size="small" align="left"></TableCell>
                      <TableCell size="small" align="left"></TableCell>
                      <TableCell size="small" align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allMonths &&
                      allMonths.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            size="small"
                            className={styles.dataCell}
                            align="left"
                          >
                            {row.name} - {row.year.name}
                          </TableCell>

                          {categories.map((item, index) => {
                            return (
                              <TableCell
                                size="small"
                                key={index}
                                className={styles.dataCell}
                                align="left"
                              >
                                {iterator(row.summaries, item)}
                              </TableCell>
                            );
                          })}
                          {/* Fun_Activities */}
                          <TableCell
                            size="small"
                            className={styles.dataCell}
                            align="left"
                          >
                            {row.Fun_Activities}
                          </TableCell>
                          {/* total tasks */}
                          <TableCell
                            size="small"
                            className={styles.dataCell}
                            align="left"
                          >
                            {getTotalTasks(row.summaries)}
                          </TableCell>
                          {/* grand total */}
                          <TableCell
                            size="small"
                            className={styles.dataCell}
                            align="left"
                          >
                            {sumOfHoursWithFunActivity(
                              row.summaries,
                              row.Fun_Activities
                            )}
                          </TableCell>
                          <TableCell
                            size="small"
                            className={styles.dataCell}
                            align="left"
                          >
                            {row.monthlyscore}

                            <BasicModal row={row} />
                          </TableCell>

                          <TableCell className={styles.dataCell} align="right">
                            {true && (
                              <EventAvailableIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCurrentPage(
                                    "/reports/myTeam/months/FinalmonthlyReport"
                                  );
                                  navigate(
                                    `/reports/myTeam/months/FinalmonthlyReport`,
                                    {
                                      state: {
                                        row: row,
                                        employee: employee,
                                      },
                                    }
                                  );
                                }}
                              >
                                edit
                              </EventAvailableIcon>
                            )}
                          </TableCell>

                          <TableCell className={styles.dataCell} align="right">
                            {true && (
                              <Button
                                variant="outlined"
                                color="secondary"
                                className={styles.editMonthlyButton}
                                onClick={() => {
                                  setCurrentPage(
                                    "/reports/myTeam/months/monthlyReport"
                                  );
                                  navigate(
                                    `/reports/myTeam/months/monthlyReport`,
                                    {
                                      state: {
                                        row: row,
                                        employee: employee,
                                      },
                                    }
                                  );
                                }}
                              >
                                Report
                              </Button>
                            )}
                          </TableCell>

                          <TableCell className={styles.dataCell} align="right">
                            <Button
                              onClick={() => {
                                setCurrentPage(
                                  "/reports/myTeam/months/days"
                                );
                                navigate(`/reports/myTeam/months/days`, {
                                  state: {
                                    title: row.alphaName,
                                    monthId: row._id,
                                    employee: employee,
                                    yearId: yearId,
                                    depart:depart
                                  },
                                });
                              }}
                              className={styles.reportButton}
                              variant="outlined"
                              color="primary"
                            >
                              {`Days`}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </>
      </Box>
    </Box>
  );
};
export default TeamMemberAllMonths;
