import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "../myTeam_marketing/myTeam.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { hr } from "../../roles";
import CreateSummary from "../../components/addForms/addSummary";
import CreateSummaryMarketingAdmin from "../../components/addForms/addSummaryAdmin";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SummaryCenteredTabsAdmin({ setCurrentPage }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.employees);
  const hrRole = hr(user);

  React.useEffect(() => {
    setCurrentPage(location.pathname);
  }, [navigate]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Tabs value={value} onChange={handleChange} centered>
          <Tab className={styles.panelStyles} label="BD" {...a11yProps(0)} />
          <Tab
            className={styles.panelStyles}
            label="Marketing"
            {...a11yProps(1)}
          />
          <Tab className={styles.panelStyles} label="HR" {...a11yProps(2)} />
          <Tab className={styles.panelStyles} label="BIM" {...a11yProps(3)} />
          <Tab
            className={styles.panelStyles}
            label="Planning"
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>

      <React.Fragment>
        <TabPanel value={value} index={0}>
          <CreateSummaryMarketingAdmin depart={"BD"} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateSummaryMarketingAdmin depart={"Marketing"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateSummaryMarketingAdmin depart={"HR"} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CreateSummary depart={"BIM"} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CreateSummary depart={"Planning"} />
        </TabPanel>
      </React.Fragment>
    </Box>
  );
}
