import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getYears } from "../../store/yearsSlice/yearsSlice";
import { Chart, registerables } from "chart.js";
import { CircularProgress } from "@mui/material";
import styles from "./charts.module.css";

const ProfileChart = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allYears, isLoading } = useSelector((state) => state.years);
  let arr = [];
  let scorearr = []; 

  allYears.forEach((element) => {
    arr.push(element.name);    
    scorearr.push(element.firstHalf)                     
    scorearr.push(element.score)
  });

  let yearsArray = [0, ...arr];
  let scoreArray =[0 , ...scorearr]

  let schema = yearsArray.join(',jun,') 
  yearsArray = schema.split(',')
  
  React.useEffect(() => {
    if (user) {
      dispatch(getYears(user._id));
    }
  }, [navigate]);
  Chart.register(...registerables);


console.log(scoreArray);

  let state = {
    labels: yearsArray,
    datasets: [
      {
        label: "Rainfall",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: scoreArray,
      },
    ],
  };
  return (
    <>
      {isLoading ? (
        <div className={styles.progressContainer}>
          <CircularProgress className={styles.progress} />
        </div>
      ) : (
        <Line
          data={state}
          options={{
            title: {
              display: true,
              text: "Average Rainfall per month",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: "right",
            },
          }}
        />
      )}
    </>
  );
};
export default ProfileChart;
