export const calculateSpentTime = (allTasks,  Fun_Activities) => {
  let totalHours = +Fun_Activities?.split(":")[0] || 0;
  let totalMinutes = +Fun_Activities?.split(":")[1] || 0;

  allTasks.forEach((val) => {
    totalHours += +val.grandTotal.split(":")[0];
    totalMinutes += +val.grandTotal.split(":")[1];
  });
  // totalHours += offTime;
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;
    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

const getAllDaysInMonth = (month, year) =>
  Array.from(
    { length: new Date(year, month, 0).getDate() }, // get next month, zeroth's (previous) day
    (_, i) => new Date(year, month - 1, i + 1) // get current month (0 based index)
  );

export const getLenghtOfMonth = (monthName, yearName) => {
  const allDaysBeforeMap = getAllDaysInMonth(monthName, yearName);
  const allDaysAfterMap = allDaysBeforeMap.map((x) =>
    x.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      weekday: "short",
    })
  );

  const allDays = allDaysAfterMap.filter((item) => {
    return item.slice(0, 3) !== "Sat" && item.slice(0, 3) !== "Sun";
  });

  return allDays.length;
};

export const sumOfHours = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours += +val.grandTotal.split(":")[0];
    totalMinutes += +val.grandTotal.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;

    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const calculateMonthlyHoursOff = (
  totalTimeSpent,
  shouldTotalTimeSpent
) => {
  return changeTimeSpend(totalTimeSpent, shouldTotalTimeSpent + ":00");
};

export const sumOfHoursWithFunActivity = (allTasks, fun) => {
  let totalHours = +fun?.split(":")[0] || 0;
  let totalMinutes = +fun?.split(":")[1] || 0;

  allTasks.forEach((val) => {
    totalHours += +val.grandTotal.split(":")[0];
    totalMinutes += +val.grandTotal.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;

    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const sumOfHoursProductiv = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours +=
      +val.Break?.split(":")[0] + +val.No_Work_Assigned?.split(":")[0];
    totalMinutes +=
      +val.Break?.split(":")[1] + +val.No_Work_Assigned?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;

    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const sumOfHoursProductivWithoutNWA = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours += +val.Break?.split(":")[0];
    totalMinutes += +val.Break?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;

    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const sumOfHoursMom = (allTasks) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours +=
      +val.Project_Admin?.split(":")[0] +
      +val.Technical?.split(":")[0] +
      +val.Assisting?.split(":")[0] +
      +val.Office_Admin?.split(":")[0];
    totalMinutes +=
      +val.Project_Admin?.split(":")[1] +
      +val.Technical?.split(":")[1] +
      +val.Assisting?.split(":")[1] +
      +val.Office_Admin?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;

    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const iterator = (allTasks, categorypar) => {
  let totalHours = 0;
  let totalMinutes = 0;
  allTasks.forEach((val) => {
    totalHours += +val[categorypar]?.split(":")[0];
    totalMinutes += +val[categorypar]?.split(":")[1];
  });
  if (totalMinutes === 60) {
    totalHours += 1;
    return totalHours + ":00";
  } else if (totalMinutes < 60) {
    if (totalMinutes <= 9) totalMinutes = "0" + totalMinutes;
    if (totalHours <= 9) totalHours = "0" + totalHours;
    return totalHours + ":" + totalMinutes;
  } else {
    return convertMinutesToHours(totalHours, totalMinutes);
  }
};

export const getTotalTasks = (arr) => {
  return arr.reduce((prev, cur) => {
    return prev + +cur.totalTasks;
  }, 0);
};

const timeConvert = (totalHours, totalMinutes) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours) + totalHours;
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ":" + rminutes;
};

// export const changeTimeSpend = (from, to) => {
//   let totalHours;

//   let startHours = +from.split(":")[0];
//   let endHours = +to.split(":")[0];

//   totalHours = Math.abs(endHours - startHours);
//   const startMinutes = +from.split(":")[1];
//   const endMinutes = +to.split(":")[1];
//   let totalMinutes = Math.abs(endMinutes - startMinutes);

//   let totalTime;
//   if (totalMinutes < 60) {
//     if (totalMinutes <= 9) {
//       totalMinutes = "0" + totalMinutes;
//     }
//     totalTime = totalHours + ":" + totalMinutes;

//     return totalTime;
//   } else if (totalMinutes === 60) {
//     totalHours += 1;
//     totalTime = totalHours + ":" + "00";
//     return totalTime;
//   } else {
//     totalTime = timeConvert(totalHours, totalMinutes);
//     return totalTime;
//   }
// };

export const convertMinutesToHours = (totalHours, totalMinutes) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours) + totalHours;
  var minutes = (hours - Math.floor(hours)) * 60;
  var rminutes = Math.round(minutes);
  if (rminutes <= 9) rminutes = "0" + rminutes;
  if (rhours <= 9) rhours = "0" + rhours;

  return rhours + ":" + rminutes;
};

export const productiveTimePersentatge = (time) => {
  let totalHours = +time.split(":")[0];
  let totalMinutes = +time.split(":")[1];

  return totalHours + totalMinutes / 60;
};

export const changeTimeSpend = (from, to) => {
  let totalHours;

  let startHours = +from.split(":")[0];
  let endHours = +to.split(":")[0];

  totalHours = Math.abs(endHours - startHours);

  const startMinutes = +from.split(":")[1];
  const endMinutes = +to.split(":")[1];

  let totalMinutes = endMinutes - startMinutes;

  if (totalHours > 0 && totalMinutes < 0) {
    totalHours = totalHours - 1;
    totalMinutes = 60 + totalMinutes;
  }

  totalMinutes = Math.abs(totalMinutes);

  let totalTime;
  if (totalMinutes < 60) {
    if (totalMinutes <= 9) {
      totalMinutes = "0" + totalMinutes;
    }
    totalTime = totalHours + ":" + totalMinutes;
    return totalTime;
  } else if (totalMinutes === 60) {
    totalHours += 1;
    totalTime = totalHours + ":" + "00";
    return totalTime;
  } else {
    totalTime = timeConvert(totalHours, totalMinutes);
    return totalTime;
  }
};
