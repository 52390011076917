import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import styles from "./summary.module.css";


const SummaryTableMarketing = ({ allTasks }) => {



  const iterator = (status) => {
    if (status === "---") {
      return allTasks.filter((val) => {
        return val.status !== status;
      }).length;
    } else
      return allTasks.filter((val) => {
        return val.status === status;
      }).length;
  };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table className={styles.tableStyle} aria-label="simple table">
        <TableHead className={styles.tableHeadStyle}>
          <TableRow>
            <TableCell>Summary</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              Total Tasks
            </TableCell>
            <TableCell align="right">{iterator("---")}</TableCell>
          </TableRow>

          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 1 },
            }}
          >
            <TableCell component="th" scope="row">
              Completed
            </TableCell>
            <TableCell align="right">{iterator("Completed")}</TableCell>
          </TableRow>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 1 },
            }}
          >
            <TableCell component="th" scope="row">
              postponed
            </TableCell>
            <TableCell align="right">{iterator("postponed")}</TableCell>
          </TableRow>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 1 },
            }}
          >
            <TableCell component="th" scope="row">
              In Progress
            </TableCell>
            <TableCell align="right">{iterator("In Progress")}</TableCell>
          </TableRow>

          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              Not Started
            </TableCell>
            <TableCell align="right">{iterator("Not Started")}</TableCell>
          </TableRow>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              Total % complete
            </TableCell>
            <TableCell align="right">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    backgroundColor: "#FF8042",
                    height: "1rem",
                    width: "1rem",
                  }}
                ></div>
                <div>
                  {iterator("---") !== 0
                    ? parseFloat(
                      (iterator("Completed") / iterator("---")) * 100
                    ).toFixed(2)
                    : 0}
                  %
                </div>
              </div>
            </TableCell>
          </TableRow>
          
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SummaryTableMarketing;
