import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

//getDays
export const getDays = createAsyncThunk(
  "days/getDays",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { id, year, month } = data;

    try {
      const res = await axios.get(`${BASE_API}/days/${id}`, {
        params: { year: year, month: month },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editDayHoursOff
export const editDayHoursOff = createAsyncThunk(
  "days/editDayHoursOff",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    console.log(data);
    const { id, hours } = data;

    try {
      const res = await axios.put(`${BASE_API}/days/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allDays: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const daysSlice = createSlice({
  name: "days",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDays.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getDays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allDays = action.payload;
      })
      .addCase(getDays.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.allDays = null;
      })
      .addCase(editDayHoursOff.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(editDayHoursOff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        const editedDay = state.allDays.find(
          (item) => item._id === action.payload._id
        );
        if (editedDay) {
          editedDay.hoursOff = action.payload.hoursOff;
        }
      })
      .addCase(editDayHoursOff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.allDays = null;
      });
  },
});

export const { reset, setStateCurrentPage } = daysSlice.actions;
export default daysSlice.reducer;
