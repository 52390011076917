import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  rowData,
  handelApprove,
  handelReject,
  approveButtonContent,
  rejectedButtonContent,
  bodyMessage,
  headerMessage,
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    // console.log(rowData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button size="small" variant="contained" onClick={handleClickOpen}>
        action
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{headerMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {bodyMessage}
            <strong>{rowData.row.senderName} </strong>
            <br />
            <br />
            From: {rowData.row.startDate.split("T")[0]} To:{" "}
            {rowData.row.endDate.split("T")[0]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleClose();
              handelReject(rowData);
            }}
          >
            {rejectedButtonContent}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handelApprove(rowData);
            }}
          >
            {approveButtonContent}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
