import { useLocation } from "react-router-dom";
import TeamMemberAllMonthsMarketing from "../myTeam_marketing/teamMemberMonths";
import TeamMemberAllMonths from "../myTeam_planning/teamMemberMonths";

const Admin_Team_Months_Table = ({ setCurrentPage }) => {
  const location = useLocation();
  let { depart } = location.state;
  return depart === "Planning" || depart === "BIM" ? (
    <TeamMemberAllMonths setCurrentPage={setCurrentPage} />
  ) : (
    <TeamMemberAllMonthsMarketing setCurrentPage={setCurrentPage} />
  );
};
export default Admin_Team_Months_Table;
