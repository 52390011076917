import React from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CreateTasks.module.css";
import { Store } from "react-notifications-component";
import { createRequest } from "../../store/requests/requestsSlice";
import { createNotification } from "../../store/notifications/notificationsSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { employee } from "../../roles";

const CreateVacation = ({ socket, formType, setValue }) => {
  const { user } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const employeeRole = employee(user);

  const controlRequestTabs = () => {
    switch (formType) {
      case "vacation":
        setValue(employeeRole ? 0 : 5);
        break;
      case "WFH":
        setValue(employeeRole ? 1 : 6);
      case "FLXH":
        setValue(employeeRole ? 2 : 7);
        break;
    }
  };

  const handleNotification = (values) => {
    let receiversArray = [];

    receiversArray.push({
      receiverId: values?.receiverId,
      message: `send you new (${formType}) request`,
    });

    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "request",
      })
    );
  };

  const validationSchema = Yup.object({
    department: Yup.string().required("Please Enter your department"),
    username: Yup.string().required("please Enter username"),
    teamLeader: Yup.string().required("please Enter teamLeader"),
    teamLeaderEmail: Yup.string().required("please Enter teamLeader Email"),
    startDate: Yup.string().required("Please enter start date "),
    endDate: Yup.string().required("Please enter end date "),
  });

  const formik = useFormik({
    initialValues: {
      department: user?.department?.name,
      username: user?.username,
      teamLeader: user?.leaderName,
      teamLeaderEmail: user?.leaderEmail,
      receiverId: user?.teamLeader,
      status: "New",
      sender: user?._id,
      senderEmail: user?.email,
      startDate: "",
      endDate: "",
      notes: "",
      requestType: formType,
      requestTime: "Full day",
    },

    onSubmit: (values, { resetForm }) => {
      controlRequestTabs();
      dispatch(createRequest(values))
        .unwrap()
        .then(() => {
          Store.addNotification({
            title: "Wonderful!",
            message: "New Request is added successfully",
            type: "default",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
            },
          });

          handleNotification(values);

        });
    },
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* ---------------------------------- Request Time ------------------------------- */}
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          {...formik.getFieldProps("requestTime")}
        >
          <FormControlLabel
            value="Full day"
            control={<Radio />}
            label="Full day"
          />
          <FormControlLabel
            value="Half day"
            control={<Radio />}
            label="Half day"
          />
        </RadioGroup>
      </FormControl>
      <Box marginY={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              id="outlined-basic-email"
              label="User Name"
              variant="outlined"
              type="text"
              placeholder="User Name"
              name="username"
              inputProps={{ readOnly: true }}
              {...formik.getFieldProps("username")}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className={styles.errorStyle}>{formik.errors.username}</div>
            ) : null}
          </Grid>

          <Grid item xs={12} md={6}>
            {/* ------------endDate input-------------- */}

            <TextField
              fullWidth
              size="small"
              id="outlined-basic-email"
              label="Department"
              variant="outlined"
              type="text"
              placeholder="department"
              name="department"
              inputProps={{ readOnly: true }}
              {...formik.getFieldProps("department")}
            />
            {formik.touched.department && formik.errors.department ? (
              <div className={styles.errorStyle}>
                {formik.errors.department}
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {/* ------------ comments -------------- */}
      <Box marginY={4}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="TeamLeader"
          variant="outlined"
          type="text"
          placeholder="teamLeader"
          name="teamLeader"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("teamLeader")}
        />
        {formik.touched.teamLeader && formik.errors.teamLeader ? (
          <div className={styles.errorStyle}>{formik.errors.teamLeader}</div>
        ) : null}
      </Box>

      <Box marginY={4}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="TeamLeader Email"
          variant="outlined"
          type="text"
          placeholder="TeamLeader Email"
          name="teamLeaderEmail"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("teamLeaderEmail")}
        />
        {formik.touched.teamLeaderEmail && formik.errors.teamLeaderEmail ? (
          <div className={styles.errorStyle}>
            {formik.errors.teamLeaderEmail}
          </div>
        ) : null}
      </Box>

      <Box marginY={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              id="outlined-basic-email"
              fullWidth
              label="From"
              type="date"
              name="startDate"
              InputLabelProps={{
                shrink: true,
              }}
              {...formik.getFieldProps("startDate")}
            />
            {formik.touched.startDate && formik.errors.startDate ? (
              <div className={styles.errorStyle}>{formik.errors.startDate}</div>
            ) : null}
          </Grid>

          {/* ------------endDate input-------------- */}
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              id="outlined-basic-email"
              label="To"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              placeholder="Enter department Name"
              type="date"
              name="endDate"
              {...formik.getFieldProps("endDate")}
            />
            {formik.touched.endDate && formik.errors.endDate ? (
              <div className={styles.errorStyle}>{formik.errors.endDate}</div>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {/* ---------------------------------- Request Notes ------------------------------- */}

      <Box>
        <textarea
          style={{ width: "100%" }}
          size="small"
          id="outlined-basic-email"
          placeholder="Notes"
          variant="outlined"
          type="text"
          name="notes"
          {...formik.getFieldProps("notes")}
        />
      </Box>

      {/* ------------Save button -------------- */}
      <Button
        sx={{
          marginX: "2%",
          marginY: "4%",
          width: "80%",
          backgroundColor: "#12633F",
        }}
        variant="contained"
        type="submit"
      >
        Send Request
      </Button>
      {/* ------------back button -------------- */}
    </form>
  );
};
export default CreateVacation;
