import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createDepartment
export const getMonthEmptyDays = createAsyncThunk(
  "monthlyReport/getMonthEmptyDays",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/monthlyReport/${id}`)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


//editEmptyDay
export const editEmptyDay = createAsyncThunk(
  "monthlyReport/editEmptyDay",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    
    const {id} = data
   
    try {
      const res = await axios.post(`${BASE_API}/monthlyReport/${id}`, data)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
    allDays: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  };
  
  export const monthlyReportSlice = createSlice({
    name: "monthlyReport",
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      },
    },
  
    extraReducers: {
      //getMonthEmptyDays
      [getMonthEmptyDays.pending]: (state, action) => {
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [getMonthEmptyDays.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allDays = action.payload;
      },
      [getMonthEmptyDays.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.allDays = [];
      },

      [editEmptyDay.pending]: (state, action) => {
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [editEmptyDay.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allDays = action.payload;
      },
      [editEmptyDay.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.allDays = [];
      },
  
    },
  });
  
  export const { reset } = monthlyReportSlice.actions;
  export default monthlyReportSlice.reducer;