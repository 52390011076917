import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 


  export const getGlopalMessages = createAsyncThunk(
    "glopalMessage/getGlopalMessages",
    async (id , thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const res = await axios.get(`${BASE_API}/glopalchat`);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  
  //createDepartment
export const createGlopalMessages = createAsyncThunk(
    "glopalMessage/createGlopalMessages",
    async (userData, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      //console.log(userData);
      try {
        const res = await axios.post(`${BASE_API}/glopalchat`, userData);
        //console.log(res);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  
  
  //updateDepartment
  export const updateGlopalMessages = createAsyncThunk(
    "glopalMessage/updateGlopalMessages",
    async (userData, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      
      const {id,name} = userData.values
      //console.log(id);
      try {
        const res = await axios.put(`${BASE_API}/glopalchat/${id}`, userData.values);
        //console.log(res);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  
  
  const initialState = {
    // user: user ? user : null,
    glopalMessages: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",

  };
  
  export const glopalChatSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      },
      
    },
  
    extraReducers: {
      //createGlopalMessages
      [createGlopalMessages.pending]: (state, action) => {
        // console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [createGlopalMessages.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.glopalMessages = action.payload;
      },
      [createGlopalMessages.rejected]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      },
  
      // updateGlopalMessages
      [updateGlopalMessages.pending]: (state, action) => {
        // console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [updateGlopalMessages.fulfilled]: (state, action) => {
        // console.log(action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.glopalMessages = action.payload;
      },
      [updateGlopalMessages.rejected]: (state, action) => {
        // console.log(action.payload);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      },
  
  
      [getGlopalMessages.pending]: (state, action) => {
        // console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [getGlopalMessages.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.glopalMessages = action.payload;
      },
      [getGlopalMessages.rejected]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      },
    },
  });
  
  export const { reset } = glopalChatSlice.actions;
  export default glopalChatSlice.reducer;