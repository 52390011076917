import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Divider from "@mui/material/Divider";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "./profile.module.css";
import ImageAvatar from "../avatar/avatarComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../store/employees/employeesSlice";
const StyledMenu = styled((props) => (
  <Menu
    className={styles.menueStyle}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0.8),
    minWidth: 180,
    [theme.breakpoints.down("sm")]: {
      minWidth: "91%",
      marginTop: "3%",
    },
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus({ username, socket, setCurrentPage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutButton = () => {
    socket.disconnect();
    navigate("/login");
    dispatch(logout());
  };
  const profile = () => {
    handleClose();
    navigate("/profile");
    setCurrentPage("/profile");
  };

  const { user } = useSelector((state) => state.employees);
  return (
    <div className={styles.profileContainer}>
      <Button
        className={styles.profileButton}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant=""
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <span className={styles.userName}>{user && user.username}</span>
        <span className={styles.user_avatar}>
          <ImageAvatar src={user && user.image} />
        </span>
      </Button>
      <StyledMenu
        className={styles.menue}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={profile} disableRipple>
          <PersonOutlineOutlinedIcon />
          Profile
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <SettingsOutlinedIcon />
          Settings
        </MenuItem>
        <Divider sx={{ my: 0.5, backgroundColor: "#E9E9EF" }} />
        <MenuItem onClick={logoutButton} disableRipple>
          <PowerSettingsNewOutlinedIcon sx={{ color: "#FD6481 !important" }} />
          Logout
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
