import { Box, Container, Grid } from "@mui/material";
import ProfilePageAvatar from "../../components/avatar/profilePageAvatar";
import styles from "./profile.module.css";
import BasicTabs from "../../components/profileTabs/profileTabs";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { useSelector } from "react-redux";
import ProfileChart from "../../components/charts/profileChart";

const Profile = () => {
  const { user } = useSelector((state) => state.employees);

  const skills = [
    "React.js",
    "Node.js",
    "OOP",
    "Problem Solving",
    "css",
    "HTML",
    "MongoDB",
  ];
  const skillsDiv = skills.map((row, index) => {
    return (
      <p key={index} className={styles.skillsTextStyle}>
        {row}
      </p>
    );
  });
  return (
    <Box>
      <Grid container spacing={2}>
        {/*---------------------------------------- left Components ---------------------------------------- */}

        <Grid item xs={12} md={8} >
          <div className={styles.leftDiv}>
            <div className={styles.firstBlock}>
              <ProfilePageAvatar src={user && user.image} />
              <div>
                <span className={styles.userName}>{user && user.fullName}</span>
                <br />
                <span className={styles.userJobTitle}>
                  {user && user.position}
                </span>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i className={`fa-solid fa-circle ${styles.dotIcon}`}></i>
                  <span className={styles.userDepartment}>
                    {user && user.department.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.leftDivTabs}>
            <BasicTabs />
          </div>
        </Grid>

        {/*---------------------------------------- right Components ---------------------------------------- */}

        <Grid item xs={12} md={4}>
          <div className={styles.rightDiv}>
            <p>Skills</p>
            {skillsDiv}
          </div>

          <div className={styles.rightDivProtfolio}>
            <p>Portfolio</p>
            <LanguageOutlinedIcon className={styles.icon} />{" "}
            <a className={styles.anchorTag} href="https://www.google.com">
              website
            </a>
            <br />
            <LinkOutlinedIcon className={styles.icon} />{" "}
            <a className={styles.anchorTag} href="https://www.google.com">
              Linkedin
            </a>
          </div>

          <div className={styles.rightDivProtfolio}>
            <p>score</p>
            <ProfileChart user={user} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Profile;
