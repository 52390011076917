import * as React from "react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CreateTasks.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import {
  addServices,
  createProject,
  removeServices,
  reset,
} from "../../store/projects/projectsSlice";
import { Store } from "react-notifications-component";
import { createNotification } from "../../store/notifications/notificationsSlice";
import {
  getEmployees,
  getEmployeesByDepartmentName,
} from "../../store/employees/employeesSlice";
import { adminOnly } from "../../roles";
const CreateProjectAdmin = ({ socketContext }) => {
  const [socket, setSocket] = React.useContext(socketContext);
  const { user, bdEmployees } = useSelector((state) => state.employees);
  const { isLoading, isSuccess } = useSelector((state) => state.projects);
  const { allServices } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminOnlyRole = adminOnly(user);

  const servicesData = ["Scheduling", "Delay Claim", "BIM", "Moni"];
  const scheduling_arr = [
    "Primarily-Pre-bid",
    "Baseline",
    "Monthly Update",
    "Conversion",
    "Recovery",
    "Cost Loaded",
    "Resource Loaded",
  ];
  const delay_arr = ["TIA", "Window"];
  const bim_arr = ["Modeling", "Coordination", "Shop Drawing"];
  const moni_arr = ["Quality", "Safety", "Other", "Covid 19", "SW PPP"];

  const handelServices = (option) => {
    switch (option) {
      case "Delay Claim":
        return delay_arr;
      case "Scheduling":
        return scheduling_arr;
      case "BIM":
        return bim_arr;
      case "Moni":
        return moni_arr;
    }
  };

  // containers for selected services
  const [schedulingArr, setSchadualingArr] = React.useState([]);
  const [delayArr, setDelayArr] = React.useState([]);
  const [bimArr, setBimArr] = React.useState([]);
  const [moniArr, setMoniArr] = React.useState([]);

  // handel push to services arrays
  const handelPush = (option, val) => {
    switch (option) {
      case "Scheduling":
        setSchadualingArr([...schedulingArr, val]);
        break;
      case "Delay Claim":
        setDelayArr([...delayArr, val]);
        break;
      case "BIM":
        setBimArr([...bimArr, val]);
        break;
      case "Moni":
        setMoniArr([...moniArr, val]);
        break;
    }
  };
  // handel pull from services arrays
  const handelPull = (option, val) => {
    switch (option) {
      case "Scheduling":
        setSchadualingArr((items) => items.filter((x) => x !== val));
      case "Delay Claim":
        setDelayArr((items) => items.filter((x) => x !== val));
      case "BIM":
        setBimArr((items) => items.filter((x) => x !== val));
      case "Moni":
        setMoniArr((items) => items.filter((x) => x !== val));
    }
  };

  const projectTypeData = [
    "Transit",
    "Sport",
    "Federal",
    "Airport",
    "State & Country",
    "Residential",
    "Hotel",
    "Educational",
    "Commercial",
    "HealthCare",
  ];

  React.useEffect(() => {
    dispatch(getEmployeesByDepartmentName("BD"));
  }, [navigate]);

  const handleNotification = (values) => {
    let receiversArray = [];

    if (user._id !== values.bdEmployee) {
      receiversArray.push({
        receiverId: values.bdEmployee,
        message: `Assign You New Project (${values.projectName})`,
      });
    }

    if (user._id !== values.assistantBd) {
      if (values.assistantBd) {
        receiversArray.push({
          receiverId: values.assistantBd,
          message: `Assign You New Project (${values.projectName})`,
        });
      }
    }

    receiversArray.push({
      receiverId: "ESRAA",
      message: `add new project with name (${values.projectName})`,
    });
    receiversArray.push({
      receiverId: "KAL",
      message: `add new project with name (${values.projectName})`,
    });
    receiversArray.push({
      receiverId: "YOUSSEF",
      message: `add new project with name (${values.projectName})`,
    });

    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "Project",
      })
    );
  };

  const validationSchema = Yup.object({
    projectType: Yup.string().required("Please Enter project type"),
    projectName: Yup.string().required("Please Enter project name"),
    bdEmployee: Yup.string().required("Please Select BD Employee"),
    value: Yup.string().required("Please Enter project value"),
    dateOfSign: Yup.string().required("Please Enter project sign date"),
    state: Yup.string().required("Please Enter company state"),
    company: Yup.string().required("Please Enter company name"),
    address: Yup.string().required("Please Enter company address"),
    number: Yup.string().required("Please Enter company number"),
    authority: Yup.string().required("Please Enter company authority"),
  });

  const formik = useFormik({
    initialValues: {
      bdEmployee: "",
      assistantBd: null,
      projectType: "",
      projectName: "",
      projectNote: "",
      dateOfSign: "",
      contractNumber: "",
      value: "",
      estHours: 0,
      state: "",
      company: "",
      address: "",
      number: "",
      authority: "",
      contactPerson1: "",
      contactNumber1: "",
      contactMobile1: "",
      contactEmail1: "",
      authority: "",
      contactPerson2: "",
      contactNumber2: "",
      contactMobile2: "",
      contactEmail2: "",
      status: "New",
      services: [],
    },

    onSubmit: (values) => {
      if (
        schedulingArr.length !== 0 ||
        delayArr.length !== 0 ||
        bimArr.length !== 0 ||
        moniArr.length !== 0
      ) {
        values.services = [];

        if (schedulingArr.length !== 0) {
          values.services.push({ name: "Scheduling", arr: schedulingArr });
          values.projectDepart = "Planning";
        }
        if (delayArr.length !== 0) {
          values.services.push({ name: "Delay Claim", arr: delayArr });
          values.projectDepart = "Planning";
        }
        if (bimArr.length !== 0) {
          values.services.push({ name: "BIM", arr: bimArr });
          values.projectDepart = "BIM";
        }
        if (moniArr.length !== 0) {
          values.services.push({ name: "Moni", arr: moniArr });
          values.projectDepart = "Moni";
        }

        dispatch(createProject(values))
          .unwrap()
          .then(() => {
            if (isSuccess) {
              Store.addNotification({
                title: "Wonderful!",
                message: "New Project is added successfully",
                type: "default",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                },
              });

              handleNotification(values);
              dispatch(reset());
              navigate(-1);
            }
          });
      }
    },
    validationSchema,
  });

  return (
    <Box className={styles.formContainer}>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          {/* --------------------------------------- Project --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Project
          </label>
          {/* ------------ hr container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="bdEmployee"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  BD employee{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="bdEmployee"
                  {...formik.getFieldProps("bdEmployee")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {bdEmployees.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.bdEmployee && formik.errors.bdEmployee ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.bdEmployee}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="assistantBd"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Assistant BD{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="assistantBd"
                  {...formik.getFieldProps("assistantBd")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {bdEmployees.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="dateOfSign"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Sign Date
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  type="date"
                  placeholder="Enter employee employment date"
                  name="dateOfSign"
                  {...formik.getFieldProps("dateOfSign")}
                />

                {formik.touched.dateOfSign && formik.errors.dateOfSign ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.dateOfSign}
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="value"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  value{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project value"
                  type="text"
                  name="value"
                  {...formik.getFieldProps("value")}
                />
                {formik.touched.value && formik.errors.value ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.value}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="estHours"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  EST Hours{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project est hours"
                  type="number"
                  name="estHours"
                  {...formik.getFieldProps("estHours")}
                />
                {formik.touched.estHours && formik.errors.estHours ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.estHours}
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectNote"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Notes{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project Notes"
                  type="text"
                  name="projectNote"
                  {...formik.getFieldProps("projectNote")}
                />
                {formik.touched.projectNote && formik.errors.projectNote ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectNote}
                  </div>
                ) : null}
              </Grid>
              {/* ---------------- project --------------------- */}
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectName"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  project name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project name"
                  type="text"
                  name="projectName"
                  {...formik.getFieldProps("projectName")}
                />
                {formik.touched.projectName && formik.errors.projectName ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectName}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectType"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  project type{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="projectType"
                  {...formik.getFieldProps("projectType")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {projectTypeData.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.projectType && formik.errors.projectType ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectType}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="contractNumber"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  contract number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contract number"
                  type="text"
                  name="contractNumber"
                  {...formik.getFieldProps("contractNumber")}
                />
                {formik.touched.contractNumber &&
                formik.errors.contractNumber ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.contractNumber}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <label
                  htmlFor="authority"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Authority{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project authority"
                  type="text"
                  name="authority"
                  {...formik.getFieldProps("authority")}
                />
                {formik.touched.authority && formik.errors.authority ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.authority}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              {/* ------------services input-------------- */}
              <Grid item xs={12} sm={12} md={9}>
                <label
                  htmlFor="services"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  services
                </label>
                <div
                  style={{
                    border: "1px solid #E9E9EF",
                    padding: "0.5%",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {servicesData.map((item, index) => {
                      return (
                        <>
                          <div key={index}>
                            {/* <input type="checkbox" value={item} disabled checked = {true}/> */}
                            <label>{item}</label>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 2,
                              }}
                            >
                              {handelServices(item).map((val, iterator) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    key={iterator}
                                  >
                                    <input
                                      disabled={
                                        ((schedulingArr.length !== 0 ||
                                          delayArr.length !== 0) &&
                                          (item === "BIM" ||
                                            item === "Moni")) ||
                                        (moniArr.length !== 0 &&
                                          (item === "Scheduling" ||
                                            item === "Delay Claim" ||
                                            item === "BIM")) ||
                                        (bimArr.length !== 0 &&
                                          (item === "Scheduling" ||
                                            item === "Delay Claim" ||
                                            item === "Moni"))
                                      }
                                      style={{ marginRight: "5px" }}
                                      onChange={(e) => {
                                        if (
                                          user.department.name === "Planning"
                                        ) {
                                          return false;
                                        } else {
                                          // add to list
                                          if (e.target.checked) {
                                            handelPush(item, val);
                                          } else {
                                            handelPull(item, val);
                                          }
                                        }
                                      }}
                                      type="checkbox"
                                      name="services"
                                      value={val}
                                    />
                                    <label htmlFor="services">{val}</label>
                                  </div>
                                );
                              })}
                            </Box>
                          </div>
                          <Divider
                            flexItem
                            className={
                              servicesData.length === index + 1
                                ? styles.hideDivider
                                : styles.dividerStyle
                            }
                            orientation="vertical"
                          />
                        </>
                      );
                    })}
                  </Box>
                </div>

                {formik.touched.services &&
                schedulingArr.length === 0 &&
                delayArr.length === 0 &&
                bimArr.length === 0 &&
                moniArr.length === 0 ? (
                  <div className={styles.errorStyle}>
                    choose at least one service
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Company --------------------------------------------- */}

          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Company
          </label>
          {/* ------------ Company container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- Company --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="company"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company name"
                  type="text"
                  name="company"
                  {...formik.getFieldProps("company")}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.company}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="state"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  state{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company state"
                  type="text"
                  name="state"
                  {...formik.getFieldProps("state")}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.state}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="address"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  address{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company address"
                  type="text"
                  name="address"
                  {...formik.getFieldProps("address")}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.address}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="number"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company number"
                  type="text"
                  name="number"
                  {...formik.getFieldProps("number")}
                />
                {formik.touched.number && formik.errors.number ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.number}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Contact Person 1 --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Contact Person (1)
          </label>
          {/* ------------ contactPerson1 container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- contactPerson1 --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactPerson1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person name"
                  type="text"
                  name="contactPerson1"
                  {...formik.getFieldProps("contactPerson1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactNumber1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person number"
                  type="text"
                  name="contactNumber1"
                  {...formik.getFieldProps("contactNumber1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactMobile1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  mobile{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person mobile"
                  type="text"
                  name="contactMobile1"
                  {...formik.getFieldProps("contactMobile1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactEmail1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  email{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person email"
                  type="text"
                  name="contactEmail1"
                  {...formik.getFieldProps("contactEmail1")}
                />
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Contact Person 1 --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Contact Person (2)
          </label>
          {/* ------------ contactPerson2 container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- contactPerson2 --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactPerson2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person name"
                  type="text"
                  name="contactPerson2"
                  {...formik.getFieldProps("contactPerson2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactNumber2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person number"
                  type="text"
                  name="contactNumber2"
                  {...formik.getFieldProps("contactNumber2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactMobile2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  mobile{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person mobile"
                  type="text"
                  name="contactMobile2"
                  {...formik.getFieldProps("contactMobile2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactEmail2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  email{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person email"
                  type="text"
                  name="contactEmail2"
                  {...formik.getFieldProps("contactEmail2")}
                />
              </Grid>
            </Grid>
          </div>

          {/* ------------Save button -------------- */}
          <ButtonBase type="submit" className={styles.saveButton}>
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <>
                <i
                  style={{ marginRight: "10%" }}
                  className="fa-solid fa-floppy-disk"
                ></i>
                <span>Save</span>
              </>
            )}
          </ButtonBase>
          {/* ------------back button -------------- */}
          <ButtonBase
            onClick={() => {
              navigate(-1);
              dispatch(reset());
            }}
            className={styles.deleteButton}
          >
            back
          </ButtonBase>
        </form>
      </Box>
    </Box>
  );
};
export default CreateProjectAdmin;
