import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

export const getRequestEmployee = createAsyncThunk(
  "requests/getRequestEmployee",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, requestType } = data;

    try {
      const res = await axios.get(`${BASE_API}/requestemployee/${id}`, {
        params: { requestType: requestType },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); // getRequestEmployee

export const getRequestLeader = createAsyncThunk(
  "requests/getRequestLeader",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, status, requestType } = data;
    try {
      const res = await axios.get(`${BASE_API}/requestleader/${id}`, {
        params: { status: status, requestType: requestType },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //getRequestLeader

export const getRequestHr = createAsyncThunk(
  "requests/getRequestHr",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, status, requestType } = data;
    try {
      const res = await axios.get(`${BASE_API}/requesthr/${id}`, {
        params: { status: status, requestType: requestType },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //getRequestHr

export const createRequest = createAsyncThunk(
  "requests/createRequest",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.post(`${BASE_API}/requestemployee`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //createRequest

export const updateRequestHr = createAsyncThunk(
  "requests/updateRequestHr",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { id } = data;

    try {
      const res = await axios.put(`${BASE_API}/requesthr/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //updateRequestHr

export const updateRequestEmployee = createAsyncThunk(
  "requests/updateRequestEmployee",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { _id } = data;
    try {
      const res = await axios.put(`${BASE_API}/requestemployee/${_id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //updateRequestEmployee

export const updateRequestLeader = createAsyncThunk(
  "requests/updateRequestLeader",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { id } = data;

    try {
      const res = await axios.put(`${BASE_API}/requestleader/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //updateRequestLeader

export const createOtherRequest = createAsyncThunk(
  "requests/createOtherRequest",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.post(`${BASE_API}/requestOther`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //createOtherRequest

export const updateOtherRequest = createAsyncThunk(
  "requests/updateOtherRequest",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const { id } = data;
    try {
      const res = await axios.put(`${BASE_API}/requestOther/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //updateOtherRequest

export const getOtherRequestEmployee = createAsyncThunk(
  "requests/getOtherRequestEmployee",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, viewStatus } = data;
    try {
      const res = await axios.get(`${BASE_API}/requestOther/${id}`, {
        params: { viewStatus: viewStatus },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); // getOtherRequestEmployee

export const deleteOtherRequest = createAsyncThunk(
  "requests/deleteOtherRequest",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // console.log(data);
    const { id } = data;

    try {
      const res = await axios.delete(`${BASE_API}/requestOther/${id}`, {
        data: data,
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //deleteOtherRequest

export const deleteVacationRequest = createAsyncThunk(
  "requests/deleteVacationRequest",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // console.log(data);
    const { id } = data;

    try {
      const res = await axios.delete(`${BASE_API}/requestemployee/${id}`, {
        data: data,
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //deleteVacationRequest

export const deleteVacationRequestHr = createAsyncThunk(
  "requests/deleteVacationRequestHr",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // console.log(data);
    const { id } = data;

    try {
      const res = await axios.delete(`${BASE_API}/requesthr/${id}`, {
        data: data,
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //deleteVacationRequestHr

export const deleteVacationRequestLeader = createAsyncThunk(
  "requests/deleteVacationRequestLeader",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // console.log(data);
    const { id } = data;

    try {
      const res = await axios.delete(`${BASE_API}/requestleader/${id}`, {
        data: data,
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //deleteVacationRequestLeader

export const createRequestUpdate = createAsyncThunk(
  "requests/createRequestUpdate",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    let { id } = data;
    try {
      const res = await axios.post(`${BASE_API}/requestupdates/${id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); //createRequestUpdate

export const getRequestUpdates = createAsyncThunk(
  "requests/getRequestUpdates",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    // const { id } = data
    try {
      const res = await axios.get(`${BASE_API}/requestupdates/${id}`);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); // getRequestUpdates

const initialState = {
  allRequests: [],
  otherRequests: [],
  allRequestUpdates: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    [getRequestEmployee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getRequestEmployee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [getRequestEmployee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    }, //getRequestEmployee

    [getRequestLeader.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getRequestLeader.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [getRequestLeader.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    }, //getRequestLeader

    [getRequestHr.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getRequestHr.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [getRequestHr.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    }, //getRequestHr

    [getOtherRequestEmployee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getOtherRequestEmployee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.otherRequests = action.payload;
    },
    [getOtherRequestEmployee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    }, //getOtherRequestEmployee

    //createRequest
    [createRequest.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createRequest.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [createRequest.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //createOtherRequest
    [createOtherRequest.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createOtherRequest.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.otherRequests = action.payload;
    },
    [createOtherRequest.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //updateOtherRequest
    [updateOtherRequest.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateOtherRequest.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.otherRequests = action.payload;
    },
    [updateOtherRequest.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //updateRequestHr
    [updateRequestHr.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateRequestHr.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [updateRequestHr.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //updateRequestEmployee
    [updateRequestEmployee.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateRequestEmployee.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [updateRequestEmployee.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //updateRequestHr
    [updateRequestLeader.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateRequestLeader.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [updateRequestLeader.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //deleteOtherRequest
    [deleteOtherRequest.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [deleteOtherRequest.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.otherRequests = action.payload;
    },
    [deleteOtherRequest.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //deleteVacationRequest
    [deleteVacationRequest.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [deleteVacationRequest.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [deleteVacationRequest.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //deleteVacationRequestHr
    [deleteVacationRequestHr.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [deleteVacationRequestHr.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [deleteVacationRequestHr.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //deleteVacationRequestLeader
    [deleteVacationRequestLeader.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [deleteVacationRequestLeader.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequests = action.payload;
    },
    [deleteVacationRequestLeader.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //createRequestUpdate
    [createRequestUpdate.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createRequestUpdate.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequestUpdates = action.payload;
    },
    [createRequestUpdate.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //getRequestUpdates
    [getRequestUpdates.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getRequestUpdates.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allRequestUpdates = action.payload;
    },
    [getRequestUpdates.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { reset } = requestSlice.actions;
export default requestSlice.reducer;
