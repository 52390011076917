
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import styles from "./summary.module.css";


const CustomSummaryTableMarketing = ({ allSummaries }) => {



    const iterator = (status) => {
        if (status === "completed") {
            return allSummaries.reduce((prev, cur) => {
                return prev + +cur.completed;
            }, 0);
        } else if (status === "inProgress") {
            return allSummaries.reduce((prev, cur) => {
                return prev + +cur.inProgress;
            }, 0);
        }

        if (status === "notStarted") {
            return allSummaries.reduce((prev, cur) => {
                return prev + +cur.notStarted;
            }, 0);
        }

        if (status === "postponed") {
            return allSummaries.reduce((prev, cur) => {
                return prev + +cur.postponed;
            }, 0);
        }
    };

    const getTotalTasks = () => {
        return allSummaries.reduce((prev, cur) => {
            return prev + +cur.totalTasks;
        }, 0);
    }

    const totalPersentatge = () => {
        return getTotalTasks() !== 0
            ? parseFloat(
                (iterator("completed") /
                    getTotalTasks()) *
                100
            ).toFixed(2)
            : 0;
    };
    const categories = ["completed", "postponed", "inProgress", "notStarted"]
    const colors = ["#52AAF0", "#2AB57D", "#FFBF53", "#dd2c00"];


    return (
        <TableContainer component={Paper} className={styles.tableContainer}>
            <Table className={styles.tableStyle} aria-label="simple table">
                <TableHead className={styles.tableHeadStyle}>
                    <TableRow>
                        <TableCell>Summary</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell component="th" scope="row">
                            Total Tasks
                        </TableCell>
                        <TableCell align="right">{getTotalTasks()}</TableCell>
                    </TableRow>
                    {categories.map((item, index) => {
                        return (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:last-child td, &:last-child th": { border: 1 },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {item}
                                </TableCell>
                                <TableCell align="right">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div
                                            style={{
                                                backgroundColor: `${colors[index]}`,
                                                height: "1rem",
                                                width: "1rem",
                                            }}
                                        ></div>
                                        <div>
                                            {iterator(item)}
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>)
                    })}


                    <TableRow
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell component="th" scope="row">
                            Total % complete
                        </TableCell>
                        <TableCell align="right">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div
                                    style={{
                                        backgroundColor: "#FF8042",
                                        height: "1rem",
                                        width: "1rem",
                                    }}
                                ></div>
                                <div>
                                    {totalPersentatge()}
                                    %
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default CustomSummaryTableMarketing;
