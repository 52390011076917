import * as React from "react";
import { DataGrid, useGridApiContext, gridClasses } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import styles from "../project.module.css";
import { Alert, Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import AddProjectUpdate from "./projectUpdateForm";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import {
  editProjectUpdate,
  getProjectUpdates,
} from "../../../store/projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ProjectUpdatesSummary from "./projectUpdateSummary";
import { planning } from "../../../roles";

const useFakeMutation = () => {
  return React.useCallback(
    (row) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            row.DL_UP?.trim() === "" ||
            row.UP?.trim() === "" ||
            row.Data_Date?.trim() === "" ||
            row.Start_Date?.trim() === "" ||
            row.Submission_Date?.trim() === ""
          ) {
            reject(new Error("Error while saving row: data can't be empty."));
          } else {
            resolve({ ...row, DL_UP: row.DL_UP?.toUpperCase() });
          }
        }, 200);
      }),
    []
  );
};

const task_duration = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const count = (Project_Stage_List, DL_UP, DL_Analysis_type) => {
  let count = 1;
  if (Project_Stage_List === "Delay") {
    if (DL_Analysis_type === "Window") {
      count = +DL_UP / 2;
    } else {
      count = +DL_UP;
    }
  }
  return count;
};

export default function ProjectUpdates(props) {
  const { allProjectUpdates, isLoading } = useSelector(
    (state) => state.projects
  );
  const { user } = useSelector((state) => state.employees);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // update rows
  const mutateRow = useFakeMutation();
  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      let response = await mutateRow(newRow);

      if (newRow.Call) {
        newRow.recentDate = newRow.Call;
      } else if (newRow.Email2) {
        newRow.recentDate = newRow.Email2;
      } else if (newRow.Email1) {
        newRow.recentDate = newRow.Email1;
      } else {
        newRow.recentDate = newRow.Submission_Date;
      }

      dispatch(editProjectUpdate(newRow));

      response.Task_Duration =
        task_duration(response.Start_Date, response.Submission_Date) + " CD";
      response.Count = count(
        response.Project_Stage_List,
        response.DL_UP,
        response.DL_Analysis_type
      );

      setSnackbar({
        children: "row successfully updated",
        severity: "success",
      });

      return response;
    },
    [mutateRow]
  );

  React.useEffect(() => {
    dispatch(getProjectUpdates(props));
  }, [navigate]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const SelectEditInputCellBoolean = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option>Yes</option>
        <option>No</option>
      </Select>
    );
  };

  const SelectEditInputCellStatge = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option>Baseline</option>
        <option>Update</option>
        <option>Rebaseline</option>
        <option>Delay</option>
        <option>Recovery</option>
        <option>Pre-Bid</option>
        <option>Preliminary</option>
        <option>Conversation</option>
      </Select>
    );
  };

  const SelectEditInputCellDl = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option>TIA</option>
        <option>Window</option>
        <option>N/A</option>
      </Select>
    );
  };

  const SelectEditInputCellActivity = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option>Submitted</option>
        <option>In Progress</option>
      </Select>
    );
  };

  const SelectEditInputCellSubmission = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option>Done</option>
        <option>Last</option>
      </Select>
    );
  };

  const renderSelectEditInputCellBoolean = (params) => {
    return <SelectEditInputCellBoolean {...params} />;
  };
  const renderSelectEditInputCellStatge = (params) => {
    return <SelectEditInputCellStatge {...params} />;
  };

  const renderSelectEditInputCellDL = (params) => {
    return <SelectEditInputCellDl {...params} />;
  };
  const renderEditInputCellActivity = (params) => {
    return <SelectEditInputCellActivity {...params} />;
  };

  const renderEditInputCellSubmission = (params) => {
    return <SelectEditInputCellSubmission {...params} />;
  };
  const columns = [
    {
      field: "planner",
      headerClassName: styles.planner,
      cellClassName: "planner",
      headerName: "planner",
      width: 100,
      editable: true,
      valueGetter: (params) => params.value.toUpperCase(),
    },
    {
      field: "Project_Stage_List",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "Project Stage List",
      renderEditCell: renderSelectEditInputCellStatge,
      width: 100,
      editable: true,
    },
    {
      field: "Data_Date",
      headerClassName: styles.submission,
      cellClassName: "submission",
      // type: "date",
      headerName: "Data Date",
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      width: 110,
      editable: true,
    },
    {
      field: "UP",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "UP",
      width: 60,
      editable: true,
    },
    {
      field: "DL_Analysis_type",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "DL Analysis type",
      renderEditCell: renderSelectEditInputCellDL,
      width: 100,
      editable: true,
    },
    {
      field: "DL_UP",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "DL UP#",
      width: 70,
      editable: true,
    },
    {
      field: "Activity_Status",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "Activity Status",
      renderEditCell: renderEditInputCellActivity,
      width: 100,
      editable: true,
    },
    {
      field: "Start_Date",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "Start Date",
      // type: "date",
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      width: 110,
      editable: true,
    },
    {
      field: "Submission_Date",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "Submission Date",
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      type: "date",
      width: 120,
      editable: true,
    },
    {
      field: "Submission_Status",
      headerClassName: styles.submission,
      cellClassName: "submission",
      headerName: "Submission Status",
      renderEditCell: renderEditInputCellSubmission,
      width: 120,
      editable: true,
    },
    {
      field: "Notes",
      headerClassName: styles.notes,
      cellClassName: "notes",
      headerName: "Notes",
      width: 250,
      editable: true,
    },
    {
      field: "Email1",
      headerClassName: styles.reminders,
      cellClassName: "reminders",
      headerName: "reminder 1",
      width: 120,
      editable: true,
      type: "date",
      valueFormatter: (params) =>
        params?.value ? moment(params?.value).format("MM/DD/YYYY") : "",
    },
    {
      field: "Email2",
      headerClassName: styles.reminders,
      cellClassName: "reminders",
      headerName: "reminder 2",
      width: 120,
      editable: true,
      type: "date",
      valueFormatter: (params) =>
        params?.value ? moment(params?.value).format("MM/DD/YYYY") : "",
    },
    {
      field: "Call",
      headerClassName: styles.reminders,
      cellClassName: "reminders",
      headerName: "reminder 3",
      width: 120,
      editable: true,
      type: "date",
      valueFormatter: (params) =>
        params?.value ? moment(params?.value).format("MM/DD/YYYY") : "",
    },
    {
      field: "Task_Duration",
      headerClassName: styles.calculations,
      cellClassName: "calculations",
      headerName: "Task Duration",
      width: 90,
      // editable: true,
    },
    {
      field: "Count",
      headerClassName: styles.calculations,
      cellClassName: "calculations",
      headerName: "Count",
      type: "number",
      width: 100,
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Planner",
      description: "Planner",
      children: [{ field: "planner" }],
      headerClassName: styles.planner_group,
    },
    {
      groupId: "Technical Information",
      description: "Technical Information",
      children: [{ field: "Cost_Loaded" }, { field: "Resource_Loaded" }],
      headerClassName: styles.technical_group,
    },
    {
      groupId: "Submission Information",
      description: "Submission Information",
      children: [
        { field: "Project_Stage_List" },
        { field: "Data_Date" },
        { field: "UP" },
        { field: "DL_Analysis_type" },
        { field: "DL_UP" },
        { field: "Activity_Status" },
        { field: "Start_Date" },
        { field: "Submission_Date" },
        { field: "Submission_Status" },
      ],
      headerClassName: styles.submission_group,
    },
    {
      groupId: "Notes Information",
      description: "Notes",
      children: [{ field: "Notes" }],
      headerClassName: styles.notes_group,
    },
    {
      groupId: "Calculations Information",
      description: "Calculations",
      children: [{ field: "Task_Duration" }, { field: "Count" }],
      headerClassName: styles.calculations_group,
    },
    {
      groupId: "Reminders & Follow Ups",
      description: "Reminders & Follow Ups",
      children: [{ field: "Email1" }, { field: "Email2" }, { field: "Call" }],
      headerClassName: styles.reminders_group,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%", marginBlock: "1%" }}>
        <Grid container>
          <Grid container item spacing={6}>
            <ProjectUpdatesSummary
              allProjectUpdates={allProjectUpdates}
              remainder={props.remainder}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          "& .planner": {
            backgroundColor: "#F2DCDB",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "center",
            alignItems: "center",
            position: "sticky",
            left: "0",
          },
          "& .technical": {
            backgroundColor: "#FFF2CC",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "center",
            alignItems: "center",
          },
          "& .submission": {
            backgroundColor: "#E2EFDA",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "center",
            alignItems: "center",
          },
          "& .notes": {
            backgroundColor: "#DAEEF3",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "center",
            alignItems: "center",
          },
          "& .calculations": {
            backgroundColor: "#EAD5FF",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "start",
            alignItems: "start",
          },
          "& .reminders": {
            backgroundColor: "#D9D9D9",
            border: "0.5px solid black",
            fontSize: "0.8rem",
            textAlign: "center",
            alignItems: "center",
          },
        }}
      >
        <DataGrid
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          autoHeight
          getRowId={(row) => row._id}
          getRowHeight={() => "auto"}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 0,
            },
          }}
          hideFooter={true}
          loading={isLoading}
          editMode={planning(user) ? "row" : "none"}
          rows={allProjectUpdates}
          columns={columns}
          experimentalFeatures={{ columnGrouping: true, newEditingApi: true }}
          columnGroupingModel={columnGroupingModel}
        />
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <Box sx={{ maxWidth: "100%", overflowX: "auto", whiteSpace: "nowrap" }}>
        {planning(user) && <AddProjectUpdate projectId={props.projectId} />}
      </Box>
    </>
  );
}
