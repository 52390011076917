import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createDepartment
export const getMonths = createAsyncThunk(
  "monthsMarketing/getMonths",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    const {id, year} = data

    try {
      const res = await axios.get(`${BASE_API}/monthsMarketing/${id}`,{ params: { year: data.year } })
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editMonthScore
export const editMonthScore = createAsyncThunk(
  "monthsMarketing/editMonthScore",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data.values);
    const {id} = data.values
   
    try {
      const res = await axios.post(`${BASE_API}/monthsMarketing/${id}`, data.values)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  allMonths: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const monthsMarketingSlice = createSlice({
  name: "months",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //createDepartment
    [getMonths.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getMonths.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allMonths = action.payload;
    },
    [getMonths.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

      //editMonthScore
      [editMonthScore.pending]: (state, action) => {
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [editMonthScore.fulfilled]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allMonths = action.payload;
      },
      [editMonthScore.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      },

  },
});

export const { reset, setStateCurrentPage } = monthsMarketingSlice.actions;
export default monthsMarketingSlice.reducer;
