import * as React from "react";
import { Box, ButtonBase, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "../project.module.css";
import {
  addProjectUpdateLocal,
  createProjectUpdate,
} from "../../../store/projects/projectsSlice";
import { getEmployeesByDepartmentName } from "../../../store/employees/employeesSlice";

const AddProjectUpdate = ({ projectId }) => {
  const dispatch = useDispatch();
  const { user, employees } = useSelector((state) => state.employees);

  React.useEffect(() => {
    dispatch(getEmployeesByDepartmentName("Planning"));
  }, []);

  const validationSchema = Yup.object({
    Project_Stage_List: Yup.string().required("Please Enter department name"),
    Data_Date: Yup.date().required("Please Enter department name"),
    UP: Yup.string().required("Please Enter department name"),
    DL_Analysis_type: Yup.string().required("Please Enter department name"),
    DL_UP: Yup.mixed().when("Project_Stage_List", {
      is: (value) => value === "Delay",
      then: Yup.number()
        .typeError("Please enter a number for DL_UP")
        .required("Please enter a number for DL_UP"),
      otherwise: Yup.string().required("Please enter department name"),
    }),
    Activity_Status: Yup.string().required("Please Enter department name"),
    Start_Date: Yup.string().required("Please Enter department name"),
    Submission_Date: Yup.string().required("Please Enter department name"),
    Submission_Status: Yup.string().required("Please Enter department name"),
  });

  const formik = useFormik({
    initialValues: {
      planner: user?.username,
      projectId: projectId,
      Project_Stage_List: "",
      Data_Date: "",
      UP: "",
      DL_Analysis_type: "",
      DL_UP: "",
      Activity_Status: "",
      Start_Date: "",
      Submission_Date: "",
      Submission_Status: "",
      Notes: "",
    },

    onSubmit: (values, { resetForm }) => {
      values.recentDate = values.Submission_Date;
      dispatch(createProjectUpdate(values))
        .unwrap()
        .then((data) => {
          dispatch(addProjectUpdateLocal(data));
          resetForm({
            values: {
              planner: "",
              projectId: projectId,
              Project_Stage_List: "",
              Data_Date: "",
              UP: "",
              DL_Analysis_type: "",
              DL_UP: "",
              Activity_Status: "",
              Start_Date: "",
              Submission_Date: "",
              Submission_Status: "",
              Notes: "",
            },
          });
        });
    },
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.updatesContainer}>
        {/* -----------------------------------------------Planner input------------------------------------------------------- */}
        <TextField
          error={formik.touched.planner && formik.errors.planner ? true : false}
          className={styles.updatesitem}
          // sx={{ width: "10%" }}
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          id="outlined-select-currency"
          size="small"
          select
          label="Planner"
          name="planner"
          SelectProps={{
            native: true,
          }}
          {...formik.getFieldProps("planner")}
        >
          {employees.map((item, index) => {
            return (
              <option key={index} value={item.username}>
                {item.username}
              </option>
            );
          })}
        </TextField>

        {/* -----------------------------------------------Project_Stage_List input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Project_Stage_List &&
            formik.errors.Project_Stage_List
              ? true
              : false
          }
          className={styles.updatesitem}
          // sx={{ width: "10%" }}
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          id="outlined-select-currency"
          size="small"
          select
          label="Project Stage List"
          name="Project_Stage_List"
          SelectProps={{
            native: true,
          }}
          {...formik.getFieldProps("Project_Stage_List")}
        >
          <option value={"---"}>---</option>
          <option value={"Baseline"}>Baseline</option>
          <option value={"Update"}>Update</option>
          <option value={"Rebaseline"}>Rebaseline</option>
          <option value={"Delay"}>Delay</option>
          <option value={"Recovery"}>Recovery</option>
          <option value={"Pre-Bid"}>Pre-Bid</option>
          <option value={"Preliminary"}>Preliminary</option>
          <option value={"Conversion"}>Conversion</option>
        </TextField>

        {/* -----------------------------------------------Data_Date input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Data_Date && formik.errors.Data_Date ? true : false
          }
          className={styles.updatesitem}
          // sx={{ width: "10%" }}
          id="outlined-error-helper-text"
          label="Data Date"
          type="date"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="Data_Date"
          {...formik.getFieldProps("Data_Date")}
        />
        {/* -----------------------------------------------UP input------------------------------------------------------- */}
        <TextField
          error={formik.touched.UP && formik.errors.UP ? true : false}
          className={styles.updatesitem_DLUp}
          id="outlined-error-helper-text"
          label="UP"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="UP"
          {...formik.getFieldProps("UP")}
        />
        {/* -----------------------------------------------DL_Analysis_type input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.DL_Analysis_type && formik.errors.DL_Analysis_type
              ? true
              : false
          }
          className={styles.updatesitem}
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          id="outlined-select-currency"
          size="small"
          select
          label="DL Analysis type"
          name="DL_Analysis_type"
          SelectProps={{
            native: true,
          }}
          {...formik.getFieldProps("DL_Analysis_type")}
        >
          <option value={"---"}>---</option>
          <option value={"TIA"}>TIA</option>
          <option value={"Window"}>Window</option>
          <option value={"N/A"}>N/A</option>
        </TextField>

        {/* -----------------------------------------------DL_UP input------------------------------------------------------- */}
        <TextField
          error={formik.touched.DL_UP && formik.errors.DL_UP ? true : false}
          className={styles.updatesitem_DLUp}
          id="outlined-error-helper-text"
          label="DL UP"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="DL_UP"
          {...formik.getFieldProps("DL_UP")}
        />
        {/* -----------------------------------------------Activity_Status input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Activity_Status && formik.errors.Activity_Status
              ? true
              : false
          }
          className={styles.updatesitem}
          // sx={{ width: "10%" }}
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          id="outlined-select-currency"
          size="small"
          select
          label="Activity Status"
          name="Activity_Status"
          SelectProps={{
            native: true,
          }}
          {...formik.getFieldProps("Activity_Status")}
        >
          <option value={"---"}>---</option>
          <option value={"Submitted"}>Submitted</option>
          <option value={"In Progress"}>In Progress</option>
        </TextField>
        {/* -----------------------------------------------Start_Date input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Start_Date && formik.errors.Start_Date ? true : false
          }
          className={styles.updatesitem}
          id="outlined-error-helper-text"
          label="Start Date"
          type="date"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="Start_Date"
          {...formik.getFieldProps("Start_Date")}
        />
        {/* -----------------------------------------------Submission_Date input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Submission_Date && formik.errors.Submission_Date
              ? true
              : false
          }
          className={styles.updatesitem}
          id="outlined-error-helper-text"
          label="Submission Date"
          type="date"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="Submission_Date"
          {...formik.getFieldProps("Submission_Date")}
        />
        {/* -----------------------------------------------Submission_Status input------------------------------------------------------- */}
        <TextField
          error={
            formik.touched.Submission_Status && formik.errors.Submission_Status
              ? true
              : false
          }
          className={styles.updatesitem}
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          id="outlined-select-currency"
          size="small"
          select
          label="Submission_Status"
          name="Submission_Status"
          SelectProps={{
            native: true,
          }}
          {...formik.getFieldProps("Submission_Status")}
        >
          <option value={"---"}>---</option>
          <option value={"Done"}>Done</option>
          <option value={"Last"}>Last</option>
        </TextField>

        {/* -----------------------------------------------Notes input------------------------------------------------------- */}
        <TextField
          error={formik.touched.Notes && formik.errors.Notes ? true : false}
          className={styles.updatesitem}
          id="outlined-error-helper-text"
          label="Notes"
          inputProps={{ style: { fontSize: "0.9rem" } }} // font size of input text
          InputLabelProps={{ shrink: true, style: { fontSize: "0.9rem" } }} // font size of input label
          variant="outlined"
          size="small"
          name="Notes"
          {...formik.getFieldProps("Notes")}
        />
      </div>

      {/* ------------Save button -------------- */}
      <Box className={styles.addRowButtonContainer}>
        <ButtonBase onClick={formik.submitForm} className={styles.addRowButton}>
          Add Update
        </ButtonBase>
      </Box>
    </form>
  );
};
export default AddProjectUpdate;
