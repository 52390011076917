import { Alert, Button, ButtonBase, Container } from "@mui/material";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "../../pages/Reports/dailyTasks/CreateTasks.module.css";
import CircularProgress from "@mui/material/CircularProgress";

import { deleteOldTask, getOldTasks } from "../../store/oldTasks/oldTasksSlice";
import Summary from "../Reports/dailySummry/summary";
import { createSummary } from "../../store/dailySummary/dailySummarySlice";
import { bim, hr } from "../../roles";

const TeamMemberTasks = ({ setCurrentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { allOldTasks, isLoading, todayallTasks } = useSelector(
    (state) => state.oldTasks
  );
  const { user } = useSelector((state) => state.employees);
  let { employee, depart } = location?.state;

  const bimRole = bim(employee);
  const hrRole = hr(user);

  React.useEffect(() => {
    setCurrentPage(location.pathname);
    if (user) {
      dispatch(
        getOldTasks({
          id: location.state.day,
          employeeId: location.state.employee._id,
          monthId: location.state.month,
          yearId: location.state.year,
        })
      );
    }
  }, [navigate]);

  return (
    <>
      <Box sx={{ border: "1px solid #E9E9EF" }}>
        <Box className={styles.reportContainer}>
          <>
            {allOldTasks.length === 0 && !isLoading && (
              <Box className={styles.alertContainerStyle} height="400px">
                <Alert
                  variant="filled"
                  severity="info"
                  className={styles.alertStyle}
                >
                  You don't have any Tasks in {location.state.title}
                </Alert>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonBase
                onClick={() => navigate(-1)}
                type="submit"
                className={styles.backButton}
              >
                <i
                  className={`fa-solid fa-angle-left ${styles.addIconStyle}`}
                ></i>
                back
              </ButtonBase>
              <p style={{ fontFamily: "tahoma" }}>
                Tasks Of {location.state?.title} For{" "}
                {location?.state?.employee?.username}
              </p>
            </Box>

            {isLoading ? (
              <div className={styles.progressContainer}>
                <CircularProgress className={styles.progress} />
              </div>
            ) : (
              allOldTasks.length !== 0 && (
                <TableContainer
                  sx={{
                    maxHeight: 430,
                  }}
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead className={styles.tableHeadStyle}>
                      <TableRow>
                        <TableCell className={styles.headerCell}>
                          YY/MM/DD
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          From
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          To
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          Project ID
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          Project name
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          Category
                        </TableCell>
                        <TableCell className={styles.headerCell} align="right">
                          Description
                        </TableCell>
                        {bimRole && (
                          <TableCell
                            className={styles.headerCell}
                            align="right"
                          >
                            Discipline
                          </TableCell>
                        )}
                        <TableCell className={styles.headerCell} align="right">
                          task
                        </TableCell>
                        {!bimRole && (
                          <TableCell
                            className={styles.headerCell}
                            align="right"
                          >
                            Submission Type
                          </TableCell>
                        )}
                        <TableCell className={styles.headerCell} align="right">
                          Time spent
                        </TableCell>
                        {hrRole && (
                          <TableCell
                            className={styles.headerCell}
                            align="right"
                          ></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allOldTasks &&
                        allOldTasks.map((row, index) => {
                          return (
                            <TableRow
                              className={
                                row.status === "In Progress"
                                  ? styles.inProgressStyle
                                  : row.status === "Completed"
                                  ? styles.completedStyle
                                  : ""
                              }
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                className={styles.dataCell}
                                component="th"
                                scope="row"
                              >
                                {row.viewDate}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.from}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.to}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.projectId}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.projectName}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.category}
                              </TableCell>
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.taskDescription}
                              </TableCell>
                              {bimRole && (
                                <TableCell
                                  className={styles.dataCell}
                                  align="right"
                                >
                                  {row.discipline}
                                </TableCell>
                              )}
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.task}
                              </TableCell>
                              {!bimRole && (
                                <TableCell
                                  className={styles.dataCell}
                                  align="right"
                                >
                                  {row.submissionType}
                                </TableCell>
                              )}
                              <TableCell
                                className={styles.dataCell}
                                align="right"
                              >
                                {row.timeSpent}
                              </TableCell>
                              {hrRole && (
                                <TableCell>
                                  <Button
                                    className={styles.editButton}
                                    variant="text"
                                    onClick={() => {
                                      navigate(
                                        `/reports/dailyTasks/edittaskhr`,
                                        {
                                          state: { info: row, depart: depart },
                                        }
                                      );
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            )}
          </>
        </Box>
      </Box>
      <Box>
        <Summary
          employee={location?.state?.employee}
          todayallTasks={allOldTasks}
        />
      </Box>
    </>
  );
};
export default TeamMemberTasks;
