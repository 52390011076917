import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import styles from "./requests.module.css";
import CreateVacation from "../../components/addForms/createRequest";
import CreateOther from "../../components/addForms/createRequestOther";
import CreateFlexableHours from "../../components/addForms/createFlexableHours";

function SwipeableTextMobileStepper({ socket, setValue, value }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const images = [
    {
      label: "Vacation Form",
      subLabel: "Fill your data and send request",
      form: (
        <CreateVacation
          setValue={setValue}
          formType={"vacation"}
          socket={socket}
        />
      ),
    },
    {
      label: "Other Form",
      subLabel: "Fill your data and send request",
      form: <CreateOther setValue={setValue} socket={socket} />,
    },
    {
      label: "WFH Form",
      subLabel: "Fill your data and send request",
      form: (
        <CreateVacation setValue={setValue} formType={"WFH"} socket={socket} />
      ),
    },
    {
      label: "FLXH Form",
      subLabel: "Fill your data and send request",
      form: (
        <CreateFlexableHours setValue={setValue} formType={"FLXH"} socket={socket} />
      ),
    },
  ];
  const maxSteps = images.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 500, minHeight: 500, flexGrow: 1 }}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 3 ? (
              <Box>
                <p className={styles.formTitle}>{images[activeStep].label}</p>
                <p className={styles.subPageTitle}>
                  {images[activeStep].subLabel}
                </p>
                {images[activeStep].form}
              </Box>
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
