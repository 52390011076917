import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createDepartment
export const getYears = createAsyncThunk(
  "years/getYears",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BASE_API}/years/${id}`)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allYears: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const yearsSlice = createSlice({
  name: "years",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //createDepartment
    [getYears.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getYears.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allYears = action.payload;
    },
    [getYears.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.allYears = null;
    },
  },
});

export const { reset, setStateCurrentPage } = yearsSlice.actions;
export default yearsSlice.reducer;
