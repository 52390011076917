import React from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CreateTasks.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { createOtherRequest } from "../../store/requests/requestsSlice";
import { createNotification } from "../../store/notifications/notificationsSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { getEmployeesByDepartmentName } from "../../store/employees/employeesSlice";
import { useState } from "react";
import { employee } from "../../roles";

const CreateOther = ({ socket, setValue }) => {
  const { isError, isSuccess } = useSelector((state) => state.tasksMarketing);
  const { user, bdEmployees } = useSelector((state) => state.employees);
  const [selectors, setSelectors] = useState([]);
  const [isSelectorEmpty, setEmpty] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const employeeRole = employee(user);

  const onTagChange = (event, values) => {
    // console.log(values);
    setEmpty(false);
    setSelectors(values);
  };

  React.useEffect(() => {
    dispatch(getEmployeesByDepartmentName("all"));
  }, [navigate]);

  const handleNotification = (values) => {
    let receiversArray = [];

    values.receivers.forEach((element) => {
      receiversArray.push({
        receiverId: element?._id,
        message: `send you new (Other) request`,
      });
    });

    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "request",
      })
    );
  };

  const validationSchema = Yup.object({
    // username: Yup.string().required("please Enter username"),
    // receivers: Yup.array().min(1).required("please select your receivers"),
    notes: Yup.string().required("please enter your request content"),
  });

  const formik = useFormik({
    initialValues: {
      senderId: user?._id,
      senderImage: user?.image,
      senderName: user?.username,
      senderEmail: user?.email,
      department: user?.department?.name,
      receivers: selectors,
      status: "New",
      notes: "",
    },

    onSubmit: (values, { resetForm }) => {
      values.receivers = selectors;
      if (values.receivers.length === 0) {
        setEmpty(true);
      } else {
        setValue(employeeRole ? 2 : 7);

        dispatch(createOtherRequest(values))
          .unwrap()
          .then(() => {
            Store.addNotification({
              title: "Wonderful!",
              message: "New Request is added successfully",
              type: "default",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 4000,
                onScreen: true,
              },
            });

            handleNotification(values);

            // resetForm({
            //   values: {
            //     receivers: [],
            //     notes: "",
            //   },
            // });
          });
      }
    },
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box marginY={4}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="User Name"
          variant="outlined"
          type="text"
          placeholder="User Name"
          name="senderName"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("senderName")}
        />
      </Box>

      <Box marginY={4}>
        {/* ------------endDate input-------------- */}
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-email"
          label="Department"
          variant="outlined"
          type="text"
          placeholder="department"
          name="department"
          inputProps={{ readOnly: true }}
          {...formik.getFieldProps("department")}
        />
      </Box>

      {/* ------------ comments -------------- */}
      <Box marginY={4}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="tags-outlined"
          name="receivers"
          options={bdEmployees}
          getOptionLabel={(option) => option.username}
          filterSelectedOptions
          onChange={onTagChange}
          renderInput={(params) => (
            <TextField
              {...params}
              name="receivers"
              label="receivers"
              placeholder="receivers"
            />
          )}
        />
        {isSelectorEmpty ? (
          <div className={styles.errorStyle}>select at least one item</div>
        ) : null}
      </Box>

      <Box>
        <textarea
          style={{ width: "100%" }}
          size="small"
          id="outlined-basic-email"
          placeholder="Notes"
          variant="outlined"
          type="text"
          name="notes"
          rows={6}
          {...formik.getFieldProps("notes")}
        />
        {formik.touched.notes && formik.errors.notes ? (
          <div className={styles.errorStyle}>{formik.errors.notes}</div>
        ) : null}
      </Box>

      {/* ------------Save button -------------- */}
      <Button
        sx={{
          marginX: "2%",
          marginY: "6%",
          width: "80%",
          backgroundColor: "#12633F",
        }}
        variant="contained"
        type="submit"
      >
        Send Request
      </Button>
      {/* ------------back button -------------- */}
    </form>
  );
};
export default CreateOther;
