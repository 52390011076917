import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/dailyTasksPlanning/${id}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getServerTime = createAsyncThunk(
  "tasks/getServerTime",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/timezone`);
      return res.data.serverDate;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addTask = createAsyncThunk(
  "tasks/addTask",
  async (taskData, thunkAPI) => {
    //console.log(taskData);
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${BASE_API}/dailyTasksPlanning`, taskData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async (allData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(allData);
    const { id } = allData;
    const { taskdata } = allData;
    try {
      const res = await axios.put(`${BASE_API}/dailyTasksPlanning/${id}`, {
        data: taskdata,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async (allData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { id, employeeId } = allData;
    //console.log(allData);
    try {
      const res = await axios.delete(`${BASE_API}/dailyTasksPlanning/${id}`, {
        data: allData,
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allTasks: [],
  todayallTasks: [],
  serverTime: "",
  selectedtask: null,
  isLoading: false,
  isError: false,
  message: null,
  isSuccess: false,
};

export const taskSlice = createSlice({
  name: "pets",
  initialState,
  reducers: {
    selectTask: (state, action) => {
      state.selectedtask = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: {
    [getServerTime.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getServerTime.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.serverTime = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [getServerTime.rejected]: (state, action) => {
      //console.log(action)
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    }, // get server time

    [getTasks.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getTasks.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.allTasks = action.payload;
      state.todayallTasks = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [getTasks.rejected]: (state, action) => {
      //console.log(action)
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    [updateTask.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateTask.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.allTasks = action.payload;
    },
    [updateTask.rejected]: (state, action) => {
      //console.log(action)
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    [addTask.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [addTask.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.allTasks = action.payload;

      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [addTask.rejected]: (state, action) => {
      //console.log(action)
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    [deleteTask.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = null;
    },
    [deleteTask.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.allTasks = action.payload;
      state.todayallTasks = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    [deleteTask.rejected]: (state, action) => {
      //console.log(action)
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});
export const { selectTask, reset } = taskSlice.actions;
export default taskSlice.reducer;
