import * as React from "react";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import styles from "../project.module.css";
import LinearProgress from "@mui/material/LinearProgress";
import NoRowsComponent from "../../../components/emptyRows/noRows";
import toBd_columns from "./toBd_columns";
import {
  getFilterdProject,
  getProjects,
} from "../../../store/projects/projectsSlice";
import {
  createSettings,
  getSettings,
} from "../../../store/tablesSettings/tablesSettingSlice";

export default function ToBd({ setCurrentPage }) {
  // Store states
  const { allProjects, isLoading, numOfRows } = useSelector(
    (state) => state.projects
  );
  const { user } = useSelector((state) => state.employees);
  const { allSettings } = useSelector((state) => state.settings);

  // react hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPageSize, setCurrentPageSize] = React.useState(50);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);

  const getData = () => {
    dispatch(getSettings(user._id)).then(() => {
      dispatch(
        getProjects({
          id: user?._id,
          schema: "ToBd",
          projectDepart: location?.state?.info || "Planning",
        })
      );
    });
  }; // get all projects

  React.useEffect(() => {
    if (user) {
      getData();
      setCurrentPage(location.pathname);
    }
  }, [navigate, location?.state?.info]); // get all data on first load

  return (
    <Box sx={{ border: "1px solid #E9E9EF" }}>
      <Box className={styles.reportContainer}>
        <p className={styles.pageTitle}>To BD Projects</p>

        <div
          style={{
            minheight: 600,
            minWidth: 650,
            height: "75vh",
            width: "100%",
            marginTop: "1%",
            backgroundColor: "white",
          }}
        >
          {!isLoading ? (
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 0,
                },
              }}
              // autoHeight={allProjects.length > 10}
              rowCount={numOfRows}
              rowsPerPageOptions={[10, 20, 50]}
              initialState={{
                pagination: {
                  pageSize: currentPageSize,
                },
                columns: {
                  columnVisibilityModel: allSettings?.settingObj,
                },
              }}
              onColumnVisibilityModelChange={(newModel) => {
                dispatch(
                  createSettings({ id: user._id, userSettingObj: newModel })
                );
              }}
              checkboxSelection
              rows={allProjects}
              getRowId={(row) => row._id}
              columns={toBd_columns(setCurrentPage, user, navigate, location)}
              loading={isLoading}
              components={{
                Toolbar: GridToolbar,
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: NoRowsComponent,
              }}
            />
          ) : (
            <div className={styles.fakeDev}>
              <DataGrid
                columns={[]}
                rows={[]}
                components={{
                  Toolbar: GridToolbar,
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: NoRowsComponent,
                }}
                loading={true}
              />
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}
