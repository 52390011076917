export const hr = (user) => {
  if (user) {
    return user.department.name === "HR";
  }
};

export const employee = (user) => {
  if (user) {
    return user.authority === "Other";
  }
};

export const marketing = (user) => {
  if (user) {
    return user.department.name === "Marketing";
  }
};

export const marketing_Leader = (user) => {
  if (user) {
    return (
      user.department.name === "Marketing" && user.authority === "TeamLeader"
    );
  }
};

export const marketing_Employee = (user) => {
  if (user) {
    return user.department.name === "Marketing" && user.authority === "Other";
  }
};

export const planning = (user) => {
  if (user) {
    return user.department.name === "Planning";
  }
};

export const bim = (user) => {
  if (user) {
    return user.department.name === "BIM";
  }
};

export const Planning_Leader = (user) => {
  if (user) {
    return (
      user.department.name === "Planning" && user.authority === "TeamLeader"
    );
  }
};

export const Planning_Employee = (user) => {
  if (user) {
    return user.department.name === "Planning" && user.authority === "Other";
  }
};

export const bd = (user) => {
  if (user) {
    return user.department.name === "BD" && user.authority === "Other";
  }
};

export const teamLeader = (user) => {
  if (user) {
    return user.authority === "TeamLeader";
  }
};

export const admin = (user) => {
  if (user) {
    return user.admin;
  }
};

export const adminOnly = (user) => {
  if (user) {
    return user.authority === "AdminOnly";
  }
};

export const adminPlanning = (user) => {
  if (user) {
    return user.authority === "AdminPlanning";
  }
};

export const myTeamTabFilter = (item, user) => {
  if (user) {
    if (
      user.department.name === "HR" ||
      user.authority === "AdminPlanning" ||
      user.authority === "AdminOnly"
    ) {
      return true;
    } else {
      return item.authority === "Other" && item.teamLeader === user._id;
    }
  }
};

export const remainingTimeStyle = (row, user) => {
  if (user) {
    return user.authority !== "Other";
  }
};
