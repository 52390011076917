import * as React from "react";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import styles from "./requests.module.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequestEmployee,
  updateRequestEmployee,
} from "../../store/requests/requestsSlice";
import { Box, ButtonBase, Select } from "@mui/material";
import DeleteOtherRequestdialog from "./deleteOtherRequestDialog";

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) =>
        setTimeout(() => {
          if (user.receiverName?.trim() === "") {
            reject();
          } else {
            resolve(user);
          }
        }, 200)
      ),
    []
  );
};

function computeMutation(newRow, oldRow) {
  if (newRow.startDate !== oldRow.startDate) {
    return `StartDate from '${oldRow.startDate.split("T")[0]}' to '${new Date(
      newRow.startDate
    ).toLocaleDateString()}'`;
  }
  if (newRow.endDate !== oldRow.endDate) {
    return `EndDate from '${oldRow.endDate.split("T")[0] || ""}' to '${
      new Date(newRow.endDate).toLocaleDateString() || ""
    }'`;
  }

  if (newRow.requestTime !== oldRow.requestTime) {
    return `requestTime from '${oldRow.requestTime}' to '${newRow.requestTime}'`;
  }

  if (newRow.notes !== oldRow.notes) {
    return `notes from '${oldRow.notes}' to '${newRow.notes}'`;
  }
  return null;
}

export default function AskConfirmationBeforeSave({ status, requestType }) {
  const mutateRow = useFakeMutation();
  const noButtonRef = React.useRef(null);
  const [promiseArguments, setPromiseArguments] = React.useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.employees);
  const { allRequests, isLoading } = useSelector((state) => state.requests);

  React.useEffect(() => {
    if (user) {
      dispatch(getRequestEmployee({ id: user?._id, requestType: requestType }));
    }
  }, [navigate]);

  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    // console.log(newRow);
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: "User successfully saved", severity: "success" });
      resolve(response);
      setPromiseArguments(null);
      dispatch(updateRequestEmployee(newRow));
    } catch (error) {
      setSnackbar({ children: "Name can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    {
      field: "receiverName",
      headerName: "Receiver Name",
      width: 150,
    },
    {
      field: "receiverEmail",
      headerName: "Receiver Email",
      width: 250,
    },

    {
      field: "department",
      headerName: "Department",
      width: 150,
    },
    {
      field: "requestTime",
      headerName: "Type",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Full day", "Half day"],
      width: 150,
    },
    {
      field: "startDate",
      headerName: "From",
      type: "date",
      width: 150,
      editable: true,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },

    {
      field: "endDate",
      headerName: "To",
      type: "date",
      width: 150,
      editable: true,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },

    {
      field: "status",
      headerName: "Status",
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <span
            className={
              params.row.status === "InProgress"
                ? styles.inProgressStatus
                : params.row.status === "Take It"
                ? styles.approvedStatus
                : params.row.status === "Not Taken"
                ? styles.notTaken
                : params.row.status === "Rejected"
                ? styles.notAssignedStatus
                : styles.newStatus
            }
          >
            {params.row.status === "InProgress" && (
              <LoopIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Take It" && (
              <CheckIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Rejected" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status === "Not Taken" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status}
          </span>
        );
      },
      headerClassName: styles.dataCell,
    }, // STatus

    {
      field: "notes",
      headerName: "Notes",
      width: 150,
      editable: true,
    },

    {
      field: "delete",
      headerName: "",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {params.row.status !== "New" &&
              params.row.status !== "InProgress" && (
                <DeleteOtherRequestdialog
                  setSnackbar={setSnackbar}
                  rowId={params.row._id}
                  userId={user?._id}
                  dispatch={dispatch}
                  state={"vacation"}
                  requestType={requestType}
                />
              )}
          </Box>
        );
      },
    }, // action
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      {renderConfirmDialog()}
      <DataGrid
        rows={allRequests}
        columns={columns}
        getRowId={(row) => row._id}
        loading={isLoading}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        isCellEditable={(params) => {
          return (
            params.row.status !== "Take It" && params.row.status !== "Not Taken"
          );
        }}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={3000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
