import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  Box,
  ButtonBase,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./requests.module.css";
import {
  createRequestUpdate,
  getRequestUpdates,
} from "../../store/requests/requestsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { createNotification } from "../../store/notifications/notificationsSlice";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { hr } from "../../roles";

export default function AlignItemsList({
  setCurrentPage,
  socketContext,
  setValue,
}) {
  const [socket, setSocket] = React.useContext(socketContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.employees);
  const { isLoading, allRequestUpdates } = useSelector(
    (state) => state.requests
  );
  const [message, setMessage] = useState(``);
  const hrRole = hr(user);

  useEffect(() => {
    dispatch(getRequestUpdates(location?.state?.info?._id));
    setCurrentPage(location.pathname);
  }, [navigate]);

  const handelReceiverArray = (arr) => {
    let receiversArray = [];
    arr.forEach((element) => {
      receiversArray.push({
        receiverId: element?._id,
        message: `add update to request with date (${location?.state?.info?.created_date})`,
      });
    });
    return receiversArray;
  };

  const handleNotification = () => {
    let receiversArray = [];

    if (location?.state?.info?.senderName === user?.username) {
      receiversArray = handelReceiverArray(location?.state?.info?.receivers);
    } else {
      let rec_arr = location?.state?.info?.receivers.filter((item) => {
        return item.username !== user?.username;
      });

      rec_arr.push({ _id: location?.state?.info?.senderId });
      receiversArray = handelReceiverArray(rec_arr);
    }

    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "request",
      })
    );
  };

  const handelSendmessage = () => {
    if (message.trim()) {
      let data = {
        id: location?.state?.info?._id,
        content: message,
        senderName: user?.username,
        senderImage: user?.image,
      };
      dispatch(createRequestUpdate(data)).then(() => {
        handleNotification();
        setMessage("");
        dispatch(getRequestUpdates(location?.state?.info?._id));
      });
    }
  };

  const handelPrees = (event) => {
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13 && !event.shiftKey) {
      // Don't generate a new line
      event.preventDefault();

      // Do something else such as send the message to back-end
      if (event.key === "Enter") {
        handelSendmessage();
      }
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, mb: 10 }}>No Updates</Box>
      </StyledGridOverlay>
    );
  }

  const updaytesList = allRequestUpdates.map((item, index) => {
    return (
      <ListItem
        key={index}
        className={styles.listItemStyle}
        alignItems="flex-start"
        secondaryAction={
          <Box
            className={
              item.senderName === user?.username ? "" : styles.secondaryAction
            }
          >
            <IconButton edge="end" aria-label="comments">
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton edge="end" aria-label="comments">
              <DeleteIcon fontSize="inherit" color="error" />
            </IconButton>
          </Box>
        }
      >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={item.senderImage} />
        </ListItemAvatar>
        <ListItemText
          primary={
            item.senderName.charAt(0) +
            item.senderName.substring(1).toLowerCase()
          }
          secondary={
            <React.Fragment>
              <span>{item.content}</span>
              <Divider
                variant="middle"
                sx={{
                  marginBlock: 1,
                  backgroundColor: "#8a8a8a",
                }}
              />
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.date}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  });

  return (
    <Box className={styles.updatesContainer}>
      <ButtonBase
        onClick={() => {
          navigate(-1);
          setValue(2);
        }}
        type="submit"
        className={styles.backButton}
      >
        <i className={`fa-solid fa-angle-left ${styles.addIconStyle}`}></i>
        back
      </ButtonBase>
      <p className={styles.updatesPageTitle}>Recent updates</p>
      <p className={styles.updatesPageSubTitle}>Latest Updates by moderators</p>

      <Container className={styles.updatesListStyle}>
        <List sx={{ width: "80%" }}>
          <ListItem
            className={styles.fixedlistItemStyle}
            alignItems="flex-start"
            secondaryAction={
              <Box
                className={
                  location?.state?.info?.senderName === user?.username
                    ? ""
                    : styles.secondaryAction
                }
              >
                <IconButton edge="end" aria-label="comments">
                  <BorderColorOutlinedIcon />
                </IconButton>
                <IconButton edge="end" aria-label="comments">
                  <DeleteIcon fontSize="inherit" color="error" />
                </IconButton>
              </Box>
            }
          >
            <ListItemAvatar>
              <Avatar
                alt="Remy Sharp"
                src={location?.state?.info?.senderImage}
              />
            </ListItemAvatar>
            <ListItemText
              primary={location?.state?.info?.senderName}
              secondary={
                <React.Fragment>
                  <span>{location?.state?.info?.notes}</span>
                  <Divider
                    variant="middle"
                    sx={{
                      marginBlock: 1,
                      backgroundColor: "#8a8a8a",
                    }}
                  />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {"Request date: "} {location?.state?.info?.created_date}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider>
            <Chip label="15-7-2023" />
          </Divider>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                my: "60px",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : allRequestUpdates.length !== 0 ? (
            updaytesList
          ) : (
            <CustomNoRowsOverlay />
          )}
        </List>
      </Container>

      <Container
        sx={{ boxShadow: 1 }}
        className={styles.addUpdateInputBox}
        paddingy={4}
      >
        <TextField
          className={styles.updateTextField}
          fullWidth
          id="outlined-basic-email"
          label="+ add update"
          variant="outlined"
          type="text"
          placeholder="enter new update"
          name="senderName"
          value={message}
          onKeyDown={(e) => handelPrees(e)}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <SendIcon
                color="primary"
                className={styles.addUpdateIcon}
                onClick={handelSendmessage}
              />
            ),
          }}
        />
      </Container>
    </Box>
  );
}
