import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import styles from "./requests.module.css";
import SwipeableTextMobileStepper from "./requestSlider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import DataGridDemoLeader from "./requestsTableLeader";
import { admin, hr } from "../../roles";
import DataGridDemoHr from "./requestsTableHr";
import AskConfirmationBeforeSave from "./requestsTableEmployee";
import CustomToolbarGrid from "./otherRequestsTable";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  minHeight: "480px",
  height: "auto",
  borderRadius: "10px",

  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Requests({ socketContext, value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = useSelector((state) => state.employees);
  const hrRole = hr(user);
  const adminRole = admin(user);
  const [socket, setSocket] = React.useContext(socketContext);

  return (
    <Box component={Paper}>
      <Container className={styles.formContainer}>
        <Item>
          <SwipeableTextMobileStepper setValue={setValue} socket={socket} />
        </Item>
      </Container>

      {user.authority !== "Other" || hrRole ? (
        <Box p={6}>
          <p className={styles.pageTitle}>Requests History</p>
          <p className={styles.subPageTitle}>
            Access all your previous requests.
          </p>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              paddingInline: "7%",
            }}
          >
            <p className={styles.myTabHeader}>Team</p>
            <p className={styles.myTabHeader}>My Requests</p>
          </Box>
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab className={styles.tab} label="Vacations" {...a11yProps(0)} />
            <Tab className={styles.tab} label="WFH" {...a11yProps(1)} />
            <Tab className={styles.tab} label="FLXH" {...a11yProps(2)} />
            <Tab className={styles.tab} label="Other" {...a11yProps(3)} />
            <Tab
              className={styles.tab}
              label="        "
              {...a11yProps(4)}
              disabled
            />
            <Tab
              className={styles.tab}
              label="        "
              {...a11yProps(5)}
              disabled
            />
            <Tab className={styles.tab} label="Vacations" {...a11yProps(6)} />
            <Tab className={styles.tab} label="WFH" {...a11yProps(7)} />
            <Tab className={styles.tab} label="FLXH" {...a11yProps(8)} />
            <Tab className={styles.tab} label="Other" {...a11yProps(9)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            {hrRole ? (
              <DataGridDemoHr
                requestType={"vacation"}
                status={"myTeamRequests"}
              />
            ) : (
              <DataGridDemoLeader
                requestType={"vacation"}
                socket={socket}
                status={"myTeamRequests"}
              />
            )}
          </TabPanel>

          <TabPanel value={value} index={1}>
            {hrRole ? (
              <DataGridDemoHr requestType={"WFH"} status={"myTeamRequests"} />
            ) : (
              <DataGridDemoLeader
                requestType={"WFH"}
                socket={socket}
                status={"myTeamRequests"}
              />
            )}
          </TabPanel>

          <TabPanel value={value} index={2}>
            {hrRole ? (
              <DataGridDemoHr requestType={"FLXH"} status={"myTeamRequests"} />
            ) : (
              <DataGridDemoLeader
                requestType={"FLXH"}
                socket={socket}
                status={"myTeamRequests"}
              />
            )}
          </TabPanel>

          <TabPanel value={value} index={3}>
            <CustomToolbarGrid viewStatus={"teamRequests"} socket={socket} />
          </TabPanel>

          <TabPanel value={value} index={6}>
            <AskConfirmationBeforeSave
              requestType={"vacation"}
              status={"myRequests"}
            />
          </TabPanel>

          <TabPanel value={value} index={7}>
            <AskConfirmationBeforeSave
              requestType={"WFH"}
              status={"myRequests"}
            />
          </TabPanel>

          <TabPanel value={value} index={8}>
            <AskConfirmationBeforeSave
              requestType={"FLXH"}
              status={"myRequests"}
            />
          </TabPanel>

          <TabPanel value={value} index={9}>
            <CustomToolbarGrid viewStatus={"myRequests"} socket={socket} />
          </TabPanel>
        </Box>
      ) : (
        <Box p={6}>
          <p className={styles.pageTitle}>Requests History</p>
          <p className={styles.subPageTitle}>
            Access all your previous requests.
          </p>
          <Tabs className={styles.tab} value={value} onChange={handleChange}>
            <Tab className={styles.tab} label="Vacations" {...a11yProps(0)} />
            <Tab className={styles.tab} label="WFH" {...a11yProps(1)} />
            <Tab className={styles.tab} label="FLXH" {...a11yProps(2)} />
            <Tab className={styles.tab} label="Other" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <AskConfirmationBeforeSave
              requestType={"vacation"}
              status={"myRequests"}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AskConfirmationBeforeSave
              requestType={"WFH"}
              status={"myRequests"}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <AskConfirmationBeforeSave
              requestType={"FLXH"}
              status={"myRequests"}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CustomToolbarGrid viewStatus={"myRequests"} socket={socket} />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
}
