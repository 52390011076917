import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import styles from "./home.module.css";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import ImageAvatar from "../../components/avatar/avatarComponent";
import CustomizedMenus from "../../components/profileList/profileComponent";
import { styled, useTheme } from "@mui/material/styles";
import AccountMenu from "../../components/notification/notificationComponent";

const AppBarComponent = ({
  open,
  handleDrawerOpen,
  drawerWidth,
  setCurrentPage,
  socket,
  user,
}) => {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    color: "black",
    boxShadow: "none",
    borderBottom: `1px solid #E9E9EF`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })); // AppBar Styled Component

  return (
    <AppBar position="fixed" open={open} className={styles.appbar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* ******************************** notifications  ********************************** */}
      <div className={styles.profileSmallContainer}>
        <Toolbar>
          <AccountMenu socket={socket} />
        </Toolbar>
        <Toolbar className={styles.profileDiv}>
          <span className={styles.userAvatar_container}>
            <ImageAvatar src={user && user.image} />
          </span>
          <CustomizedMenus socket={socket} setCurrentPage={setCurrentPage} />
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default AppBarComponent;
