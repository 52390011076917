import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createDepartment
export const getYears = createAsyncThunk(
  "yearsMarketing/getYears",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.get(`${BASE_API}/yearsMarketing/${id}`)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createYear = createAsyncThunk(
  "yearsMarketing/createYear",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI; 

    try {
      const res = await axios.post(`${BASE_API}/yearsMarketing`,data)
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); 

const initialState = {
  allYears: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const yearsSliceMarketing = createSlice({
  name: "years",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //getYears
    [getYears.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getYears.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allYears = action.payload;
    },
    [getYears.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //getYears
    [createYear.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createYear.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allYears = action.payload;
    },
    [createYear.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { reset, setStateCurrentPage } = yearsSliceMarketing.actions;
export default yearsSliceMarketing.reducer;
