//@collapse
import React, { useState } from "react";
import { Box, ButtonBase, Container, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { reset, updateTask } from "../../store/dailyTasks/taskSlice";
import styles from "./CreateTasks.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createSummary,
  editOldSummary,
} from "../../store/dailySummary/dailySummarySlice";
import { Store } from "react-notifications-component";
import { hint } from "../notification-message-progress/notification_message_progress";

const EditOldTasks = ({ setCurrentPage }) => {
  const { isError, isSuccess, isLoading } = useSelector((state) => state.tasks);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setInfo] = useState(
    location?.state?.info || {
      date: "",
      from: "",
      to: "",
      submission_number: "",
      deduct: "",
      projectId: "",
      projectName: "",
      category: "",
      taskDescription: "",
      task: "",
      employeeId: "",
      viewDate: "",
      submissionType: "",
    }
  );
  // const { info } = user ? location.state : { info: "" };

  React.useEffect(() => {
    setCurrentPage(location.pathname);
  }, [navigate]);

  const validationSchema = Yup.object({
    from: Yup.string().required("Please Enter your start time"),
    to: Yup.string().required("Please Enter your end time"),
    taskDescription: Yup.string().required("please select task description"),
    submissionType: Yup.string()
      .required("Please Enter Submission time")
      .notOneOf(["---"], "Submission time cannot be '---'"),
  });

  const [selected, setSelected] = React.useState(info.category);
  const checkIfDeduct = (submissionType) => {
    if (
      submissionType === "Baseline" ||
      submissionType === "Pre-Bid" ||
      submissionType === "Preliminary"
    ) {
      return true;
    }
    return false;
  };

  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  const Project_Admin = ["Client Calls", "Follow Ups"];
  const Technical = [
    "Reviewing Documents",
    "BL Schedule Development",
    "Submittal Log",
    "Contractor Comments",
    "CL / RL / PSR",
    "Narrative Report",
    "Finalizing Packages",
    "Reviewer's Comments",
    "Upload to Portal",
    "Update templates",
    "Update Schedule",
    "Delay Analysis",
    "Recovery/Rebaseline Schedule",
  ];
  const Assisting = ["Assisting"];
  const Office_Admin = [
    "Tracking sheet",
    "Project Folders",
    "Presentation Preparation",
  ];
  const Internal_Meetings = [
    "Weekly Meetings",
    "Admin Meeting",
    "Fun Activities",
    "Evaluation Meeting",
  ];
  const Training = ["Sponsored Certificates", "Technical Training"];
  const Break = ["Break"];
  const No_Work_Assigned = ["No Work Assigned"];

  let type = null;
  let options = null;

  if (selected === "Project Admin") {
    type = Project_Admin;
  } else if (selected === "Technical") {
    type = Technical;
  } else if (selected === "Assisting") {
    type = Assisting;
  } else if (selected === "Office Admin") {
    type = Office_Admin;
  } else if (selected === "Internal Meetings") {
    type = Internal_Meetings;
  } else if (selected === "Training") {
    type = Training;
  } else if (selected === "Break") {
    type = Break;
  } else if (selected === "No Work Assigned") {
    type = No_Work_Assigned;
  }

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }

  const formik = useFormik({
    initialValues: {
      date: info.date,
      from: info.from,
      to: info.to,
      submission_number: info.submission_number,
      deduct: info.deduct,
      projectId: info.projectId,
      projectName: info.projectName,
      category: selected,
      taskDescription: info.taskDescription,
      task: info.task,
      employeeId: info.employeeId,
      viewDate: info.viewDate,
      submissionType: info.submissionType || "Baseline",
    },

    onSubmit: (values, { setSubmitting }) => {
      if (!navigator.onLine) {
        // Check for network connectivity
        hint(
          "Network Bad Connection!",
          `Network error, please check your network and try again`,
          "danger",
          10000
        );
        setSubmitting(true);
        setTimeout(() => {
          setSubmitting(false);
        }, 10000); // 10 seconds = 10000 milliseconds
        return;
      } // check if there is network error

      values.category = selected;
      values.deduct = checkIfDeduct(values.submissionType);
      values.projectId = values.projectId.trim();

      dispatch(updateTask({ taskdata: values, id: info._id })).then((data) => {
        if (data.error) {
          hint(
            "Error!",
            `Server error, please refresh and try again`,
            "danger",
            10000
          );
        } else {
          hint("Wonderful!", `Task is updated successfully`, "success", 4000);
          dispatch(editOldSummary(values));
          navigate(-1);
        }
      });
    },
    validationSchema,
  });

  return (
    <Box className={styles.formContainer}>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          {/*--------------------- time grid ---------------- */}
          <Grid container spacing={2} className={styles.selectorContainer}>
            {/* ------------From input-------------- */}
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="from"
                className={`form-label  ${styles.labelStyle}`}
              >
                From{" "}
              </label>
              <input
                className={`form-control ${styles.inputStyle}`}
                placeholder="Enter Your start time"
                type="time"
                name="from"
                {...formik.getFieldProps("from")}
              />
              {formik.touched.from && formik.errors.from ? (
                <div className={styles.errorStyle}>
                  {formik.errors.from}
                  {isError ? isError : ""}
                </div>
              ) : null}
            </Grid>
            {/* ------------to input-------------- */}
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="to"
                className={`form-label  ${styles.labelStyle}`}
              >
                To
              </label>
              <input
                className={`form-control ${styles.inputStyle}`}
                type="time"
                placeholder="Enter Your end time"
                name="to"
                {...formik.getFieldProps("to")}
              />
              {formik.touched.to && formik.errors.to ? (
                <div className={styles.errorStyle}>{formik.errors.to}</div>
              ) : null}
            </Grid>
          </Grid>

          {/*--------------------- Category , description grid ---------------- */}
          <Grid container spacing={2} className={styles.selectorContainer}>
            {/* ------------category input -------------- */}
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="category"
                className={`form-label  ${styles.labelStyle}`}
              >
                category :
              </label>
              <select
                // {...formik.getFieldProps("category")}
                className={styles.selectStyle}
                name="category"
                onChange={changeSelectOptionHandler}
              >
                <option value="Project Admin">Choose...</option>
                <option value="Project Admin">Project Admin</option>
                <option value="Technical">Technical</option>
                <option value="Assisting">Assisting</option>
                <option value="Office Admin">Office Admin</option>
                <option value="Internal Meetings">Internal Meetings</option>
                <option value="Training">Training</option>
                <option value="Break">Break</option>
                <option value="No Work Assigned">No Work Assigned</option>
              </select>
            </Grid>
            {/* ------------Description input-------------- */}
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="taskDescription"
                className={`form-label  ${styles.labelStyle}`}
              >
                Description :
              </label>
              <select
                className={styles.selectStyle}
                name="taskDescription"
                {...formik.getFieldProps("taskDescription")}
              >
                <option value="Project Admin">Choose...</option>
                {options}
              </select>
              {formik.touched.taskDescription &&
              formik.errors.taskDescription ? (
                <div className={styles.errorStyle}>
                  {formik.errors.taskDescription}
                </div>
              ) : null}
            </Grid>
          </Grid>

          {/*--------------------- priject id , name grid ---------------- */}
          <Grid container spacing={2} className={styles.selectorContainer}>
            <Grid item xs={12} sm={12} md={6}>
              {/* ------------project id input-------------- */}
              <label
                htmlFor="projectId"
                className={`form-label  ${styles.labelStyle}`}
              >
                Project Id
              </label>
              <input
                className={`form-control ${styles.inputStyle}`}
                type="text"
                placeholder="Enter Your project id"
                name="projectId"
                {...formik.getFieldProps("projectId")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {/* ------------project name input-------------- */}
              <label
                htmlFor="projectName"
                className={`form-label  ${styles.labelStyle}`}
              >
                Project name
              </label>
              <input
                readOnly={true}
                className={`form-control ${styles.inputStyle}`}
                type="text"
                placeholder="Enter Your project name"
                name="projectName"
                {...formik.getFieldProps("projectName")}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={styles.selectorContainer}>
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="submission_number"
                className={`form-label  ${styles.labelStyle}`}
              >
                submission number
              </label>
              <input
                className={`form-control ${styles.inputStyle}`}
                type="text"
                placeholder="Enter Your submission number"
                name="submission_number"
                {...formik.getFieldProps("submission_number")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <label
                htmlFor="submission_number"
                className={`form-label  ${styles.labelStyle}`}
              >
                submission type
              </label>
              <select
                className={styles.selectStyle}
                name="submissionType"
                {...formik.getFieldProps("submissionType")}
              >
                <option value={"---"}>---</option>
                <option value={"Baseline"}>Baseline</option>
                <option value={"NA"}>NA</option>
                <option value={"Update"}>Update</option>
                <option value={"Rebaseline"}>Rebaseline</option>
                <option value={"Delay"}>Delay</option>
                <option value={"Recovery"}>Recovery</option>
                <option value={"Pre-Bid"}>Pre-Bid</option>
                <option value={"Preliminary"}>Preliminary</option>
                <option value={"Conversion"}>Conversion</option>
              </select>
              {formik.touched.submissionType && formik.errors.submissionType ? (
                <div className={styles.errorStyle}>
                  {formik.errors.submissionType}
                </div>
              ) : null}
            </Grid>
          </Grid>

          <div className="mb-2 col-sm-12 col-md-12 ">
            <label
              htmlFor="task"
              className={`form-label  ${styles.labelStyle}`}
            >
              task
            </label>
            <input
              className={`form-control ${styles.inputStyle}`}
              type="text"
              placeholder="Enter Your task"
              name="task"
              {...formik.getFieldProps("task")}
            />
          </div>

          {/* ------------Save button -------------- */}
          <ButtonBase
            type="submit"
            className={styles.saveButton}
            disabled={isLoading || formik.isSubmitting}
          >
            <i
              style={{ marginRight: "10%" }}
              className="fa-solid fa-floppy-disk"
            ></i>
            {isLoading || formik.isSubmitting ? "Saving..." : "Save"}
          </ButtonBase>
          {/* ------------back button -------------- */}
          <ButtonBase
            onClick={() => navigate(-1)}
            className={styles.deleteButton}
          >
            back
          </ButtonBase>
        </form>
      </Box>
    </Box>
  );
};
export default EditOldTasks;
