import { createTheme } from '@mui/material/styles';

const themeLight = createTheme({
    palette: {
        background: {
            default: '#F7FAFF',
        },
    },
});
export default themeLight
