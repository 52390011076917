import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import styles from './avatar.module.css'
export default function ProfilePageAvatar({src}) {

  return (
      <Avatar
        alt="Remy Sharp"
        src={src}
        className={styles.ProfilePageAvatar}
      />
   
  );
}