import { DateRange } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createNotification
export const createNotification = createAsyncThunk(
  "notifications/createNotification",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      await axios.post(`${BASE_API}/notifications`, data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editNotification
export const editNotification = createAsyncThunk(
  "notifications/editNotification",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(id);
    try {
      await axios.put(`${BASE_API}/notifications/${id}`);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//getNotification
export const getNotification = createAsyncThunk(
  "notifications/getNotification",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    try {
      const res = await axios.get(
        `${BASE_API}/notifications/${data.id}`,
        {
          params: { status: data.status },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//getAllNotification
export const getAllNotification = createAsyncThunk(
  "notifications/getAllNotification",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);
    try {
      const res = await axios.get(
        `${BASE_API}/notifications/${data.id}`,
        {
          params: { status: data.status },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allNotifications: [],
  testAllNotifications: [],
  unReadNotifications : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isLoading2 : false ,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.allNotifications = [];
    },
    setNotifications: (state, action) => {
      state.allNotifications.push(action.payload);
    },
  },

  extraReducers: {
    //createNotification
    [createNotification.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createNotification.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      // state.allProjects = action.payload;
    },
    [createNotification.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      // state.allProjects = null;
    },

    //editNotification
    [editNotification.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [editNotification.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      // state.allProjects = action.payload;
    },
    [editNotification.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      // state.allProjects = null;
    },

    //getNotification
    [getNotification.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getNotification.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.testAllNotifications = action.payload;
    },
    [getNotification.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    //getAllNotification
    [getAllNotification.pending]: (state, action) => {
      //console.log(action);
      state.isLoading2 = true;
      state.isError = false;
    },
    [getAllNotification.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading2 = false;
      state.isSuccess = true;
      state.isError = false;
      state.unReadNotifications = action.payload;
    },
    [getAllNotification.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading2 = false;
      state.isError = true;
      state.message = action.payload;
    },
  },
});

export const { reset, setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
