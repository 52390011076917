import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#52AAF0", "#2AB57D", "#FFBF53", "#dd2c00"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent * 100 !== 0 && `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const calcNumOfCompleted = (allTasks) => {
  return allTasks.reduce((prev, cur) => {
    return prev + +cur.completed;
  }, 0);
};

const calcNumOfPostpond = (allTasks) => {
  return allTasks.reduce((prev, cur) => {
    return prev + +cur.postponed;
  }, 0);
};

const calcNumOfInProgress = (allTasks) => {
  return allTasks.reduce((prev, cur) => {
    return prev + +cur.inProgress;
  }, 0);
};

const calcNumOfNotStarted = (allTasks) => {
  return allTasks.reduce((prev, cur) => {
    return prev + +cur.notStarted;
  }, 0);
};

const getTotalTasks = (allTasks) => {
  return allTasks.reduce((prev, cur) => {
    return prev + +cur.totalTasks;
  }, 0);
};

const SummaryExampleMarketing = ({ allTasks }) => {
  let numOfCompletedTasks = calcNumOfCompleted(allTasks);
  let numOfNonCompletedTasks = Math.abs(
    numOfCompletedTasks - getTotalTasks(allTasks)
  );
  let numOfPostPond = calcNumOfPostpond(allTasks);
  let numOfInProgress = calcNumOfInProgress(allTasks);
  let numOfNotStarted = calcNumOfNotStarted(allTasks);

  let data = [
    { name: "Completed", value: numOfCompletedTasks },
    { name: "PostPond", value: numOfPostPond },
    { name: "InProgress", value: numOfInProgress },
    { name: "NotStarted", value: numOfNotStarted },
  ];
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          isAnimationActive={true}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={130}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default SummaryExampleMarketing;
