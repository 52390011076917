import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  getOtherRequestEmployee,
  updateOtherRequest,
} from "../../store/requests/requestsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button } from "@mui/material";
import styles from "./requests.module.css";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import DeleteOtherRequestdialog from "./deleteOtherRequestDialog";
import FormDialog from "../../components/editForm/editOtherRequestDialog";
import { Box } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { createNotification } from "../../store/notifications/notificationsSlice";

export default function CustomToolbarGrid({ socket, viewStatus }) {
  const noButtonRef = React.useRef(null);
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.employees);
  const { otherRequests, isLoading } = useSelector((state) => state.requests);

  const useFakeMutation = () => {
    return React.useCallback(
      (user) =>
        new Promise((resolve, reject) =>
          setTimeout(() => {
            if (user.senderName?.trim() === "") {
              reject();
            } else {
              resolve(user);
            }
          }, 100)
        ),
      []
    );
  };

  const mutateRow = useFakeMutation();

  function computeMutation(newRow, oldRow) {
    if (newRow.status !== oldRow.status) {
      return `status from '${oldRow.status}' to '${newRow.status}'`;
    }

    return null;
  }

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  React.useEffect(() => {
    if (user) {
      dispatch(
        getOtherRequestEmployee({ id: user?._id, viewStatus: viewStatus })
      );
    }
  }, [navigate]);

  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleNotification = (values) => {
    let receiversArray = [];

    receiversArray.push({
      receiverId: values?.senderId,
      message: `${
        values.status === "InProgress" ? "Approved" : values.status
      } your (Other) request with date ${values?.created_date}`,
    });

    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: receiversArray,
        notificationType: "request",
      })
    );

    socket?.emit("sendNotification", {
      senderName: user?.username,
      receiverName: receiversArray,
    });
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    // console.log(newRow);
    try {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({
        children: "request status successfully saved",
        severity: "success",
      });
      resolve(response);
      setPromiseArguments(null);
      newRow.id = newRow._id;
      newRow.role = "leader";
      newRow.leaderId = user?._id;
      newRow.viewStatus = viewStatus;
      // console.log(newRow);
      dispatch(updateOtherRequest(newRow)).then(() => {
        if (newRow.status === "Rejected" || newRow.status === "InProgress")
          handleNotification(newRow);
      });
    } catch (error) {
      setSnackbar({ children: "status can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    {
      field: "senderName",
      headerName: "Owner",
      width: 150,
    },
    {
      field: "department",
      headerName: "Department",
      width: 150,
    },
    {
      field: "created_date",
      headerName: "date",
      width: 150,
    },
    {
      field: "receivers",
      headerName: "Moderators",
      // headerClassName: styles.dataCell,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <ul style={{ margin: "0" }}>
            {params.row.receivers.map((item, index) => {
              return <li key={index}>{item?.username}</li>;
            })}
          </ul>
        );
      },
      valueGetter: (params) => `${[...params.row.receivers]}`,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["New", "InProgress", "Rejected", "Take It", "Not Taken"],
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <span
            className={
              params.row.status === "InProgress"
                ? styles.inProgressStatus
                : params.row.status === "Take It"
                ? styles.approvedStatus
                : params.row.status === "Not Taken"
                ? styles.notTaken
                : params.row.status === "Rejected"
                ? styles.notAssignedStatus
                : styles.newStatus
            }
          >
            {params.row.status === "InProgress" && (
              <LoopIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Take It" && (
              <CheckIcon sx={{ paddingInline: "2%" }} fontSize="small" />
            )}
            {params.row.status === "Rejected" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status === "Not Taken" && (
              <ErrorOutlineOutlinedIcon
                sx={{ paddingInline: "2%" }}
                fontSize="small"
              />
            )}
            {params.row.status}
          </span>
        );
      },
      //   headerClassName: styles.dataCell,
    }, // STatus

    {
      field: "notes",
      headerName: "Content",
      width: 300,
    }, // content

    {
      field: "action",
      headerName: "action",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            onClick={() =>
              navigate("/requests/updates", {
                state: { info: params.row },
              })
            }
            size="small"
            variant="contained"
            sx={{ my: 1 }}
          >
            updates
          </Button>
        );
      },
    }, // action
    {
      field: "delete",
      headerName: "",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {params.row?.senderName === user?.username &&
              params.row.status !== "Take It" &&
              params.row.status !== "Not Taken" &&
              params.row.status !== "Rejected" && (
                <FormDialog socket={socket} row={params.row} />
              )}
            {((params.row?.senderName !== user?.username &&
              params.row.status !== "New" &&
              params.row.status !== "InProgress") ||
              params.row?.senderName === user?.username) && (
              <DeleteOtherRequestdialog
                setSnackbar={setSnackbar}
                rowId={params.row._id}
                userId={user?._id}
                dispatch={dispatch}
                state={"other"}
                viewStatus={viewStatus}
              />
            )}
          </Box>
        );
      },
    }, // action
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      {renderConfirmDialog()}
      <DataGrid
        rows={otherRequests}
        columns={columns}
        getRowId={(row) => row._id}
        loading={isLoading}
        getRowHeight={() => "auto"}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        isCellEditable={(params) => {
          return (
            params.row.status !== "Take It" &&
            params.row.status !== "Not Taken" &&
            params.row.senderName !== user?.username
          );
        }}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={3000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
