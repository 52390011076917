import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import CreateSummary from "../../../components/addForms/addSummary";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import CustomSummaryTable from "./customSummaryTable";
import SummaryExample from "../../../components/charts/summaryChart";
import CustomSummaryTableMarketing from "./customSummaryTableMarketing";
import SummaryExampleMarketing from "../../../components/charts/summaryChartMarketing";

const FinalSummaryMarketing = ({allSummaries}) => {

  return (
    <Grid container spacing={4}>
      <Grid item xs={11} md={6} lg={6}>
        {" "}
        <Box
          sx={{
            border: "1px solid #E9E9EF",
            marginTop: "2%",
            justifyContent: "center",
            display: "flex",
            paddingBottom: "1.3%",
          }}
        >
          <CustomSummaryTableMarketing  allSummaries={allSummaries}/>
        </Box>
      </Grid>
      <Grid item xs={11} md={6} lg={6}>
        {" "}
        <Box sx={{ border: "1px solid #E9E9EF", marginTop: "2%" }}>
          <SummaryExampleMarketing allTasks={allSummaries}/>
        </Box>
      </Grid>
    </Grid>
  );
};
export default FinalSummaryMarketing;
