import * as React from "react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CreateTasks.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  editProject,
  getProjectUpdates,
  reset,
} from "../../store/projects/projectsSlice";
import {
  getEmployees,
  getEmployeesByDepartmentName,
} from "../../store/employees/employeesSlice";
import { createNotification } from "../../store/notifications/notificationsSlice";
import { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Store } from "react-notifications-component";
import ProjectUpdates from "../../pages/projects/projectUpdates/projectUpdates";

const EditProjectAssigned = ({ setCurrentPage, socketContext }) => {
  const [socket, setSocket] = React.useContext(socketContext);
  const { user, bdEmployees } = useSelector((state) => state.employees);
  const { isLoading, isSuccess } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { info } = user && location.state ? location.state : { info: "" };

  const [newStatus, setStatus] = useState(info.status);
  const [statusError, setStatusError] = useState(false);

  const servicesData = ["Scheduling", "Delay Claim", "BIM", "Moni"];
  const scheduling_arr = [
    "Preliminary-Pre-bid",
    "Baseline",
    "Monthly Update",
    "Conversion",
    "Recovery",
    "Cost Loaded",
    "Resource Loaded",
  ];
  const delay_arr = ["TIA", "Window"];
  const bim_arr = ["Modeling", "Coordination", "Shop Drawing"];
  const moni_arr = ["Quality", "Safety", "Other", "Covid 19", "SW PPP"];

  // containers for selected services
  const [schedulingArr, setSchadualingArr] = React.useState([]);
  const [delayArr, setDelayArr] = React.useState([]);
  const [bimArr, setBimArr] = React.useState([]);
  const [moniArr, setMoniArr] = React.useState([]);

  const handelServicesInitial = () => {
    for (let index = 0; index < info.services?.length; index++) {
      switch (info.services[index]?.name) {
        case "Delay Claim":
          setDelayArr(info.services[index]?.arr);
          break;
        case "Scheduling":
          setSchadualingArr(info.services[index]?.arr);
          break;
        case "BIM":
          setBimArr(info.services[index]?.arr);
          break;
        case "Moni":
          setMoniArr(info.services[index]?.arr);
          break;
      }
    }
  };

  const handelCheckedOption = (option, val) => {
    switch (option) {
      case "Delay Claim":
        return delayArr.includes(val);
      case "Scheduling":
        return schedulingArr.includes(val);
      case "BIM":
        return bimArr.includes(val);
      case "Moni":
        return moniArr.includes(val);
    }
  };

  const handelServices = (option) => {
    switch (option) {
      case "Delay Claim":
        return delay_arr;
      case "Scheduling":
        return scheduling_arr;
      case "BIM":
        return bim_arr;
      case "Moni":
        return moni_arr;
    }
  };

  // handel push to services arrays
  const handelPush = (option, val) => {
    switch (option) {
      case "Scheduling":
        setSchadualingArr([...schedulingArr, val]);
        break;
      case "Delay Claim":
        setDelayArr([...delayArr, val]);
        break;
      case "BIM":
        setBimArr([...bimArr, val]);
        break;
      case "Moni":
        setMoniArr([...moniArr, val]);
        break;
    }
  };
  // handel pull from services arrays
  const handelPull = (option, val) => {
    switch (option) {
      case "Scheduling":
        setSchadualingArr((items) => items.filter((x) => x !== val));
      case "Delay Claim":
        setDelayArr((items) => items.filter((x) => x !== val));
      case "BIM":
        setBimArr((items) => items.filter((x) => x !== val));
      case "Moni":
        setMoniArr((items) => items.filter((x) => x !== val));
    }
  };
  const projectTypeData = [
    "Transit",
    "Sport",
    "Federal",
    "Airport",
    "State & Country",
    "Residential",
    "Hotel",
    "Educational",
    "Commercial",
    "HealthCare",
  ];

  const id = user ? user.department._id : "";
  React.useEffect(() => {
    handelServicesInitial();
    dispatch(getEmployees(id));
    dispatch(getEmployeesByDepartmentName("BD"));
    setCurrentPage(location.pathname);
  }, [navigate]);

  const chooseReceiver = (status, values) => {
    let receiversArray = [];

    if (status === "Completed" || status === "ToBd") {
      if (values.assignedEmployee !== user._id) {
        receiversArray.push({
          receiverId: values.assignedEmployee,
          message: `Update project (${values.projectName}) status to ${status}`,
        });
      }

      if (values.assistantPlanner !== user._id) {
        receiversArray.push({
          receiverId: values.assistantPlanner,
          message: `Update project (${values.projectName}) status to ${status}`,
        });
      }

      if (values.selectedTeamLeader !== user._id) {
        receiversArray.push({
          receiverId: values.selectedTeamLeader,
          message: `Update project (${values.projectName}) status to ${status}`,
        });
      }

      if (values.assistantBd) {
        receiversArray.push({
          receiverId: values.assistantBd,
          message: `Update project (${values.projectName}) status to ${status}`,
        });
      }

      receiversArray.push({
        receiverId: values.bdEmployee,
        message: `Update project (${values.projectName}) status to ${status}`,
      });
      receiversArray.push({
        receiverId: "ESRAA",
        message: `Update project (${values.projectName}) status to ${status}`,
      });
      receiversArray.push({
        receiverId: "KAL",
        message: `Update project (${values.projectName}) status to ${status}`,
      });
      receiversArray.push({
        receiverId: "TOM",
        message: `Update project (${values.projectName}) status to ${status}`,
      });
    }
    return receiversArray;
  };

  const handleNotification = (values) => {
    dispatch(
      createNotification({
        senderId: user?._id,
        receiverName: chooseReceiver(values.status, values),
        notificationType: "Project",
      })
    )
      .unwrap()
      .then((data) => {
        socket?.emit("sendNotification", {
          senderName: user?.username,
          receiverName: chooseReceiver(values.status, values),
        });
      });
  };

  const validationSchema = Yup.object({
    projectName: Yup.string().required("Please Enter project name"),
    // contractNumber: Yup.string().required("Please Enter contract number"),
    value: Yup.string().required("Please Enter project value"),
    estHours: Yup.string().required("Please Enter project est hours"),
    state: Yup.string().required("Please Enter company state"),
    company: Yup.string().required("Please Enter company name"),
    address: Yup.string().required("Please Enter company address"),
    number: Yup.string().required("Please Enter company number"),
    authority: Yup.string().required("Please Enter company authority"),
    // selectedTeamLeader: Yup.string().required("Please select Team leader"),
    // assignedEmployee: Yup.string().required("Please select assigned employee"),
  });

  const formik = useFormik({
    initialValues: {
      id: info._id,
      projectName: info.projectName,
      contractNumber: info.contractNumber,
      projectType: info.projectType,
      projectNote: info.projectNote,
      dateOfSign: info.dateOfSign.split("T")[0],
      value: info.value,
      estHours: info.estHours,
      state: info.state,
      company: info.company,
      address: info.address,
      number: info.number,
      authority: info.authority,
      program: info.program,
      contactPerson1: info.contactPerson1,
      contactNumber1: info.contactNumber1,
      contactMobile1: info.contactMobile1,
      contactEmail1: info.contactEmail1,
      authority: info.authority,
      contactPerson2: info.contactPerson2,
      contactNumber2: info.contactNumber2,
      contactMobile2: info.contactMobile2,
      contactEmail2: info.contactEmail2,
      status: info.status,
      projectId: info.projectId,
      assignedEmployee: info.assignedEmployee?._id,
      assistantPlanner: info.assistantPlanner?._id,
      selectedTeamLeader: info.selectedTeamLeader?._id,
      bdEmployee: info.bdEmployee?._id,
      assistantBd: info.assistantBd?._id,
      services: [],
    },
    onSubmit: (values) => {
      if (
        schedulingArr.length !== 0 ||
        delayArr.length !== 0 ||
        bimArr.length !== 0 ||
        moniArr.length !== 0
      ) {
        values.services = [];

        if (schedulingArr.length !== 0) {
          values.services.push({ name: "Scheduling", arr: schedulingArr });
        }
        if (delayArr.length !== 0) {
          values.services.push({ name: "Delay Claim", arr: delayArr });
        }
        if (bimArr.length !== 0) {
          values.services.push({ name: "BIM", arr: bimArr });
        }
        if (moniArr.length !== 0) {
          values.services.push({ name: "Moni", arr: moniArr });
        }
        values.status = newStatus;

        dispatch(editProject(values))
          .unwrap()
          .then(() => {
            if (isSuccess) {
              Store.addNotification({
                title: "Wonderful!",
                message: `project ${values.projectName} is updated successfully`,
                type: "success",
                insert: "top",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                  onScreen: true,
                },
              });

              handleNotification(values);
              // dispatch(reset());
              navigate(
                `/projects/${info?.status}/${location?.state?.notificationControl}`,
                {
                  state: {
                    page_num_edit: location?.state?.page_num,
                    info: location?.state?.notificationControl,
                  },
                }
              );
            }
          });
      }
    },
    validationSchema,
  });

  return (
    <Box className={styles.formContainer}>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          {/* --------------------------------------- Project --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Project
          </label>
          {/* ------------ hr container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="bdEmployee"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  BD employee{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="bdEmployee"
                  disabled={true}
                  {...formik.getFieldProps("bdEmployee")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {bdEmployees.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.bdEmployee && formik.errors.bdEmployee ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.bdEmployee}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="assistantBd"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Assistant BD{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="assistantBd"
                  disabled={true}
                  {...formik.getFieldProps("assistantBd")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {bdEmployees.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.assistantBd && formik.errors.assistantBd ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.assistantBd}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="dateOfSign"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Sign Date
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  type="date"
                  placeholder="Enter employee employment date"
                  name="dateOfSign"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("dateOfSign")}
                />

                {formik.touched.dateOfSign && formik.errors.dateOfSign ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.dateOfSign}
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="value"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  value{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project value"
                  type="text"
                  name="value"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("value")}
                />
                {formik.touched.value && formik.errors.value ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.value}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="estHours"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  EST Hours{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project est hours"
                  type="number"
                  name="estHours"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("estHours")}
                />
                {formik.touched.estHours && formik.errors.estHours ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.estHours}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectNote"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Notes{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project Notes"
                  type="text"
                  name="projectNote"
                  {...formik.getFieldProps("projectNote")}
                />
                {formik.touched.projectNote && formik.errors.projectNote ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectNote}
                  </div>
                ) : null}
              </Grid>
              {/* ---------------- project --------------------- */}
              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectName"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  project name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project name"
                  type="text"
                  name="projectName"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("projectName")}
                />
                {formik.touched.projectName && formik.errors.projectName ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectName}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="projectType"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  project type{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="projectType"
                  disabled={true}
                  {...formik.getFieldProps("projectType")}
                >
                  <option style={{ color: "grey" }}>---</option>
                  {projectTypeData.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.projectType && formik.errors.projectType ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.projectType}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="contractNumber"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  contract number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contract number"
                  type="text"
                  name="contractNumber"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contractNumber")}
                />
                {formik.touched.contractNumber &&
                formik.errors.contractNumber ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.contractNumber}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="authority"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Authority{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter project authority"
                  type="text"
                  name="authority"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("authority")}
                />
                {formik.touched.authority && formik.errors.authority ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.authority}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <label
                  htmlFor="program"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  Program{" "}
                </label>
                <select
                  className={styles.selectStyle}
                  name="program"
                  // disabled={true}
                  {...formik.getFieldProps("program")}
                >
                  <option style={{ color: "grey" }}>---</option>

                  <option value={"P6"}>P6</option>
                  <option value={"MS"}>MS</option>
                </select>
                {formik.touched.program && formik.errors.program ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.program}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              {/* ------------services input-------------- */}
              <Grid item xs={12} sm={12} md={12}>
                <label
                  htmlFor="services"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  services
                </label>
                <div
                  style={{
                    border: "1px solid #E9E9EF",
                    padding: "0.5%",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {servicesData.map((item, index) => {
                      return (
                        <>
                          <div key={index}>
                            {/* <input type="checkbox" value={item} disabled checked = {true}/> */}
                            <label>{item}</label>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 2,
                              }}
                            >
                              {handelServices(item).map((val, iterator) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    key={iterator}
                                  >
                                    <input
                                      disabled={
                                        (moniArr?.length !== 0 &&
                                          (item === "Scheduling" ||
                                            item === "Delay Claim" ||
                                            item === "BIM")) ||
                                        (bimArr?.length !== 0 &&
                                          (item === "Scheduling" ||
                                            item === "Delay Claim" ||
                                            item === "Moni")) ||
                                        ((schedulingArr?.length !== 0 ||
                                          delayArr?.length !== 0) &&
                                          (item === "BIM" || item === "Moni"))
                                      }
                                      style={{ marginRight: "5px" }}
                                      onChange={(e) => {
                                        if (
                                          user.department.name === "Planning"
                                        ) {
                                          return false;
                                        } else {
                                          // add to list
                                          if (e.target.checked) {
                                            handelPush(item, val);
                                          } else {
                                            handelPull(item, val);
                                          }
                                        }
                                      }}
                                      type="checkbox"
                                      name="services"
                                      value={val}
                                      checked={handelCheckedOption(item, val)}
                                    />
                                    <label htmlFor="services">{val}</label>
                                  </div>
                                );
                              })}
                            </Box>
                          </div>
                          <Divider
                            flexItem
                            className={
                              servicesData.length === index + 1
                                ? styles.hideDivider
                                : styles.dividerStyle
                            }
                            orientation="vertical"
                          />
                        </>
                      );
                    })}
                  </Box>
                </div>

                {formik.touched.services &&
                schedulingArr.length === 0 &&
                delayArr.length === 0 &&
                bimArr.length === 0 &&
                moniArr.length === 0 ? (
                  <div className={styles.errorStyle}>
                    choose at least one service
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Company --------------------------------------------- */}

          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Company
          </label>
          {/* ------------ Company container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- Company --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="company"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company name"
                  type="text"
                  name="company"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("company")}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.company}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="state"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  state{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company state"
                  type="text"
                  name="state"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("state")}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.state}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="address"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  address{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company address"
                  type="text"
                  name="address"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("address")}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.address}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="number"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter company number"
                  type="text"
                  name="number"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("number")}
                />
                {formik.touched.number && formik.errors.number ? (
                  <div className={styles.errorStyle}>
                    {formik.errors.number}
                    {/* {isError ? isError : ""} */}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Contact Person 1 --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Contact Person (1)
          </label>
          {/* ------------ contactPerson1 container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- contactPerson1 --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactPerson1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person name"
                  type="text"
                  name="contactPerson1"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactPerson1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactNumber1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person number"
                  type="text"
                  name="contactNumber1"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactNumber1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactMobile1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  mobile{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person mobile"
                  type="text"
                  name="contactMobile1"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactMobile1")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactEmail1"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  email{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person email"
                  type="text"
                  name="contactEmail1"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactEmail1")}
                />
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- Contact Person 1 --------------------------------------------- */}
          <label
            htmlFor="authority"
            className={`form-label  ${styles.employeeRoles_labelStyle}`}
          >
            <i
              className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
            ></i>{" "}
            Contact Person (2)
          </label>
          {/* ------------ contactPerson2 container -------------- */}
          <div className={styles.employeeRoles}>
            <Grid container spacing={2}>
              {/* ---------------- contactPerson2 --------------------- */}
              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactPerson2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  name{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person name"
                  type="text"
                  name="contactPerson2"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactPerson2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactNumber2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  number{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person number"
                  type="text"
                  name="contactNumber2"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactNumber2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactMobile2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  mobile{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person mobile"
                  type="text"
                  name="contactMobile2"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactMobile2")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <label
                  htmlFor="contactEmail2"
                  className={`form-label  ${styles.labelStyle}`}
                >
                  email{" "}
                </label>
                <input
                  className={`form-control ${styles.inputStyle}`}
                  placeholder="Enter contact Person email"
                  type="text"
                  name="contactEmail2"
                  readOnly={
                    user && user.department.name === "Planning"
                      ? "readonly"
                      : ""
                  }
                  {...formik.getFieldProps("contactEmail2")}
                />
              </Grid>
            </Grid>
          </div>

          {/* --------------------------------------- marketing part----------------------------------------- */}
          {user && user.department.name !== "BD" && (
            <>
              <label
                htmlFor="authority"
                className={`form-label  ${styles.employeeRoles_labelStyle}`}
              >
                <i
                  className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
                ></i>{" "}
                <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  part 2
                </span>
              </label>
              <div className={styles.marketingEmployeeRoles}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <label
                      htmlFor="projectId"
                      className={`form-label  ${styles.labelStyle}`}
                    >
                      project Id{" "}
                    </label>
                    <input
                      className={`form-control ${styles.inputStyle}`}
                      placeholder="Enter contact project Id"
                      type="text"
                      name="projectId"
                      readOnly={
                        user && user.department.name === "Planning"
                          ? "readonly"
                          : ""
                      }
                      {...formik.getFieldProps("projectId")}
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}
          {/* --------------------------------------- tracking sheet part----------------------------------------- */}
          <>
            <label
              htmlFor="authority"
              className={`form-label  ${styles.employeeRoles_labelStyle}`}
            >
              <i
                className={`fa-solid fa-arrow-right-long ${styles.employeeRoles_labelStyle_arrow}`}
              ></i>{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                part 3
              </span>
            </label>
            <div className={styles.updatesTableContainer}>
              <ProjectUpdates
                projectId={info?._id}
                projectStatus={info?.status}
                remainder={info?.remainder}
              />
            </div>
          </>

          {/* --------------------------------------- Status part----------------------------------------- */}
          <div className={styles.statusContainer}>
            <ButtonBase type="submit" className={styles.saveButton}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>
                  <i
                    style={{ marginRight: "10%" }}
                    className="fa-solid fa-floppy-disk"
                  ></i>
                  <span>Save</span>
                </>
              )}
            </ButtonBase>{" "}
            <ButtonBase
              onClick={() => {
                dispatch(reset());
                navigate(
                  `/projects/${info?.status}/${location?.state?.notificationControl}`,
                  {
                    state: {
                      page_num_edit: location?.state?.page_num,
                      info: location?.state?.notificationControl,
                    },
                  }
                );
              }}
              className={styles.deleteButton}
            >
              back
            </ButtonBase>{" "}
            <div
              className={
                !statusError || info.status !== newStatus
                  ? styles.hide
                  : styles.show
              }
            >
              Select One*
            </div>
            <ButtonGroup
              sx={{ marginLeft: "3%", padding: "0" }}
              variant="outlined"
              aria-label="outlined button group"
            >
              {info.status === "InProgress" && (
                <Button
                  onClick={() => setStatus("Completed")}
                  variant={newStatus === "Completed" ? "contained" : "outlined"}
                >
                  Completed
                </Button>
              )}

              {info.status === "InProgress" && (
                <Button
                  onClick={() => setStatus("ToBd")}
                  variant={newStatus === "ToBd" ? "contained" : "outlined"}
                >
                  To BD
                </Button>
              )}

              {info.status === "InProgress" && (
                <Button
                  onClick={() => setStatus("InProgress")}
                  variant={
                    newStatus === "InProgress" ? "contained" : "outlined"
                  }
                >
                  InProgress
                </Button>
              )}
            </ButtonGroup>
          </div>
        </form>
      </Box>
    </Box>
  );
};
export default EditProjectAssigned;
