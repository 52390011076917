import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

//getProjects
export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    let { id, schema, pageNumber, pageSize, projectDepart } = data;

    try {
      const res = await axios.get(`${BASE_API}/projects/${id}`, {
        params: {
          status: schema,
          pageSize: pageSize,
          pageNumber: pageNumber,
          projectDepart: projectDepart,
        },
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//getFilterdProject
export const getFilterdProject = createAsyncThunk(
  "projects/getFilterdProject",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    let { id, schema, columnField, value } = data;

    try {
      const res = await axios.get(`${BASE_API}/projects/${id}`, {
        params: {
          status: schema,
          value: value,
          columnField: columnField,
          type: "filter",
        },
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//createProject
export const createProject = createAsyncThunk(
  "projects/createProject",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.post(`${BASE_API}/projects`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editProject
export const editProject = createAsyncThunk(
  "projects/editProject",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.put(`${BASE_API}/projects/${data.id}`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//deleteProject
export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (allData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.delete(`${BASE_API}/projects/${allData.id}`, {
        data: allData,
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// -------------------------------------- tracking sheet Updates------------------------------------//
//getProjectUpdates
export const getProjectUpdates = createAsyncThunk(
  "projects/getProjectUpdates",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/projectupdate`, {
        params: data,
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//createProjectUpdate
export const createProjectUpdate = createAsyncThunk(
  "projects/createProjectUpdate",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.post(`${BASE_API}/projectupdate`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editProjectUpdate
export const editProjectUpdate = createAsyncThunk(
  "projects/editProjectUpdate",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.put(
        `${BASE_API}/projectupdate/${data._id}`,
        data
      );
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//deleteProjectUpdate
export const deleteProjectUpdate = createAsyncThunk(
  "projects/deleteProjectUpdate",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axios.delete(`${BASE_API}/projectupdate/${id}`);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// -------------------------------------- tracking sheet Reminders------------------------------------//

//createProjectReminder
export const createProjectReminder = createAsyncThunk(
  "projects/createProjectReminder",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.post(`${BASE_API}/projectreminder`, data);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//getProjectReminder
export const getProjectReminder = createAsyncThunk(
  "projects/getProjectReminder",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/projectreminder`, {
        params: data,
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//editProjectReminder
export const editProjectReminder = createAsyncThunk(
  "projects/editProjectReminder",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(data);

    try {
      const res = await axios.put(
        `${BASE_API}/projectreminder/${data._id}`,
        data
      );
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allProjects: [],
  allProjectUpdates: [],
  allProjectReminders: [],
  projectSummary: {},
  allServices: [],
  filterdProjects: [],
  numOfRows: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.allServices = [];
    },
    setServices: (state, action) => {
      state.allServices = action.payload;
    },
    addServices: (state, action) => {
      state.allServices.push(action.payload);
    },
    removeServices: (state, action) => {
      state.allServices = state.allServices.filter((val) => {
        return val !== action.payload;
      });
    },
    removeProjectUpdateLocal: (state, action) => {
      state.allProjectUpdates = state.allProjectUpdates.filter((val) => {
        return val._id !== action.payload;
      });
    },
    addProjectUpdateLocal: (state, action) => {
      state.allProjectUpdates.push(action.payload.newProjectUpdate);
      state.allProjectUpdates.forEach((element) => {
        element.Email1 = "";
        element.Email2 = "";
        element.Call = "";
        element.Submission_Status = "Done";
      });
    },
    addProjectReminderLocal: (state, action) => {
      state.allProjectReminders.push(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder
      // getProjects
      .addCase(getProjects.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allProjects = action.payload.allProjects_marketing;
        state.numOfRows = action.payload.count;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // getFilterdProject
      .addCase(getFilterdProject.pending, (state, action) => {
        state.isError = false;
      })
      .addCase(getFilterdProject.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.allProjects = action.payload.allProjects_marketing;
        state.numOfRows = action.payload.count;
      })
      .addCase(getFilterdProject.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload;
      })

      // createProject
      .addCase(createProject.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allProjects = action.payload;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // editProject
      .addCase(editProject.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(editProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      // deleteProject
      .addCase(deleteProject.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allProjects = action.payload;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // createProjectUpdate
      .addCase(createProjectUpdate.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createProjectUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.projectSummary = action.payload?.summary;
      })
      .addCase(createProjectUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // createProjectReminder
      .addCase(createProjectReminder.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createProjectReminder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createProjectReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // editProjectUpdate
      .addCase(editProjectUpdate.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(editProjectUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.projectSummary = action.payload?.summary;
      })
      .addCase(editProjectUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // getProjectUpdates
      .addCase(getProjectUpdates.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getProjectUpdates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allProjectUpdates = action.payload?.allUpdates;
        state.projectSummary = action.payload?.summary;
      })
      .addCase(getProjectUpdates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // getProjectReminder
      .addCase(getProjectReminder.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getProjectReminder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allProjectReminders = action.payload;
      })
      .addCase(getProjectReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // editProjectReminder
      .addCase(editProjectReminder.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(editProjectReminder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(editProjectReminder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // deleteProjectUpdate
      .addCase(deleteProjectUpdate.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteProjectUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteProjectUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  reset,
  setStateCurrentPage,
  addServices,
  removeServices,
  setServices,
  addProjectUpdateLocal,
  addProjectReminderLocal,
} = projectsSlice.actions;
export default projectsSlice.reducer;
