import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

//createDepartment
export const createSummary = createAsyncThunk(
  "summaryMarketing/createSummaryMarketing",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.post(
        `${BASE_API}/dailySummarymarketing`,
        userData
      );
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  dailySummary: null,
  dailySummarys: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const dailySummarySliceMarketing = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //createDepartment
    [createSummary.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createSummary.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.dailySummary = action.payload;
    },
    [createSummary.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.dailySummary = null;
    },
  },
});

export const { reset, setStateCurrentPage } = dailySummarySliceMarketing.actions;
export default dailySummarySliceMarketing.reducer;
