//@collapse
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {
  bd,
  hr,
  admin,
  marketing,
  marketing_Employee,
  marketing_Leader,
  planning,
  Planning_Employee,
  Planning_Leader,
  teamLeader,
  adminPlanning,
  adminOnly,
  bim,
} from "../../roles";
import ProfilePageAvatar from "../../components/avatar/profilePageAvatar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
// Company
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
// employees
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
//Blocked users
import BlockIcon from "@mui/icons-material/Block";
//Reports
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
//daily Reports
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
//yearly
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
//summary
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
//Requests
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// Dashboard
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
//glboal
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
// New
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
// Not Assigned
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
//inProgress
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
//Completed
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
//my team
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
// to bd
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
// all projects
import ListAltIcon from "@mui/icons-material/ListAlt";
import DehazeIcon from "@mui/icons-material/Dehaze";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SideBar = ({
  handleClick,
  handleDrawerClose,
  handleClick_Planning_Projects,
  handleClick_BIM_Projects,
  handleClick_MONI_Projects,
  user,
  Drawer,
  DrawerHeader,
  mLink,
  theme,
  navigate,
  open,
  setCurrentPage,
  currentPage,
  expandopen,
  expandopen_projects_planning,
  expandopen_projects_BIM,
  expandopen_projects_MONI,
  selectedDepart,
  handelClickOnTeam,
  selectedTeam,
}) => {
  // Roles
  const planningEmployeeRole = Planning_Employee(user);
  const marketingLeaderRole = marketing_Leader(user);
  const marketingEmployeeRole = marketing_Employee(user);
  const hrRole = hr(user);
  const marketingRole = marketing(user);
  const bdRole = bd(user);
  const teamLeaderRole = teamLeader(user);
  const adminRole = admin(user);
  const adminPlanningRole = adminPlanning(user);
  const adminBDRole = adminOnly(user);
  const bimRole = bim(user);
  const planningLeader = Planning_Leader(user);
  let departments = bimRole
    ? ["BIM"]
    : planningEmployeeRole || planningLeader
    ? ["Planning"]
    : ["Planning", "BIM", "Moni"];

  const chooseCollapse = (item) => {
    switch (item) {
      case "Planning":
        return expandopen_projects_planning;
      case "BIM":
        return expandopen_projects_BIM;
      case "Moni":
        return expandopen_projects_MONI;
    }
  };

  const chooseCollapseClick = (item) => {
    switch (item) {
      case "Planning":
        return handleClick_Planning_Projects;
      case "BIM":
        return handleClick_BIM_Projects;
      case "Moni":
        return handleClick_MONI_Projects;
    }
  };

  // console.log(currentPage);
  return (
    <Drawer variant="permanent" open={open}>
      {/* -------------------------------- Header -------------------------------- */}
      <DrawerHeader className={styles.drawerHeader}>
        <p className={styles.drawerTitle}>PEARLS</p>
        <div>
          <IconButton
            sx={{ color: "white", margin: "0", paddingTop: "0.6%" }}
            onClick={handleDrawerClose}
          >
            {theme.direction === "rtl" ? (
              <DehazeIcon className={styles.sideParIcon} />
            ) : (
              <DehazeIcon className={styles.sideParIcon} />
            )}
          </IconButton>
        </div>
      </DrawerHeader>

      {/* -------------------------------- profile -------------------------------- */}
      <div className={open ? styles.showProfile : styles.hideProfile}>
        <ProfilePageAvatar src={user && user.image} />
        <p className={styles.profileTitle}>{user && user.fullName}</p>
        <p className={styles.profilePosition}>{user && user.position}</p>
      </div>

      <List>
        {/* --------------------------------Company Tab-------------------------------- */}
        <ListItem
          component={mLink}
          to={"/home"}
          className={styles.listItem}
          disablePadding
          onClick={() => {
            setCurrentPage("/home");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PolylineOutlinedIcon
                className={
                  currentPage !== "/home" && currentPage !== "/"
                    ? styles.listItemIcon
                    : styles.selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              primary="Pearls"
              sx={{ opacity: open ? 1 : 0 }}
              className={
                currentPage !== "/home" && currentPage !== "/"
                  ? styles.listItemText
                  : styles.selected_listItemText
              }
            />
          </ListItemButton>
        </ListItem>

        {/*------------------------------- Employees Tab -------------------------------- */}
        <ListItem
          component={mLink}
          to={"/departments"}
          className={
            (user && marketingLeaderRole) || hrRole || adminRole
              ? styles.projectTab
              : styles.projectTabCondition
          }
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            setCurrentPage("/departments");
            // navigate("/departments");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <GroupsOutlinedIcon
                className={
                  currentPage.split("/")[1] !== "departments"
                    ? styles.listItemIcon
                    : styles.selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              primary="Employees"
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              sx={{ opacity: open ? 1 : 0 }}
              className={
                currentPage.split("/")[1] !== "departments"
                  ? styles.listItemText
                  : styles.selected_listItemText
              }
            />
          </ListItemButton>
        </ListItem>

        {/* --------------------------------Blocked Tab --------------------------------*/}
        <ListItem
          component={mLink}
          to={"/blocked"}
          disablePadding
          className={
            user && (marketingLeaderRole || adminRole || hrRole)
              ? styles.projectTab
              : styles.projectTabCondition
          }
          sx={{ display: "block" }}
          onClick={() => {
            setCurrentPage("/blocked");
            // navigate("/blocked");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <BlockIcon
                className={
                  currentPage !== "/blocked"
                    ? styles.listItemIcon
                    : styles.selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              primary="Blocked Users"
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              sx={{ opacity: open ? 1 : 0 }}
              className={
                currentPage !== "/blocked"
                  ? styles.listItemText
                  : styles.selected_listItemText
              }
            />
          </ListItemButton>
        </ListItem>

        {/*-------------------------------- Reports Tab --------------------------------*/}
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            className={styles.listItemButtonClass}
            onClick={handleClick}
          >
            <ListItemIcon>
              <AssessmentOutlinedIcon
                className={
                  currentPage.split("/")[1] !== "reports"
                    ? styles.expand_listItemIcon
                    : styles.expand_selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              sx={{ opacity: open ? 1 : 0 }}
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              primary="Reports"
              className={
                currentPage.split("/")[1] !== "reports"
                  ? styles.listItemText
                  : styles.selected_listItemText
              }
            />
            {expandopen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={expandopen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* DailyTasks */}
              <ListItemButton
                component={mLink}
                to={"/reports/dailyTasks"}
                className={
                  user && (marketingRole || hrRole || adminBDRole || bdRole)
                    ? styles.DailyTasksStyle
                    : ""
                }
                sx={{ pl: 4 }}
                onClick={() => {
                  setCurrentPage("/reports/dailyTasks");
                }}
              >
                <ListItemIcon>
                  <TodayOutlinedIcon
                    className={
                      currentPage.split("/")[2] !== "dailyTasks"
                        ? styles.expand_listItemIcon
                        : styles.expand_selected_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary="Daily Tasks"
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    currentPage.split("/")[2] !== "dailyTasks"
                      ? styles.listItemText
                      : styles.selected_listItemText
                  }
                />
              </ListItemButton>

              {/* allTasks */}
              <ListItemButton
                component={mLink}
                to={"/reports/allTasks"}
                sx={{ pl: 4 }}
                onClick={() => {
                  setCurrentPage("/reports/allTasks");
                }}
              >
                <ListItemIcon>
                  <CalendarMonthOutlinedIcon
                    className={
                      currentPage.split("/")[2] !== "allTasks"
                        ? styles.expand_listItemIcon
                        : styles.expand_selected_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary="All Tasks"
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    currentPage.split("/")[2] !== "allTasks"
                      ? styles.listItemText
                      : styles.selected_listItemText
                  }
                />
              </ListItemButton>

              {/* Summary */}
              <ListItemButton
                component={mLink}
                to={"/reports/summary"}
                sx={{ pl: 4 }}
                onClick={() => {
                  setCurrentPage("/reports/summary");
                }}
              >
                <ListItemIcon>
                  <SummarizeOutlinedIcon
                    className={
                      currentPage !== "/reports/summary"
                        ? styles.expand_listItemIcon
                        : styles.expand_selected_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary="Summary"
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    currentPage !== "/reports/summary"
                      ? styles.listItemText
                      : styles.selected_listItemText
                  }
                />
              </ListItemButton>

              {/* myTeam */}
              <ListItemButton
                component={mLink}
                to={"/reports/myTeam"}
                sx={{ pl: 4 }}
                onClick={() => {
                  setCurrentPage("/reports/myTeam");
                  handelClickOnTeam("Planning");
                  navigate("reports/myTeam");
                }}
                className={
                  (user && teamLeaderRole) || hrRole || adminRole
                    ? ""
                    : styles.myTeamTab
                }
              >
                <ListItemIcon>
                  <PeopleOutlineIcon
                    className={
                      currentPage.split("/")[2] === "myTeam" &&
                      selectedTeam === "Planning"
                        ? styles.expand_selected_listItemIcon
                        : styles.expand_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary={"my Team"}
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    currentPage.split("/")[2] === "myTeam" &&
                    selectedTeam === "Planning"
                      ? styles.selected_listItemText
                      : styles.listItemText
                  }
                />
              </ListItemButton>

              {/* <ListItemButton
                component={mLink}
                to={"/reports/bimteam"}
                sx={{ pl: 4 }}
                onClick={() => {
                  setCurrentPage("/reports/bimteam");
                  handelClickOnTeam("bim");
                  navigate("reports/bimteam");
                }}
                className={user && hrRole ? "" : styles.myTeamTab}
              >
                <ListItemIcon>
                  <PeopleOutlineIcon
                    className={
                      currentPage.split("/")[2] === "bimteam" &&
                      selectedTeam === "bim"
                        ? styles.expand_selected_listItemIcon
                        : styles.expand_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary={"BIM Team"}
                  sx={{ opacity: open ? 1 : 0 }}
                  className={
                    currentPage.split("/")[2] === "bimteam" &&
                    selectedTeam === "bim"
                      ? styles.selected_listItemText
                      : styles.listItemText
                  }
                />
              </ListItemButton> */}
            </List>
          </Collapse>
        </ListItem>

        {/*-------------------------------- Requests Tab --------------------------------*/}
        <ListItem
          component={mLink}
          to={"/requests"}
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            setCurrentPage("/requests");
            navigate("/requests");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <BorderColorOutlinedIcon
                className={
                  currentPage.split("/")[1] !== "requests"
                    ? styles.listItemIcon
                    : styles.selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              primary="Requests"
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              sx={{ opacity: open ? 1 : 0 }}
              className={
                currentPage.split("/")[1] !== "requests"
                  ? styles.listItemIcon
                  : styles.selected_listItemIcon
              }
            />
          </ListItemButton>
        </ListItem>

        {/*-------------------------------- Dashboard Tab --------------------------------*/}

        <ListItem
          component={mLink}
          to={"/dashboard/chat"}
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            setCurrentPage("/dashboard/chat");
            navigate("/dashboard/chat");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ChatOutlinedIcon
                className={
                  currentPage !== "/dashboard/chat"
                    ? styles.listItemIcon
                    : styles.selected_listItemIcon
                }
              />
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              primaryTypographyProps={{ fontSize: "0.9rem" }}
              sx={{ opacity: open ? 1 : 0 }}
              className={
                currentPage !== "/dashboard/chat"
                  ? styles.listItemText
                  : styles.selected_listItemText
              }
            />
          </ListItemButton>
        </ListItem>

        {/*-------------------------------- Projects Tab --------------------------------*/}

        {departments.map((item, index) => {
          return (
            <ListItem
              key={index}
              disablePadding
              className={
                user && hrRole ? styles.projectTabCondition : styles.projectTab
              }
            >
              <ListItemButton
                className={styles.listItemButtonClass}
                onClick={chooseCollapseClick(item)}
              >
                <ListItemIcon>
                  <AccountTreeOutlinedIcon
                    className={
                      currentPage.split("/")[1] === "projects" &&
                      selectedDepart === item
                        ? styles.expand_selected_listItemIcon
                        : styles.expand_listItemIcon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{ opacity: open ? 1 : 0 }}
                  primaryTypographyProps={{ fontSize: "0.9rem" }}
                  primary={item}
                  className={
                    currentPage.split("/")[1] === "projects" &&
                    selectedDepart === item
                      ? styles.selected_listItemText
                      : styles.listItemText
                  }
                />
                {chooseCollapse(item) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={chooseCollapse(item)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* New */}
                  {user && (marketingLeaderRole || bdRole || adminRole) && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        setCurrentPage(`/projects/New/${item}`);
                        navigate(`/projects/New/${item}`, {
                          state: { info: item },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <CreateNewFolderOutlinedIcon
                          className={
                            currentPage !==
                              `/projects/New/createProject/${item}` &&
                            currentPage !== `/projects/New/${item}`
                              ? styles.expand_listItemIcon
                              : styles.expand_selected_listItemIcon
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                        primary="New"
                        sx={{ opacity: open ? 1 : 0 }}
                        className={
                          currentPage !==
                            `/projects/New/createProject/${item}` &&
                          currentPage !== `/projects/New/${item}`
                            ? styles.listItemText
                            : styles.selected_listItemText
                        }
                      />
                    </ListItemButton>
                  )}

                  {/* NotAssigned */}
                  {user &&
                    !planningEmployeeRole &&
                    !marketingEmployeeRole &&
                    !bimRole && (
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          setCurrentPage(`/projects/NotAssigned/${item}`);
                          navigate(`/projects/NotAssigned/${item}`, {
                            state: { info: item },
                          });
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentLateOutlinedIcon
                            className={
                              currentPage !== `/projects/NotAssigned/${item}`
                                ? styles.expand_listItemIcon
                                : styles.expand_selected_listItemIcon
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                          primary="Not Assigned"
                          sx={{ opacity: open ? 1 : 0 }}
                          className={
                            currentPage !== `/projects/NotAssigned/${item}`
                              ? styles.listItemText
                              : styles.selected_listItemText
                          }
                        />
                      </ListItemButton>
                    )}

                  {/* InProgress */}
                  {!marketingEmployeeRole && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        setCurrentPage(`/projects/InProgress/${item}`);
                        navigate(`projects/InProgress/${item}`, {
                          state: { info: item },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <CachedOutlinedIcon
                          className={
                            currentPage !== `/projects/InProgress/${item}`
                              ? styles.expand_listItemIcon
                              : styles.expand_selected_listItemIcon
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                        primary="InProgress"
                        sx={{ opacity: open ? 1 : 0 }}
                        className={
                          currentPage !== `/projects/InProgress/${item}`
                            ? styles.listItemText
                            : styles.selected_listItemText
                        }
                      />
                    </ListItemButton>
                  )}

                  {/* Completed */}
                  {!marketingEmployeeRole && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        setCurrentPage(`/projects/Completed/${item}`);
                        navigate(`projects/Completed/${item}`, {
                          state: { info: item },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <AssignmentTurnedInOutlinedIcon
                          className={
                            currentPage !== `/projects/Completed/${item}`
                              ? styles.expand_listItemIcon
                              : styles.expand_selected_listItemIcon
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                        primary="Completed"
                        sx={{ opacity: open ? 1 : 0 }}
                        className={
                          currentPage !== `/projects/Completed/${item}`
                            ? styles.listItemText
                            : styles.selected_listItemText
                        }
                      />
                    </ListItemButton>
                  )}

                  {/* To BD */}
                  {!marketingEmployeeRole && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        setCurrentPage(`/projects/ToBd/${item}`);
                        navigate(`projects/ToBd/${item}`, {
                          state: { info: item },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <KeyboardReturnIcon
                          className={
                            currentPage !== `/projects/ToBd/${item}`
                              ? styles.expand_listItemIcon
                              : styles.expand_selected_listItemIcon
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                        primary="TO BD"
                        sx={{ opacity: open ? 1 : 0 }}
                        className={
                          currentPage !== `/projects/ToBd/${item}`
                            ? styles.listItemText
                            : styles.selected_listItemText
                        }
                      />
                    </ListItemButton>
                  )}

                  {/* All Projects */}
                  {(bdRole || marketingRole || adminRole) && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        setCurrentPage(`/projects/allProjects/${item}`);
                        navigate(`projects/allProjects/${item}`, {
                          state: { info: item },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <ListAltIcon
                          className={
                            currentPage !== `/projects/allProjects/${item}`
                              ? styles.expand_listItemIcon
                              : styles.expand_selected_listItemIcon
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                        primary="All Projects"
                        sx={{ opacity: open ? 1 : 0 }}
                        className={
                          currentPage !== `/projects/allProjects/${item}`
                            ? styles.listItemText
                            : styles.selected_listItemText
                        }
                      />
                    </ListItemButton>
                  )}
                </List>
              </Collapse>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};
export default SideBar;
