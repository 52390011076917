import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import ImageAvatar from "../../components/avatar/avatarComponent";
import styles from "./notificationspage.module.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  getAllNotification,
  getNotification,
} from "../../store/notifications/notificationsSlice";
import { Alert, TableHead } from "@mui/material";
import systemAvatar from "../../assets/logo3.png"


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function NotificationsList({ setCurrentPage }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  const { unReadNotifications, isLoading2 } = useSelector(
    (state) => state.notifications
  );
  const { user } = useSelector((state) => state.employees);
  const dispatch = useDispatch();


  React.useEffect(() => {
    setCurrentPage(location.pathname);
    dispatch(getAllNotification({ id: user?._id, status: "all" }));
  }, [navigate]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - unReadNotifications.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container
      sx={{
        border: "1px solid #E9E9EF",
        padding: "2%",
        backgroundColor: "white",
      }}
    >
      {unReadNotifications.length === 0 && !isLoading2 ? (
        <Box className={styles.alertContainerStyle} height="400px">
          <Alert variant="filled" severity="info" className={styles.alertStyle}>
            You don't have any Notifications Yet
          </Alert>
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead className={styles.tableHeadStyle}>
              <TableRow>
                <TableCell className={styles.headerCell} align="left">
                  Sender
                </TableCell>
                <TableCell className={styles.headerCell} align="left">
                  Type
                </TableCell>
                <TableCell className={styles.headerCell} align="left">
                  Content
                </TableCell>
                <TableCell className={styles.headerCell} align="right">
                  date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? unReadNotifications.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : unReadNotifications
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "none",
                    }}
                  >
                    <ImageAvatar src={row.sender ? row.sender?.image : systemAvatar} />
                    {row.sender? row.sender?.username : "Pearls Net"}
                  </TableCell>
                  <TableCell className={styles.messageSpan} align="left">
                    <span
                      className={
                        row.notificationType === "Project"
                          ? styles.projectType
                          : row.notificationType === "Task"
                            ? styles.requestType
                            : styles.requestType
                      }
                    >
                      {row.notificationType}
                    </span>
                  </TableCell>
                  <TableCell className={styles.messageSpan} align="left">
                    {
                      row.receiver.filter((item) => {
                        return item.receiverId === user?._id;
                      })[0]?.message
                    }
                  </TableCell>
                  <TableCell className={styles.messageSpan} align="right">
                    {row.created_date}
                    {" at "}
                    {row?.created_time}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={unReadNotifications.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}
