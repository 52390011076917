import { Box, Grid } from "@mui/material";
import Example from "../../../components/charts/chart";
import SummaryTable from "./summaryTable";

const Summary = ({ todayallTasks, employee }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={11} md={6} lg={6}>
        {" "}
        <Box
          sx={{
            border: "1px solid #E9E9EF",
            marginTop: "2%",
            justifyContent: "center",
            display: "flex",
            paddingBottom: "1.3%",
          }}
        >
          <SummaryTable employee={employee} allTasks={todayallTasks} />
        </Box>
      </Grid>
      <Grid item xs={11} md={6} lg={6}>
        {" "}
        <Box sx={{ border: "1px solid #E9E9EF", marginTop: "2%" }}>
          <Example employee={employee} allTasks={todayallTasks} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Summary;
