import * as React from "react";
import styles from "../project.module.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow:
    "rgba(26, 35, 126, 0.3) 0px 1px 2px 0px, rgba(26, 35, 126, 0.15) 0px 2px 6px 2px",
  border: 0,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const ProjectUpdatesSummary = ({ remainder, allProjectUpdates }) => {
  const { projectSummary, isLoading } = useSelector((state) => state.projects);

  function countReminders() {
    let counter = 0;

    for (const item of allProjectUpdates) {
      if (item.Email1 !== "") {
        counter++;
      }
      if (item.Email2 !== "") {
        counter++;
      }
      if (item.Call !== "") {
        counter++;
      }
    }

    return counter;
  }

  const stages = [
    "Baseline",
    "Update",
    "Rebaseline",
    "Delay",
    "Recovery",
    "Pre-Bid",
    "Preliminary",
    "Conversion",
  ];

  const itemsArray = stages.map((element, index) => {
    return (
      <Grid key={index} item xs={1.3}>
        {!isLoading && (
          <Item>
            <p className={styles.projectStatgeItems_header}>{element}</p>
            <p className={styles.projectStatgeItems_sub}>
              {projectSummary[element] ? projectSummary[element] : 0}
            </p>
          </Item>
        )}
      </Grid>
    );
  });

  return (
    <React.Fragment>
      {itemsArray}
      <Grid item xs={1.3}>
        {!isLoading && (
          <Item>
            <p className={styles.projectStatgeItems_header_Remainder}>
              Reminders
            </p>
            <p className={styles.projectStatgeItems_sub}>
              {countReminders() || 0}
            </p>
          </Item>
        )}
      </Grid>
    </React.Fragment>
  );
};
export default ProjectUpdatesSummary;
