import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../config";

//getCustomSummary
export const getCustomSummary = createAsyncThunk(
  "customSummary/getCustomSummary",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const { startDate, endDate, employeeId, role, searchers } = data;

    try {
      const res = await axios.get(`${BASE_API}/customSummary/`, {
        params: {
          startDate: startDate,
          endDate: endDate,
          employeeId: employeeId,
          role: role,
          searchers: searchers,
        },
      });
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  allCustomSummary: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const customSummarySlice = createSlice({
  name: "months",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },

  extraReducers: {
    //createDepartment
    [getCustomSummary.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [getCustomSummary.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.allCustomSummary = action.payload;
    },
    [getCustomSummary.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.allCustomSummary = null;
    },
  },
});

export const { reset } = customSummarySlice.actions;
export default customSummarySlice.reducer;
