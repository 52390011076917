import React, { lazy, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import themeLight from "./theme/LightTheme/LightTheme";
import themeDark from "./theme/DarkTheme/DarkTheme";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Router from "./routes";

function App() {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <ReactNotifications />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
