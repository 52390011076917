import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 



export const getDepartment = createAsyncThunk(
  "auth/getDepartment",
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${BASE_API}/departments`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


//Logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      localStorage.removeItem("user");

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


//createDepartment
export const createDepartment = createAsyncThunk(
  "auth/createDepartment",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    //console.log(userData);
    try {
      const res = await axios.post(`${BASE_API}/departments`, userData);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


//updateDepartment
export const updateDepartment = createAsyncThunk(
  "auth/updateDepartment",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    
    const {id,name} = userData.values
    //console.log(id);
    try {
      const res = await axios.put(`${BASE_API}/departments/${id}`, userData.values);
      //console.log(res);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const initialState = {
  // user: user ? user : null,
  departments:[],
  isError: null,
  isSuccess: false,
  isLoading: false,
  message: "",
  stateCurrentPage:"/home"

};

export const departmentSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    setStateCurrentPage :(state , action)=>{
     state.stateCurrentPage = action.payload
    },
  },

  extraReducers: {
    //createDepartment
    [createDepartment.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [createDepartment.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.departments = action.payload;
    },
    [createDepartment.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.departments = null;
    },

    // updateDepartment
    [updateDepartment.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.isError = false;
    },
    [updateDepartment.fulfilled]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.departments = action.payload;
    },
    [updateDepartment.rejected]: (state, action) => {
      //console.log(action.payload);
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
      state.user = null;
    },


    [getDepartment.pending]: (state, action) => {
      //console.log(action);
      state.isLoading = true;
      state.error = null;
    },
    [getDepartment.fulfilled]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.departments = action.payload;
    },
    [getDepartment.rejected]: (state, action) => {
      //console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
  },
});

export const { reset ,setStateCurrentPage} = departmentSlice.actions;
export default departmentSlice.reducer;