import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_API} from '../../config' 

export const getTasksMarketing =createAsyncThunk(
  "tasksMarketing/getTasksMarketing",
  async(id,thunkAPI) =>{
    const { rejectWithValue } = thunkAPI;
    try {
        const res = await axios.get(`${BASE_API}/dailyTasksMarketing/${id}`);
        return res.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
  }  
);

export const addTaskMarketing = createAsyncThunk(
    'tasksMarketing/addTaskMarketing',
    async (taskData, thunkAPI) => {
      //console.log(taskData);
      const { rejectWithValue } = thunkAPI;
      try {
        const res = await axios.post(`${BASE_API}/dailyTasksMarketing`, taskData);
        return res.data ;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const updateTask = createAsyncThunk(
    'tasksMarketing/updateTaskMarketing',
    async (allData, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      //console.log(allData);
      const {id} = allData
      const {taskdata} = allData
      try {
        const res = await axios.put(`${BASE_API}/dailyTasksMarketing/${id}`, { data: taskdata })
        return res.data ;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const deleteTask = createAsyncThunk(
    'tasksMarketing/deleteTaskMarketing',
    async (allData, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      const {id,employeeId} = allData
        //console.log(allData); 
      try {

        const res = await axios.delete(`${BASE_API}/dailyTasksMarketing/${id}`, { data: allData })
       
        return res.data ;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  )

  var today = new Date();

  const initialState = {
    allTasks: [],
    todayallTasks:[],
    selectedtask:null,
    isLoading: false,
    isError: false,
    message: null,
    isSuccess: false,
  };

  export const taskSliceMarketing=createSlice({
    name:"pets",
    initialState,
    reducers:{
        selectTask: (state,action) => {
            state.selectedtask=action.payload
          },
          reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = "";
          },
    },
    extraReducers:{
      [getTasksMarketing.pending]:(state,action)=>{
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [getTasksMarketing.fulfilled]:(state,action)=>{
         //console.log(action.payload);
         state.allTasks=action.payload;
         state.todayallTasks=action.payload
         state.isLoading=false;
         state.isError=false;
         state.isSuccess=true; 
      },
      [getTasksMarketing.rejected]:(state,action)=>{
          //console.log(action)
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
      },

      [updateTask.pending]:(state,action)=>{
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [updateTask.fulfilled]:(state,action)=>{
         //console.log(action.payload);
         state.allTasks=action.payload;
         state.isLoading=false;
         state.isError=false;
         state.isSuccess=true; 
      },
      [updateTask.rejected]:(state,action)=>{
          //console.log(action)
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
      },

      [addTaskMarketing.pending]:(state,action)=>{
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [addTaskMarketing.fulfilled]:(state,action)=>{
         //console.log(action.payload);
         state.allTasks=action.payload;
         state.isLoading=false;
         state.isError=false;
         state.isSuccess=true; 
      },
      [addTaskMarketing.rejected]:(state,action)=>{
          //console.log(action)
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
      },

      [deleteTask.pending]:(state,action)=>{
        //console.log(action);
        state.isLoading = true;
        state.isError = false;
      },
      [deleteTask.fulfilled]:(state,action)=>{
         //console.log(action.payload);
         state.allTasks=action.payload;
         state.todayallTasks=action.payload;
         state.isLoading=false;
         state.isError=false;
         state.isSuccess=true; 
      },
      [deleteTask.rejected]:(state,action)=>{
          //console.log(action)
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
      }
    }
})

export const { selectTask , reset } = taskSliceMarketing.actions;
export default taskSliceMarketing.reducer;