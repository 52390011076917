import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./home.module.css";
import { useLocation, useNavigate, Link, Outlet  } from "react-router-dom";
import { reset } from "../../store/employees/employeesSlice";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { BASE_API } from "../../config";
import SideBar from "./sideBar";
import AppBarComponent from "./appBar";

const drawerWidth = 240;
const drawerColor = "#081736";

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: drawerColor,
  borderRight: "4px solid #90B4FF",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
}); // Open SideBar Styled Component

const closedMixin = (theme) => ({
  backgroundColor: drawerColor,
  borderRight: `1px solid #E9E9EF`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
}); // Close SideBar Styled Component

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
})); // SideBar Header Styled Component

const DrawerHeader2 = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0",
  justifyContent: "flex-end",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
})); // SideBar Sub_Header Styled Component

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
})); // SideBar styled Componen

export default function Home({SocketContext, currentPage, setCurrentPage }) {
  // Vars
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux
  const { user } = useSelector((state) => state.employees);
  const isAuthed = localStorage.getItem("user");

  // UseStates
  const [socket, setSocket] = React.useState(null);
  const [mLink, setLink] = React.useState(Link);
  const [open, setOpen] = React.useState(true);
  // const [currentPage, setCurrentPage] = React.useState(location.pathname);
  const [expandopen, setExpandopen] = React.useState(false);
  const [expandopen_dashboard, setExpandopen_dashboard] = React.useState(false);
  const [expandopen_projects_planning, setExpandopen_projects_planning] =
    React.useState(false);
  const [expandopen_projects_BIM, setExpandopen_projects_BIM] =
    React.useState(false);
  const [expandopen_projects_MONI, setExpandopen_projects_MONI] =
    React.useState(false);
  const [selectedDepart, setSelectedDepart] = React.useState(
    location.pathname.split("/")[3] || ""
  );
  const [selectedTeam, setSelectedTeam] = React.useState(
    location.pathname.split("/")[2] || ""
  );

  // console.log(location.pathname);
  React.useEffect(() => {
    if (!isAuthed) {
      navigate("/login");
      dispatch(reset());
    }
  }); // return user to login page if not Signed

  React.useEffect(() => {
    if (user) {
      setSocket(io(`${BASE_API}`));
      setLink(Link);
    }
  }, []); // Set sockets

  React.useEffect(() => {
    if (user) {
      socket?.emit("newUser", user);
    }
  }, [socket, user]); // enter user to socket room

  const handleClick = () => {
    setExpandopen(!expandopen);
    setExpandopen_dashboard(false);
    setExpandopen_projects_planning(false);
    setExpandopen_projects_BIM(false);
    setExpandopen_projects_MONI(false);
  }; // controller of Reports Tab

  const handleClickDashboard = () => {
    setExpandopen_dashboard(!expandopen_dashboard);
    setExpandopen(false);
    setExpandopen_projects_planning(false);
    setExpandopen_projects_BIM(false);
    setExpandopen_projects_MONI(false);
  }; // controller of Dashboard Tab

  const handleClick_Planning_Projects = () => {
    setExpandopen_projects_planning(!expandopen_projects_planning);
    setExpandopen_projects_BIM(false);
    setExpandopen_projects_MONI(false);
    setExpandopen_dashboard(false);
    setExpandopen(false);
    setSelectedDepart("Planning");
  }; // controller of handleClick_Planning_Projects Tab

  const handleClick_BIM_Projects = () => {
    setExpandopen_projects_BIM(!expandopen_projects_BIM);
    setExpandopen_projects_planning(false);
    setExpandopen_projects_MONI(false);
    setExpandopen_dashboard(false);
    setExpandopen(false);
    setSelectedDepart("BIM");
  }; // controller of handleClick_BIM_Projects Tab

  const handleClick_MONI_Projects = () => {
    setExpandopen_projects_MONI(!expandopen_projects_MONI);
    setExpandopen_projects_BIM(false);
    setExpandopen_projects_planning(false);
    setExpandopen_dashboard(false);
    setExpandopen(false);
    setSelectedDepart("Moni");
  }; // controller of handleClick_MONI_Projects Tab

  const handleDrawerOpen = () => {
    setOpen(true);
  }; // controller of Openning SideBar

  const handleDrawerClose = () => {
    setOpen(false);
  }; // controller of Closing SideBar

  const reFormateRoute = (route) => {
    return route.replaceAll("/", " > ");
  }; // controller of current Page Path

  const handelClickOnTeam = (team) => {
    setSelectedTeam(team);
  };




  return (
    <Box sx={{ display: "flex" }}>
      {/* ------------------------- Space after AppBar -------------------------- */}
      <CssBaseline />

      <AppBarComponent
        socket={socket}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        drawerWidth={drawerWidth}
        setCurrentPage={setCurrentPage}
        user={user}
      />

      {/* -------------------------------- SideBar -------------------------------- */}
      <SideBar
        handleClick={handleClick}
        handleClickDashboard={handleClickDashboard}
        handleClick_Planning_Projects={handleClick_Planning_Projects}
        handleClick_BIM_Projects={handleClick_BIM_Projects}
        handleClick_MONI_Projects={handleClick_MONI_Projects}
        handleDrawerClose={handleDrawerClose}
        user={user}
        Drawer={Drawer}
        DrawerHeader={DrawerHeader}
        mLink={mLink}
        theme={theme}
        navigate={navigate}
        open={open}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        expandopen={expandopen}
        expandopen_projects_planning={expandopen_projects_planning}
        expandopen_projects_BIM={expandopen_projects_BIM}
        expandopen_projects_MONI={expandopen_projects_MONI}
        expandopen_dashboard={expandopen_dashboard}
        socket={socket}
        selectedDepart={selectedDepart}
        handelClickOnTeam={handelClickOnTeam}
        selectedTeam={selectedTeam}
      />

      {/* -------------------------------- Body -------------------------------- */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 0 }}>
        <DrawerHeader2 />

        {/* current route header */}
        <div className={styles.routeStyle}>
          <p style={{ fontSize: "0.8rem", padding: "0", margin: "0" }}>
            {reFormateRoute(currentPage)}
          </p>
        </div>

        {/* ------------------------------------------ SUB Routes --------------------------------------------------------- */}

        <SocketContext.Provider value={[socket, setSocket]}>
          <Outlet />
        </SocketContext.Provider>

      </Box>
    </Box>
  );
}
