import { useLocation } from "react-router-dom";
import EditOldTasks from "./editOldTaskPlanning";
import EditOldTasksBim from "./editOldTaskBim";

const HR_Team_Tasks_Edit_Form = ({ setCurrentPage }) => {
  const location = useLocation();
  let { depart } = location.state;
  return depart === "Planning" ? (
    <EditOldTasks setCurrentPage={setCurrentPage} />
  ) : (
    <EditOldTasksBim setCurrentPage={setCurrentPage} />
  );
};
export default HR_Team_Tasks_Edit_Form;
