import { Box, Button, Tooltip, Typography } from "@mui/material";
import styles from "../project.module.css";
import {
  admin,
  Planning_Employee,
  Planning_Leader,
  remainingTimeStyle,
} from "../../../roles";
import {
  sumOfHours,
  sumOfHoursDeduct,
  changeTimeSpend,
} from "../calculateTime";
import ModalSpentTime from "../../../components/modalProjectSpentTime/spentTimeModal";

const ToBd_columns = (setCurrentPage, user, navigate, location) => {
  // Vars
  const planningLeaderRole = Planning_Leader(user);
  const planningEmployeeRole = Planning_Employee(user);
  const adminRole = admin(user);

  // columns control functions
  function getBdName(params) {
    return `${params.row.bdEmployee?.username}`;
  }
  function getTeamLeaderName(params) {
    return `${params.row.selectedTeamLeader?.username}`;
  }
  function getSignDate(params) {
    return `${params.row.dateOfSign.split("T")[0]}`;
  }
  function getAssistantPlannerName(params) {
    return `${params.row.assistantPlanner?.username}`;
  }
  function getAssignedName(params) {
    return `${params.row.assignedEmployee?.username}`;
  }
  function getBdAssistantName(params) {
    return `${
      params.row.assistantBd?.username
        ? params.row.assistantBd?.username
        : "- - -"
    }`;
  }

  const columns = [
    {
      field: "id",
      headerName: "N",
      headerClassName: styles.dataCell,
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.projectName} arrow>
            <span>{params.row.projectName}</span>
          </Tooltip>
        );
      },
      headerClassName: styles.dataCell,
      minWidth: 300,
    },
    {
      field: "projectId",
      headerName: "Project Id",
      headerClassName: styles.dataCell,
      maxWidth: 95,
    }, //projectId

    {
      field: "dateOfSign",
      headerName: "Sign Date",
      valueGetter: getSignDate,
      headerClassName: styles.dataCell,
      maxWidth: 95,
    }, //dateOfSign
    {
      field: "projectType",
      headerName: "Project Type",
      headerClassName: styles.dataCell,
      maxWidth: 300,
      minWidth: 150,
    }, //project Type
    {
      field: "authority",
      headerName: "Authority",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "value",
      headerName: "value ($)",
      headerClassName: styles.dataCell,
      maxWidth: 60,
    }, // Value
    {
      field: "services",
      headerName: "Services",
      headerClassName: styles.dataCell,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", padding: 0, margin: 0 }}>
            {params.row.services?.map((category, iterator) => {
              return (
                <ul key={iterator} style={{ margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>{category.name}</span>
                  {category.arr.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              );
            })}
          </div>
        );
      },
      valueGetter: (params) =>
        `${params.row.services[0].name} \n` +
        `${[...params.row.services[0].arr]} \n` +
        `${
          params.row.services[1]?.name ? params.row.services[1]?.name : ""
        } \n` +
        `${
          params.row.services[1]?.arr ? [...params.row.services[1]?.arr] : ""
        } `,
    },

    {
      field: "estHours",
      headerName: "Est",
      headerClassName: styles.dataCell,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            <Typography>{params.row.estHours}</Typography>
            {remainingTimeStyle(params.row, user) && (
              <Typography>{params.row.persentatgeEstHours}</Typography>
            )}
          </div>
        );
      },
      valueGetter: (params) =>
        remainingTimeStyle(params.row, user)
          ? `${params.row.estHours}  <---> ${params.row.persentatgeEstHours}`
          : `${params.row.estHours} `,
    }, //EST Hours

    {
      field: "timeSpent",
      headerName: "TimeSpent",
      headerClassName: styles.dataCell,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            <Typography>{sumOfHours(params.row.tasks)}</Typography>
            {remainingTimeStyle(params.row, user) && (
              <Typography>{sumOfHours(params.row.tasksOfAssistant)}</Typography>
            )}
          </div>
        );
      },
      valueGetter: (params) =>
        remainingTimeStyle(params.row, user)
          ? `${sumOfHours(params.row.tasks)} <---> ${sumOfHours(
              params.row.tasksOfAssistant
            )}`
          : `${sumOfHours(params.row.tasks)} `,
    }, //Time Spent

    {
      field: "baseline",
      headerName: "Baseline",
      headerClassName: styles.dataCell,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            <Typography>{sumOfHoursDeduct(params.row.tasks)}</Typography>
            {remainingTimeStyle(params.row, user) && (
              <Typography>
                {sumOfHoursDeduct(params.row.tasksOfAssistant)}
              </Typography>
            )}
          </div>
        );
      },
      valueGetter: (params) =>
        remainingTimeStyle(params.row, user)
          ? `${sumOfHoursDeduct(params.row.tasks)} <---> ${sumOfHoursDeduct(
              params.row.tasksOfAssistant
            )}`
          : `${sumOfHoursDeduct(params.row.tasks)} `,
    }, //Baseline

    {
      field: "remainingHours",
      headerName: "Remaining Hours",
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <div>
            <Typography>
              {changeTimeSpend(
                params.row.estHours + ":00",
                sumOfHoursDeduct(params.row.tasks)
              )}
            </Typography>
            {remainingTimeStyle(params.row, user) && (
              <Typography>
                {changeTimeSpend(
                  params.row.persentatgeEstHours + ":00",
                  sumOfHoursDeduct(params.row.tasksOfAssistant)
                )}
              </Typography>
            )}
          </div>
        );
      },
      valueGetter: (params) =>
        remainingTimeStyle(params.row, user)
          ? `${changeTimeSpend(
              params.row.estHours + ":00",
              sumOfHoursDeduct(params.row.tasks)
            )}<--->${changeTimeSpend(
              params.row.persentatgeEstHours + ":00",
              sumOfHoursDeduct(params.row.tasksOfAssistant)
            )}`
          : `${changeTimeSpend(
              params.row.estHours + ":00",
              sumOfHoursDeduct(params.row.tasks)
            )} `,
    }, //Remaining Hours

    {
      field: "schadual",
      hideable: planningLeaderRole || adminRole ? true : false,
      hide: planningLeaderRole || adminRole ? false : true,
      headerName: "#",
      maxWidth: 20,
      renderCell: (params) => {
        return (
          <Box sx={{ padding: "0", margin: "0" }}>
            {params.row.status === "ToBd" &&
              (planningLeaderRole || adminRole) && (
                <ModalSpentTime row={params.row} />
              )}
          </Box>
        );
      },
    }, //Baseline

    {
      field: "status",
      headerName: "Status",
      maxWidth: 80,
      renderCell: (params) => {
        return <span className={styles.toBdStatus}>{params.row.status}</span>;
      },
      headerClassName: styles.dataCell,
    }, // STatus
    {
      field: "bdEmployee",
      headerName: "BD",
      valueGetter: getBdName,
      maxWith: 100,
      headerClassName: styles.dataCell,
    },
    {
      field: "assistantBd",
      headerName: "BD Assistant",
      valueGetter: getBdAssistantName,
      with: 300,
      headerClassName: styles.dataCell,
    },
    {
      field: "selectedTeamLeader",
      headerName: "Team Leader",
      valueGetter: getTeamLeaderName,
      maxWith: 100,
      headerClassName: styles.dataCell,
    },
    {
      field: "assistantPlanner",
      headerName: "Assistant",
      valueGetter: getAssistantPlannerName,
      maxWith: 100,
      headerClassName: styles.dataCell,
    },
    {
      field: "assignedEmployee",
      headerName: "Assigned",
      valueGetter: getAssignedName,
      maxWidth: 100,
      headerClassName: styles.dataCell,
    },
    {
      field: "contractNumber",
      headerName: "Contract Number",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "number",
      headerName: "Number",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "contactPerson1",
      headerName: "ContactPerson1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactNumber1",
      headerName: "ContactNumber1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactMobile1",
      headerName: "ContactMobile1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactEmail1",
      headerName: "ContactEmail1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactPerson2",
      headerName: "ContactPerson2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactNumber2",
      headerName: "ContactNumber2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactMobile2",
      headerName: "ContactMobile2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactEmail2",
      headerName: "contactEmail2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      hide: planningLeaderRole || planningEmployeeRole ? true : false,
      align: "right",
      headerClassName: styles.dataCell,
      renderCell: (params) => {
        const onClick = (e) => {
          setCurrentPage("/projects/editProject");
          navigate(`/projects/editProject`, {
            state: {
              info: params.row,
              notificationControl: location?.state?.info,
            },
          });
        };

        return (
          !(planningEmployeeRole || planningLeaderRole) && (
            <Button onClick={onClick}>Edit</Button>
          )
        );
      },
    },
  ];

  return columns;
};

export default ToBd_columns;
