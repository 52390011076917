import * as React from "react";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getProjects } from "../../../store/projects/projectsSlice";
import { Box, Button, ButtonBase, Tooltip } from "@mui/material";
import styles from "../project.module.css";
import { adminOnly } from "../../../roles";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  createSettings,
  getSettings,
} from "../../../store/tablesSettings/tablesSettingSlice";

export default function TestNewGridTable({ setCurrentPage }) {
  const { allProjects, isLoading, numOfRows } = useSelector(
    (state) => state.projects
  );
  const { user } = useSelector((state) => state.employees);
  const { allSettings } = useSelector((state) => state.settings);

  const adminBDRole = adminOnly(user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPageSize, setCurrentPageSize] = React.useState(50);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(0);

  const getData = () => {
    dispatch(getSettings(user._id)).then(() => {
      dispatch(
        getProjects({
          id: user?._id,
          schema: "New",
          pageSize: currentPageSize,
          pageNumber: currentPageNumber,
          projectDepart: location?.state?.info || "Planning",
        })
      );
    });
  }; // get all projects

  // console.log(location?.state?.info);

  React.useEffect(() => {
    if (user) {
      getData();
      setCurrentPage(location.pathname);
    }
  }, [navigate, location?.state?.info]);

  function getBdName(params) {
    return `${params.row.bdEmployee?.username}`;
  }
  function getSignDate(params) {
    return `${params.row.dateOfSign.split("T")[0]}`;
  }
  function getBdAssistantName(params) {
    return `${
      params.row.assistantBd?.username
        ? params.row.assistantBd?.username
        : "- - -"
    }`;
  }

  // Tooltip
  const columns = [
    {
      field: "id",
      headerName: "N",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
    },
    {
      field: "projectName",
      headerName: "Project Name",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.projectName} arrow>
            <span>{params.row.projectName}</span>
          </Tooltip>
        );
      },
      headerClassName: styles.dataCell,
      minWidth: 300,
    },

    {
      field: "dateOfSign",
      headerName: "Sign Date",
      valueGetter: getSignDate,
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "projectType",
      headerName: "Project Type",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "authority",
      headerName: "Authority",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "value",
      headerName: "value ($)",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "services",
      headerName: "Services",
      headerClassName: styles.dataCell,
      minWidth: 350,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", padding: 0, margin: 0 }}>
            {params.row.services?.map((category, iterator) => {
              return (
                <ul key={iterator} style={{ margin: 0 }}>
                  <span style={{ fontWeight: "bold" }}>{category.name}</span>
                  {category.arr.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              );
            })}
          </div>
        );
      },
      valueGetter: (params) =>
        `${params.row.services[0].name} \n` +
        `${[...params.row.services[0].arr]} \n` +
        `${
          params.row.services[1]?.name ? params.row.services[1]?.name : ""
        } \n` +
        `${
          params.row.services[1]?.arr ? [...params.row.services[1]?.arr] : ""
        } `,
    },
    {
      field: "estHours",
      headerName: "Est Hours (h)",
      headerClassName: styles.dataCell,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return <span className={styles.newStatus}>{params.row.status}</span>;
      },
      headerClassName: styles.dataCell,
    },
    {
      field: "bdEmployee",
      headerName: "BD",
      valueGetter: getBdName,
      with: 300,
      headerClassName: styles.dataCell,
    },

    {
      field: "assistantBd",
      headerName: "BD Assistant",
      valueGetter: getBdAssistantName,
      with: 300,
      headerClassName: styles.dataCell,
    },

    {
      field: "contractNumber",
      headerName: "Contract Number",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "number",
      headerName: "Number",
      headerClassName: styles.dataCell,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "contactPerson1",
      headerName: "ContactPerson1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactNumber1",
      headerName: "ContactNumber1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactMobile1",
      headerName: "ContactMobile1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactEmail1",
      headerName: "ContactEmail1",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactPerson2",
      headerName: "ContactPerson2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactNumber2",
      headerName: "ContactNumber2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactMobile2",
      headerName: "ContactMobile2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "contactEmail2",
      headerName: "contactEmail2",
      headerClassName: styles.dataCell,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      headerClassName: styles.dataCell,
      renderCell: (params) => {
        const onClick = (e) => {
          setCurrentPage("/projects/editProject");
          navigate(`/projects/editProject`, {
            state: {
              info: params.row,
              notificationControl: location?.state?.info,
            },
          });
        };

        return <Button onClick={onClick}>Edit</Button>;
      },
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  // const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(allSettings.settingObj);

  return (
    <Box sx={{ border: "1px solid #E9E9EF" }}>
      <Box className={styles.reportContainer}>
        <p className={styles.pageTitle}>New {location?.state?.info} Projects</p>
        <Box>
          {user &&
            (user.department.name === "BD" ||
              user.department.name === "Marketing" ||
              adminBDRole) && (
              <ButtonBase
                onClick={() => {
                  navigate("/projects/new/createProject");
                  setCurrentPage("/projects/new/createProject", {
                    state: { notificationControl: location?.state?.info },
                  });
                }}
                type="submit"
                className={styles.addButton}
              >
                New Project
              </ButtonBase>
            )}
        </Box>

        <div
          style={{
            minheight: 600,
            minWidth: 650,
            height: allProjects.length > 10 ? "auto" : 600,
            width: "100%",
            marginTop: "2%",
            backgroundColor: "white",
          }}
        >
          {!isLoading ? (
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 0,
                },
              }}
              autoHeight={allProjects.length > 10}
              rowCount={numOfRows}
              rowsPerPageOptions={[10, 20, 50]}
              initialState={{
                pagination: {
                  pageSize: currentPageSize,
                },
                columns: {
                  columnVisibilityModel: allSettings?.settingObj,
                },
              }}
              onColumnVisibilityModelChange={(newModel) => {
                // setColumnVisibilityModel(newModel)
                dispatch(
                  createSettings({ id: user._id, userSettingObj: newModel })
                );
              }}
              checkboxSelection
              rows={allProjects}
              columns={columns}
              getRowId={(row) => row._id}
              loading={isLoading}
              components={{
                Toolbar: GridToolbar,
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          ) : (
            <div
              style={{
                minheight: "70vh",
                height: 600,
                minWidth: 650,
                width: "100%",
                marginTop: "2%",
                backgroundColor: "white",
              }}
            >
              <DataGrid
                columns={[]}
                rows={[]}
                components={{
                  Toolbar: GridToolbar,
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                loading={isLoading}
              />
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}
